import * as Constantes from "../../../../util/constantes"

export const TipoPokemon = {
    Fogo: {id: Constantes.CD_FOGO, nome: "Fogo", background: '#c70e30', backgroundHover: '#8c0a22'},
    Agua: {id: Constantes.CD_AGUA, nome: "Água",  background: '#1f46d1', backgroundHover: '#143091'},
    Dark: {id: Constantes.CD_DARK, nome: "Sombrio", background: '#545454', backgroundHover: '#3d3d3d'},
    Psiquico : {id: Constantes.CD_PSIQUICA, nome: "Psíquico", background: '#781bc4', backgroundHover: '#641aa1'},
    Planta: {id: Constantes.CD_PLANTA, nome: "Planta", background: '#17b339', backgroundHover: '#0e7525'},
    Dragao: {id: Constantes.CD_DRAGAO, nome: "Dragao", background: '#dbaa23', backgroundHover: '#b58d1d'},
    Fada: {id: Constantes.CD_FADA, nome: "Fada", background: '#e03adb', backgroundHover: '#ad2daa'},
    Lutador: {id: Constantes.CD_LUTADOR, nome: "Lutador", background: '#d1701b', backgroundHover: '#ad5d17'},
    Eletrico: {id: Constantes.CD_ELETRICO, nome: "Elétrico", background: '#dedb1f', backgroundHover: '#bdba1a'},
    Metal: {id: Constantes.CD_METAL, nome: "Metal", background: '#bfbfbf', backgroundHover: '#969696'},
    Normal: {id: Constantes.CD_NORMAL, nome: "Normal", background: '#f5f5f5', backgroundHover: '#e0e0e0'},
    Energia: {id: Constantes.CD_ENERGIA, nome: "Energia", background: '', backgroundHover: ''},
    Ferramenta: {id: Constantes.CD_FERRAMENTA, nome: "Ferramenta", background: '', backgroundHover: ''},
    Estadio: {id: Constantes.CD_ESTADIO, nome: "Estádio", background: '', backgroundHover: ''},
    Apoiador : {id: Constantes.CD_APOIADOR, nome: "Apoiador", background: '', backgroundHover: ''},
    Item: {id: Constantes.CD_ITEM, nome: "Item", background: '', backgroundHover: ''},
}