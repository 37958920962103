import { Box, IconButton, Typography } from "@mui/material";
import { useState, useEffect } from 'react'
import Endereco from "../../perfil/endereco/Endereco";
import ClienteService from "../../../api/services/ClienteService";
import CardEndereco from "../../perfil/endereco/CardEndereco";
import * as Constantes from "../../../util/constantes";
import * as icones from '../../../util/icones'
import Popup from "../../../components/Popup";
import * as Utils from "../../../util/Utils";
import * as Cores from "../../../util/Cores";

export default function EnderecoPedido(props) {
    const { userDetails, alterarLoad, notificar, enderecoSelecionado, setEnderecoSelecionado } = props
    const [popupEndereco, setPopupEndereco] = useState(false)
    const [listaEnderecos, setListaEnderecos] = useState([])
    const { height, width } = Utils.useWindowDimensions();
    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE

    useEffect(() => {
        consultarEnderecos()
    }, [popupEndereco === false])

    async function consultarEnderecos() {
        if (userDetails.id !== undefined) {
            ClienteService.consultarEnderecosCliente(alterarLoad, notificar, userDetails.id).then((response) => {
                setListaEnderecos(response)
            })
        }
    }

    const selecionarEndereco = (endereco) => {
        setEnderecoSelecionado(endereco)
    }


    return (
        <Box>
            <Typography color={Cores.COR_PRINCIPAL} fontSize={20} sx={{ fontWeight: 700 }}>Enviar para: </Typography>
            <Box sx={{
                display: '-webkit-box', overflowX: 'scroll', overflowY: 'hidden',
                ...Utils.barra()
            }}>
                {listaEnderecos !== null && listaEnderecos.map((item, index) => {
                    return (
                        <CardEndereco
                            selecionado={enderecoSelecionado === item}
                            selecionarEndereco={selecionarEndereco}
                            key={index}
                            endereco={item}
                            isMobile={isMobile}
                        />
                    )
                })}
                <Box sx={{ cursor: 'pointer' }} onClick={() => setPopupEndereco(true)}>
                    <Typography color={Cores.COR_PRINCIPAL} fontWeight={700} sx={{ margin: '8px', marginTop: '0px', transform: 'translateY(24%)' }}>Adicionar</Typography>
                </Box>
            </Box>
            {enderecoSelecionado !== null &&
                <Box sx={{ width: isMobile ? '100%' : '70%', marginLeft: isMobile ? '0%' : '15%',display: 'flex', background: 'white', borderRadius: '10px', marginTop: '10px'}}>
                    <Box sx={{ width: '50%', textAlign: 'left', padding: '10px' }}>
                        <Box sx={{ display: 'flex' }}>
                            {icones.enderecoCasaPredio(true)}
                            <Typography sx={{ marginLeft: '5px' }} fontSize={18} color={Cores.COR_PRINCIPAL}><b>{enderecoSelecionado.apelido}</b></Typography>
                        </Box>
                        <Typography><b>CEP: </b>{enderecoSelecionado.cep}</Typography>
                        <Typography><b>Cidade: </b>{enderecoSelecionado.estado + ' - ' + enderecoSelecionado.cidade}</Typography>
                        <Typography><b>Endereço: </b> {enderecoSelecionado.rua + ' ' + enderecoSelecionado.numero + ' ' + enderecoSelecionado.complemento}</Typography>
                    </Box>
                </Box>
            }
            <Popup
                openPopup={popupEndereco}
                setOpenPopup={setPopupEndereco}
                title={"Endereços"}
                width={'100%'}
            >
                <Endereco
                    alterarLoad={alterarLoad}
                    notificar={notificar}
                    userDetails={userDetails}
                />
            </Popup>
        </Box>
    )
}