import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Media from "react-media";
import Controls from "../../../components/controls/Controls";
import { useCommon } from "../../../components/hooks/useCommon";
import CartasService from "../../../api/services/CartasService";
import * as Constantes from '../../../util/constantes';
import * as Cores from "../../../util/Cores";
import AreaCartasDeck from "./AreaCartasDeck";
import BarraPesquisaDeck from "./BarraPesquisaDeck";
import BuscarCartaDeck from "./BuscarCartaDeck";
import EstatisticasDeck from "./EstatisticasDeck";
import PoolCartasDeck from "./PoolCartasDeck";

export default function DeckBuilder(props) {
    const { userDetails, isLoggedIn } = props
    const [deckList, setDeckList] = useState([])
    const [cardsDeck, setCardsDeck] = useState([])
    const [poolCards, setPoolCards] = useState([])
    const [deckSelecionado, setDeckSelecionado] = useState([])
    const {alterarLoad, notificar, Common} = useCommon()

    useEffect(() => {
        consultarDecksNome(userDetails.id)
    }, []);

    async function consultarCartasDeck(deck) {
        await CartasService.consultarDeckCodigo(deck.deckId, notificar, alterarLoad, userDetails.id).then((response) => {
            let cartas = []
            response.cartasCompletasTO.forEach((cc) => {
                for(let i = 0; i < cc.quantidade; i++){
                    cartas.push(cc)
                }
            })
            setCardsDeck(cartas)
        })
    }

    async function salvarDeck() {
        let deckTO = {
            conteudo: cardsDeck.map((carta) => carta.cartaTO.id).join(';'),
            deckId: deckSelecionado.deckId
        }
        if (deckSelecionado.deckId !== 0 && cardsDeck.length > 0) {
            await CartasService.salvarDeck(deckTO, notificar, alterarLoad)
        }
    }

    async function consultarDecksNome(clienteId) {
        await CartasService.consultarDeckUsuario(clienteId, notificar, alterarLoad).then(response => {
            if (response.length === 0) {
                setDeckSelecionado({ deckId: 0, nome: Constantes.CRIAR_DECK })
            } else {
                setDeckSelecionado({ deckId: 0, nome: Constantes.ESCOLHER_DECK })
            }
            setDeckList(response)
        })
    }

    const adicionarCarta = (carta) => {
        if (cardsDeck.includes(carta)) {
            let index = cardsDeck.indexOf(carta)
            let size = cardsDeck.length
            let inicio = cardsDeck.slice(0, index)
            let fim = cardsDeck.slice(index, size)
            setCardsDeck(inicio.concat([carta].concat(fim)))
        } else {
            setCardsDeck([carta].concat(cardsDeck))
        }
    }

    return (
        <Box sx={{background: 'white', padding: '1%'}}>
            <Common /> 
            <Box sx={{width: '100%'}}>
                {isLoggedIn ?
                    <Media queries={{ small: { maxWidth: Constantes.MOBILE_SCREEN_SIZE } }}>
                        {matches =>
                            matches.small ? (
                                <Box sx={{ width: '100%' }}>
                                    <Box >
                                        <BarraPesquisaDeck
                                            setDeckList={setDeckList}
                                            deckList={deckList}
                                            deckSelecionado={deckSelecionado}
                                            setDeckSelecionado={setDeckSelecionado}
                                            userDetails={userDetails}
                                            notificar={notificar}
                                            consultarCartasDeck={consultarCartasDeck}
                                            cardsDeck={cardsDeck}
                                            isMobile={true}
                                        />
                                    </Box>
                                    <Box sx={{ display: deckSelecionado.deckId === 0 ? 'none' : 'contents', paddingBottom: '5%' }}>
                                        <BuscarCartaDeck
                                            userDetails={userDetails}
                                            setPoolCards={setPoolCards}
                                            poolCards={poolCards}
                                            cardsDeck={cardsDeck}
                                            setCardsDeck={setCardsDeck}
                                        />
                                    </Box>
                                    <Box sx={{ display: deckSelecionado.deckId === 0 ? 'none' : 'block', height: '50px', margin: '15px', marginBottom: '50px' }}>
                                        <PoolCartasDeck
                                            adicionarCarta={adicionarCarta}
                                            poolCards={poolCards}
                                            cardsDeck={cardsDeck}
                                            setCardsDeck={setCardsDeck}
                                        />
                                    </Box>
                                    <Box sx={{ display: deckSelecionado.deckId === 0 ? 'none' : 'block' }}>
                                        <AreaCartasDeck
                                            isLoggedIn={isLoggedIn}
                                            cardsDeck={cardsDeck}
                                            setCardsDeck={setCardsDeck}
                                            adicionarCarta={adicionarCarta}
                                            userDetails={userDetails}
                                        />
                                    </Box>
                                    <Box sx={{ display: deckSelecionado.deckId === 0 ? 'none' : 'block' }}>
                                        <EstatisticasDeck cardsDeck={cardsDeck} isMobile={true} />
                                        <Divider />
                                    </Box>
                                    <Controls.Button
                                        sx={{ marginTop: '3%', display: deckSelecionado.deckId === 0 ? 'none' : 'inline' }}
                                        text='Salvar'
                                        color='primary'
                                        onClick={() => salvarDeck()}
                                    />


                                </Box>
                            ) : (
                                <Box sx={{ width: '100%', display: 'flex' }}>
                                    <Box sx={{ width: '78%', borderRight: 'solid ' + Cores.COR_PRINCIPAL }}>
                                        <Box sx={{ width: '100%' }}>
                                            <BarraPesquisaDeck
                                                setDeckList={setDeckList}
                                                deckList={deckList}
                                                deckSelecionado={deckSelecionado}
                                                setDeckSelecionado={setDeckSelecionado}
                                                userDetails={userDetails}
                                                notificar={notificar}
                                                consultarCartasDeck={consultarCartasDeck}
                                                cardsDeck={cardsDeck}
                                            />

                                        </Box>
                                        <Box sx={{ display: deckSelecionado.deckId === 0 ? 'none' : 'contents', width: '100%' }}>
                                            <AreaCartasDeck
                                                isLoggedIn={isLoggedIn}
                                                cardsDeck={cardsDeck}
                                                setCardsDeck={setCardsDeck}
                                                adicionarCarta={adicionarCarta}
                                                userDetails={userDetails}
                                            />
                                        </Box>
                                        <Controls.Button
                                            sx={{ marginTop: '3%', display: deckSelecionado.deckId === 0 ? 'none' : 'inline' }}
                                            text='Salvar'
                                            color='primary'
                                            onClick={() => salvarDeck()}
                                        />
                                    </Box>
                                    <Box sx={{ display: deckSelecionado.deckId === 0 ? 'none' : 'block', width: '22%' }}>
                                        <Box sx={{ paddingBottom: '5%' }}>
                                            <BuscarCartaDeck
                                                userDetails={userDetails}
                                                setPoolCards={setPoolCards}
                                                poolCards={poolCards}
                                                cardsDeck={cardsDeck}
                                                setCardsDeck={setCardsDeck}
                                            />
                                        </Box>
                                        <Box>
                                            <Box >
                                                <PoolCartasDeck
                                                    adicionarCarta={adicionarCarta}
                                                    poolCards={poolCards}
                                                    cardsDeck={cardsDeck}
                                                    setCardsDeck={setCardsDeck}
                                                />
                                            </Box>
                                            <Box>
                                                <EstatisticasDeck cardsDeck={cardsDeck} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        }
                    </Media>
                    :
                    <Typography>
                        {Constantes.FACA_LOGIN}
                    </Typography>
                }
            </Box>
        </Box>
    )
}