import PrintIcon from '@mui/icons-material/Print';
import { Box, Fab } from "@mui/material";
import { BlobProvider } from "@react-pdf/renderer";
import * as Constantes from '../util/constantes';
import * as Cores from '../util/Cores';

export default function ImprimirCartas(props) {
    const { documento, filename, texto } = props

    return (
        <BlobProvider document={documento} fileName={filename}>
            {({ url }) =>
            (
                <a href={url} target="_blank" style={{ textDecoration: 'none' }}>
                    <Box sx={{
                       display: 'flex',
                        '& .MuiSvgIcon-root': {
                            marginLeft: '1%',
                            transform: 'scale(1.2)',
                            fill: Cores.COR_PRINCIPAL,
                        }
                    }}>

                        <PrintIcon />

                    </Box>
                </a>
            )}

        </BlobProvider >
    )
}