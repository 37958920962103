import { Box, Tooltip, Typography } from "@mui/material";

import FavoriteIcon from '@mui/icons-material/Favorite';

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import * as Utils from "../../../util/Utils";
import { useCommon } from "../../../components/hooks/useCommon";

export default function VisualizarCarta(props) {
    const { carta, setCarta, userDetails, atualizarCarta, isLoggedIn, isMobile } = props
    const { notificar, Common } = useCommon()

    return (
        <Box>
            <Common />
            <Box sx={{
                '& .MuiSvgIcon-root': {
                    marginTop: '5px',
                    marginInline: '6px',
                    '&:hover': {
                        cursor: 'pointer',
                        fill: '#F06292',
                    }
                }
            }}>
                {atualizarCarta !== null &&
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip title="Minha coleção" sx={{ display: isLoggedIn ? 'inline-flex' : 'none' }}>
                                {carta.ehColecao ?
                                    <ImportContactsIcon fontSize='large' color='primary' onClick={(e) => Utils.adicionarColecao(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                    : <ImportContactsIcon fontSize='large' onClick={(e) => Utils.adicionarColecao(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                }
                            </Tooltip>
                            <Tooltip title="Lista de desejos" sx={{ display: isLoggedIn ? 'inline-flex' : 'none' }}>
                                {carta.ehDesejo ?
                                    <FavoriteIcon fontSize='large' color='secondary' onClick={(e) => Utils.adicionarDesejo(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                    : <FavoriteIcon fontSize='large' onClick={(e) => Utils.adicionarDesejo(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                }
                            </Tooltip>

                            <Tooltip title="Minha coleção" sx={{ display: isLoggedIn ? 'inline-flex' : 'none' }}>
                                {carta.ehTroca ?
                                    <ChangeCircleIcon fontSize='large' sx={{ fill: '#2196f3' }} onClick={(e) => Utils.adicionarTroca(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                    : <ChangeCircleIcon fontSize='large' onClick={(e) => Utils.adicionarTroca(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                }
                            </Tooltip>
                        </Box>
                        <Box sx={{ alignItems: 'center', marginTop: '2.5%' }}>
                            <Typography variant='h5' fontWeight={700} color='green'>{' R$' + (carta.cartaTO.valor+ '').replace('.', ',')}</Typography>
                        </Box>
                    </Box>
                }

                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                    <img className="main-card" height='350px' data-src={carta.cartaTO.imgUrl} src={carta.cartaTO.imgUrl} />
                </Box>
                <Box sx={{ display: isMobile ? 'contents' : 'flex' }}>
                    <Box sx={{ marginLeft: '0', width: '100%', marginTop: '2%' }}>
                        <Typography variant='body2'> <b>Raridade</b>: {carta.raridadeDescricao} </Typography>
                        <Typography variant='body2'> <b>Edição:</b>{carta.ano + '-'} {carta.edicaoDescricao}</Typography>
                        <Typography variant='body2'> <b>Número:</b> {carta.cartaTO.numero}</Typography>
                        {carta.naturezaDescricao !== 'Nenhum' && <Typography variant='body2'> <b>Natureza:</b> {carta.naturezaDescricao}</Typography>}
                        {carta.nivelDescricao !== null && <Typography variant='body2'> <b>Nível:</b> {carta.nivelDescricao}</Typography>}
                    </Box>
                </Box>

            </Box>
            {/*carta.estoque !== '0' ? <LojaFragmento carta={carta} /> : null*/}
        </Box>
    )
}