import { Box, Pagination, Stack, Typography } from "@mui/material";
import { useEffect } from 'react';
import Media from 'react-media';
import * as Constantes from "../../util/constantes";
import CardCartaLoja from "./CardCartaLoja";

export default function Loja(props) {
    const { notificar, setCount, isMobile, listaVisualizacao,
        count, listaCartas, alterarCarrinho, setPage, page,
        isLoggedIn, userDetails } = props

    useEffect(() => {
        if (isMobile) {
            setCount(Math.ceil(listaVisualizacao.length / Constantes.CARD_PER_PAGE_MOBILE))
        } else {
            setCount(Math.ceil(listaVisualizacao.length / Constantes.CARD_PER_PAGE))
        }

    }, [])

    const handleChangePagination = (event, value) => {
        setPage(value);
    };

    return (
        <Box sx={{marginTop: '14px'}}>
            <Box sx={{
                marginTop: '10px',
                marginBottom: '10px',
                display: listaCartas.length > 0 ? 'contents' : 'none',
                '& .MuiPagination-ul': {
                    justifyContent: 'center'
                }
            }}>
                <Box>
                    <Typography color='primary'>
                        <b>Registros encontrados: {listaCartas.length}</b>
                    </Typography>
                    <Pagination sx={{ marginTop: '1%' }} count={count} color="secondary" page={page} onChange={handleChangePagination} />
                </Box>
            </Box>

            <Media queries={{ small: { maxWidth: Constantes.MOBILE_SCREEN_SIZE } }}>
                {matches =>
                    matches.small ? (
                        <Box>
                            {listaVisualizacao.slice((page - 1) * Constantes.CARD_PER_PAGE_MOBILE, page * Constantes.CARD_PER_PAGE_MOBILE).map((lista, i) => {
                                return (
                                    <Stack direction='row' key={i} sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }} spacing={isMobile ? 0 : 4}>
                                        {lista.map((carta, index) => {
                                            return (<CardCartaLoja
                                                isMobile={isMobile}
                                                key={index}
                                                alterarCarrinho={alterarCarrinho}
                                                carta={carta}
                                                isLoggedIn={isLoggedIn}
                                                userDetails={userDetails}
                                                width={100}
                                                notificar={notificar}
                                            />)
                                        })}
                                    </Stack>
                                )
                            })}
                        </Box>
                    ) : (
                        <Box>
                            {listaVisualizacao.slice((page - 1) * Constantes.CARD_PER_PAGE, page * Constantes.CARD_PER_PAGE).map((lista, i) => {
                                return (
                                    <Stack direction='row' key={i} sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }} spacing={4}>
                                        {lista.map((carta, index) => {
                                            return (<CardCartaLoja
                                                isMobile={isMobile}
                                                key={index}
                                                alterarCarrinho={alterarCarrinho}
                                                carta={carta}
                                                isLoggedIn={isLoggedIn}
                                                userDetails={userDetails}
                                                width={100}
                                                notificar={notificar}
                                            />)
                                        })}
                                    </Stack>
                                )
                            })}
                        </Box>
                    )
                }
            </Media>

            <Box sx={{
                marginTop: '10px',
                marginBottom: '10px',
                display: listaCartas.length > 0 ? 'contents' : 'none',
                '& .MuiPagination-ul': {
                    justifyContent: 'center'
                }
            }}>
                <Pagination count={count} color="secondary" page={page} onChange={handleChangePagination} />
            </Box>

        </Box>
    )
}