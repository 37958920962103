
export const Raridade = {
    Comum: {id: 1, nome: "Comum", background: '#4a4a4a', backgroundHover: '#333333'},
    Incomum: {id: 2, nome: "Incomum",  background: '#d4d4d4', backgroundHover: '#bababa'},
    Rara: {id: 3, nome: "Rara", background: '#f2da3a', backgroundHover: '#d6c133'},
    UltraRara : {id: 4, nome: "UltraRara", background: '#4e45ff', backgroundHover: '#483feb'},
    RaraSecreta: {id: 5, nome: "RaraSecreta", background: '#e02b73', backgroundHover: '#c22563'},
    Promo: {id: 6, nome: "Promo", background: '#e03dd8', backgroundHover: '#cf36c7'},
    Shiny: {id: 7, nome: "Shiny", background: '#b3ff30', backgroundHover: '#9ee02b'},
    
}