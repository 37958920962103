import { Box } from "@mui/material";
import * as Mensagens from "../../util/Mensagens";
import * as Icones from '../../util/icones';
import HomeCard from "./HomeCard";


export default function Botoes(props) {
    const { isMobile, handleRedirectCartas, handleRedirectLoja, handleRedirectSobreNos, handleRedirectTutorial } = props

    return (
        <Box sx={{ paddingTop: isMobile ? '5%' : '3%', width: isMobile ? '80%' : '60%', marginLeft: isMobile ? '10%' : '20%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <HomeCard
                    handleClick={handleRedirectCartas}
                    icone={Icones.colecoesSecundario}
                    texto={Mensagens.TEXTO_HOME_CARD_CARTAS} />
                {/* <HomeCard
                    handleClick={handleRedirectLoja}
                    icone={Icones.lojaSecundario}
                    texto={Mensagens.TEXTO_HOME_CARD_LOJA} /> */}
                <HomeCard
                    handleClick={handleRedirectSobreNos}
                    icone={Icones.sobreNosSecundario}
                    texto={Mensagens.TEXTO_HOME_CARD_SOBRE} />
                <HomeCard
                    handleClick={handleRedirectTutorial}
                    icone={Icones.tutorialSecundario}
                    texto={Mensagens.TEXTO_HOME_TUTORIAL} />
            </Box>
        </Box>
    )
}