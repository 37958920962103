import { Payment, initMercadoPago } from '@mercadopago/sdk-react';
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import * as pagamentoService from '../../../api/services/PagamentoService';
import * as Mascaras from '../../../util/Mascaras';
import * as Utils from '../../../util/Utils';
import StatusMP from "./StatusMP";
import './page.css';

const initialValues = {
    numeroCartao: '',
    parcela: 0,
    dataExpiracao: '',
    CodigoSeguranca: '',
    titular: '',
    cpf: '',
    bandeira: 0
}

initMercadoPago(process.env.REACT_APP_PUBLIC_KEY, { locale: 'pt-BR' });

export default function Bricks(props) {
    const { notificar, alterarLoad, userDetails, isMobile, dadosPagamento, removerCarrinho } = props
    const [preferencia, setPreferencia] = useState('inicio')
    const [paymentId, setPaymentId] = useState('')
    const [mostrar, setMostrar] = useState(false)

    useEffect(() => {
        (async () => {
            let preferencia = {
                items: dadosPagamento.items,
                payer: dadosPagamento.payer,
                clienteId: userDetails.id
            }
            setPreferencia(await pagamentoService.gerarPreferencia(preferencia, alterarLoad, notificar));
            setMostrar(true)
        })()
    }, [])

    const initialization = {
        amount: dadosPagamento.valor,
        preferenceId: preferencia,
        redirectMode: "blank"
    };
    const customization = {
        paymentMethods: {
            bankTransfer: "all",
            creditCard: "all",
        },
        payer: {
            email: userDetails.email
        }
    }

    const onSubmit = async (
        { selectedPaymentMethod, formData }
    ) => {
        return new Promise((resolve, reject) => {
            let request = ({
                ...formData, usuario: userDetails.id, pedido: dadosPagamento.cdPedido,
                preference: {
                    items: dadosPagamento.items,
                    payer: dadosPagamento.payer,
                    clienteId: userDetails.id
                }
            })
            pagamentoService.processarPagamento(request, alterarLoad, notificar).then((response) => {
                setPaymentId(response.paymentId)
                resolve();
                removerCarrinho(0, true)
            })
                .catch((error) => {
                    // lidar com a resposta de erro ao tentar criar o pagamento
                    reject();
                });
        });
    };

    const onError = async (error) => {
        // callback chamado para todos os casos de erro do Brick
        console.log(error);
    };

    const onReady = async () => {
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
    };

    return (
        <Box>
            {dadosPagamento.valor !== null && <Typography variant='h6'><b>Valor</b>: R${Mascaras.dinheiro(dadosPagamento.valor)}</Typography>}
            <Box sx={{ width: isMobile ? '95%' : '75%', marginLeft: isMobile ? '2.5%' : '12.5%' }}>
                {paymentId === '' &&
                    <Box>
                        {!Utils.stringVazia(preferencia) && preferencia !== 'inicio' && preferencia !== '0' &&
                            <Payment
                                initialization={initialization}
                                customization={customization}
                                onSubmit={onSubmit}
                                onReady={onReady}
                                onError={onError}
                            />
                        }

                    </Box>
                }
                <StatusMP
                    paymentId={paymentId}
                />
            </Box>
        </Box>

    )
}