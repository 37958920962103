import { useState } from 'react'
import { Grid, TableBody, TableCell, TableRow } from "@mui/material"
import { Box } from "@mui/system"
import Controls from "../../../components/controls/Controls"
import { Form, useForm } from "../../../components/UseForm"
import UseTable from "../../../components/UseTable"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CartasService from '../../../api/services/CartasService'
import Load from '../../../components/Load'
import ModalConfirmacao from '../../../components/ModalConfirmacao'
import Notificacao from '../../../components/Notificacao'
import Popup from '../../../components/Popup'
import CartaForm from './CartaForm'

const headCells = [
    { id: 'nome', label: 'Nome' },
    { id: 'numero', label: 'Número' },
    { id: 'edicao', label: 'Edição' },
    { id: 'acoes', label: '', disableSorting: true },
]

const initialValues = {
    id: '',
    nome: '',
    edicao: '',
    numero: ''
}

export default function AlterarCartaForm(props) {
    const [records, setRecords] = useState([])
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [filterFn, setFilterFn] = useState({ fn: items => { return items } })
    const [exibirLoad, setExibirLoad] = useState(false)
    const [exibirModal, setExibirModal] = useState(false)
    const [cartaExcluir, setCartaExcluir] = useState(initialValues)
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    })
    const { listaEdicao, listaNatureza, listaJogo, listaIdioma, listaRaridade, listaNivel } = props

    const validate = () => {

    }


    const salvarCarta = (carta) => {
        let response = CartasService.salvarCarta({
            id: carta.id,
            descricao: carta.descricao,
            nome: carta.nome,
            imgUrl: carta.imgUrl,
            edicao: carta.edicao,
            idioma: carta.idioma,
            natureza: carta.natureza,
            raridade: carta.raridade,
            nivel: carta.nivel,
            jogo: carta.jogo,
            valor: carta.valor,
            estoque: carta.estoque,
            data_inicio: new Date(),
            data_fim: new Date(),
            numero: carta.numero
        })
        if (!response.erro) {
            setNotify({
                isOpen: true,
                message: "Carta atualizada com sucesso!",
                type: "success",
            })
        } else {
            setNotify({
                isOpen: true,
                message: "Erro ao salvar carta.",
                type: "error",
            })
        }
    }

    const {
        values,
        handleInputChange,
        erros,
    } = useForm(initialValues, true, validate)

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = UseTable(records, headCells, filterFn);

    const openInPopup = (e, item) => {
        e.preventDefault()
        e.stopPropagation()
        consultarCarta(item)
    }

    async function consultarCarta(carta) {
        setExibirLoad(true)
        try {
            await CartasService.consultarCartaPorId(carta.id).then(response => {
                setRecordForEdit(response)
                setOpenPopup(true)
            })
        } catch (e) {
            setExibirLoad(false)
        } finally {
            setExibirLoad(false)
        }
    }

    async function buscarCarta() {
        setExibirLoad(true)
        try {
            await CartasService.consultarCartasPorNomeEdicao(values.edicao, values.nome).then(response => {
                setRecords(response)
            })
        } catch (e) {
            setExibirLoad(false)
        } finally {
            setExibirLoad(false)
        }
    }

    const excluirCarta = (e, item) => {
        setCartaExcluir(item)
        setExibirModal(true)
    }

    async function handleExcluirCarta(id) {
        await CartasService.deletarCarta(id).then(response => {
        })
    }

    const handleConfirmar = () => {
        handleExcluirCarta(cartaExcluir.id)
        setExibirModal(false)
        setNotify({
            isOpen: true,
            message: "Carta excluída com sucesso.",
            type: "success",
        })
    }

    return (
        <Box sx={{marginTop:'16px'}}>
            <Form>
                <Notificacao notify={notify} setNotify={setNotify} />
                <Load exibirLoad={exibirLoad} mensagemLoad='Consultando cartas...' />
                <Grid container spacing={3} sx={{ marginBottom: 2 }}>
                    <Grid item md={5} sm={6}>
                        <Controls.Input
                            name='nome'
                            label='Nome'
                            value={values.nome}
                            onChange={handleInputChange}
                            error={erros.nome}
                        />
                    </Grid>
                    <Grid item md={5} xs={6}>
                        <Controls.Select
                            name='edicao'
                            label='Edição'
                            value={values.edicao}
                            onChange={handleInputChange}
                            options={listaEdicao}
                            error={erros.edicao}
                        />
                    </Grid>
                    <Grid item md={2} xs={6} sx={{display: 'flex'}}>
                        <Controls.Button
                            text="Buscar"
                            onClick={buscarCarta}
                        />
                    </Grid>
                </Grid>
            </Form>

            <TblContainer >
                <TblHead />
                <TableBody sx={{background: 'white'}}>
                    {recordsAfterPagingAndSorting().map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.nome}</TableCell>
                            <TableCell>{item.numero}</TableCell>
                            <TableCell>{item.edicao}</TableCell>
                            <TableCell>
                                <EditIcon style={{ cursor: 'pointer' }} onClick={e => openInPopup(e, item)} />
                                <DeleteIcon style={{ cursor: 'pointer', marginLeft: '30px' }} onClick={e => excluirCarta(e, item)} />
                            </TableCell>
                        </TableRow>
                    )
                    )}
                </TableBody>
            </TblContainer>
            <Popup
                maxWidth='md'
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title={'Alterar Carta'}
                width='80%'
            >
                <CartaForm
                    listaNivel={listaNivel}
                    listaEdicao={listaEdicao}
                    listaNatureza={listaNatureza}
                    listaRaridade={listaRaridade}
                    listaIdioma={listaIdioma}
                    listaJogo={listaJogo}
                    salvarCarta={salvarCarta}
                    carta={recordForEdit}
                    setOpenPopup={setOpenPopup}
                />

            </Popup>
            <ModalConfirmacao
                exibirModal={exibirModal}
                setExibirModal={setExibirModal}
                handleConfirmar={handleConfirmar}
                texto={'Confirmar exclusão da carta ' + cartaExcluir.nome + '?'}
            />
        </Box>
    )
}