import { Box, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import CartasService from "../../../api/services/CartasService";
import Popup from "../../../components/Popup";
import { Form, useForm } from "../../../components/UseForm";
import Controls from "../../../components/controls/Controls";
import * as Cores from "../../../util/Cores";
import AlterarCartaForm from "./AlterarCartaForm";
import CartaForm from "./CartaForm";

const initialValues = {
    nome: '',
    estoque: 0,
    edicao: '',
    raridade: '',
    natureza: 0,
    idioma: '1',
    jogo: '1',
    imgUrl: '',
    valorUnitario: 0,
    numero: 0,
    nivel: 0
}

export default function AdminCartas(props) {
    const { listaNatureza, listaRaridade, listaEdicao, listaJogo, listaIdioma, listaNivel, notificar } = props
    const [openPopup, setOpenPopup] = useState(false)

    useEffect(() => {
        //consultarCartaPorId(4)
    }, []);

    const validate = (fieldValues = values) => {
        let temp = { ...erros };
        if ("nome" in fieldValues) {
            temp.nome =
                fieldValues.nome !== "" ? "" : "Campo obrigatório.";
        }

        if ("edicao" in fieldValues) {
            temp.edicao =
                fieldValues.edicao !== "" ? "" : "Campo obrigatório.";
        }

        setErros({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === "");
        }
    }

    const {
        values,
        setValues,
        handleInputChange,
        handleInputChangeSwitch,
        erros,
        resetForm,
        setErros
    } = useForm(initialValues, true, validate)

    const salvarCarta = () => {
        if (validate()) {
            let response = CartasService.salvarCarta({
                id: 0,
                nome: values.nome,
                nivel: values.nivel,
                imgUrl: values.imgUrl,
                edicao: values.edicao,
                idioma: values.idioma,
                natureza: values.natureza,
                raridade: values.raridade,
                jogo: values.jogo,
                valor: 10.00,
                estoque: 0,
                data_inicio: new Date(),
                data_fim: new Date(),
                numero: values.numero
            })
            if (!response.erro) {
                setValues({
                    ...values,
                    nome: '',
                    numero: Number(values.numero) + 1,
                    valorUnitario: 0,
                    estoque: 0
                })
                notificar("Carta inserida com sucesso!", "success")
            } else {
                notificar("Erro ao salvar carta.", "error")
            }
        }
    }

    const abrirAlterarExcluir = (e) => {
        setOpenPopup(true)
    }

    return (
        <Form>
            <Box sx={{ textAlign: 'left' }}>
                <Typography fontWeight={700} variant='h5' color={Cores.COR_PRINCIPAL}>Cartas</Typography>
                <Box style={{ borderBottom: '2px solid ' + Cores.COR_PRINCIPAL, width: '100%', marginBottom: '20px' }} />
                
                <CartaForm
                    listaNivel={listaNivel}
                    listaEdicao={listaEdicao}
                    listaNatureza={listaNatureza}
                    listaRaridade={listaRaridade}
                    listaIdioma={listaIdioma}
                    listaJogo={listaJogo}
                    salvarCarta={salvarCarta}
                    carta={null}
                    setOpenPopup={setOpenPopup}
                />
                <Controls.Button 
                    sx={{marginTop: '16px'}}
                    color='secondary'
                    text='Alterar/Excluir Carta'
                    onClick={(e) => abrirAlterarExcluir(e)}
                />
            </Box>

            <Popup
                maxWidth='md'
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title={'Alterar / Excluir Carta'}
            >
                <AlterarCartaForm
                    listaNivel={listaNivel}
                    listaEdicao={listaEdicao}
                    listaNatureza={listaNatureza}
                    listaRaridade={listaRaridade}
                    listaIdioma={listaIdioma}
                    listaJogo={listaJogo}
                />

            </Popup>
        </Form >
    )
}