import { Drawer, Stack, Typography } from "@mui/material";
import Controls from "./controls/Controls";
import MiniaturaCarrinho from "../page/perfil/pedido/MiniaturaCarrinho";
import { useState, useEffect } from 'react'
import * as Constantes from "../util/constantes";
import { useNavigate } from "react-router-dom";
import * as Cores from "../util/Cores";

export default function CarrinhoDrawer(props) {
    const { carrinho, setAnchor, removerCarrinho, alterarCarrinho, isMobile, notificar, abrirCarrinhoDrawer, anchor, width } = props
    const [total, setTotal] = useState(0.0)
    const navigate = useNavigate();

    const handleCheckout = () => {
        setAnchor(false)
        navigate("/" + Constantes.PAGE_CHECKOUT)
    }

    useEffect(() => {
        let novoTotal = 0
        carrinho.forEach((item) => novoTotal += item.valor * item.quantidade)
        setTotal(novoTotal.toFixed(2))
    }, [carrinho]);


    return (
        <Drawer
            anchor={'right'}
            open={anchor}
            onClose={e => { abrirCarrinhoDrawer(e, false) }}
            PaperProps={{
                sx: {
                    width: width < Constantes.MOBILE_SCREEN_SIZE ? "60%" : "17%",
                    borderTopLeftRadius: '20px',
                    borderBottomLeftRadius: '20px', background: Constantes.COR_SECUNDARIA_FUNDO
                },
            }}
        >
            <Stack spacing={1} padding={1}>
                {carrinho.length > 0 &&
                    <Controls.Button
                        text='Checkout'
                        variant='outlined'
                        size='medium'
                        sx={{ background: 'white' }}
                        onClick={(e) => handleCheckout(e)}
                    />}
                <Typography color={Cores.COR_PRINCIPAL} fontWeight={700}>Total: R${total}</Typography>
                {carrinho.length > 0 && carrinho.map((item, index) => {
                    return <MiniaturaCarrinho
                        notificar={notificar}
                        key={index}
                        isMobile={isMobile}
                        item={item}
                        index={index}
                        removerCarrinho={removerCarrinho}
                        alterarCarrinho={alterarCarrinho}
                    />
                })}
            </Stack>
        </Drawer>
    )
}