import { getDestaques, postSalvarEstoque, getBuscarCartaEstoque } from '../EstoqueApi'

async function consultarDestaques(notificar, alterarLoad) {
    alterarLoad(true)
    try {
        return await getDestaques().then(response => {
            return response.data
        })
    } catch (e) {
        notificar('Erro ao consultar destaques.', "error" )
    } finally {
        alterarLoad(false)
    }
}

async function buscarCartaEstoque(id, alterarLoad, notificar){
    alterarLoad(true)
    try {
        return await getBuscarCartaEstoque(id).then(response => {
            return response.data
        })
    } catch (e) {
        notificar('Erro ao consultar estoque.', "error" )
    } finally {
        alterarLoad(false)
    }
}

async function salvarEstoque(notificar, alterarLoad, estoque) {
    alterarLoad(true)
    try {
        return await postSalvarEstoque(estoque).then(response => {
            notificar('Estoque incluído com sucesso.', 'success')
            return response.data
        })
    } catch (e) {
        notificar('Erro ao salvar estoque.', "error" )
    } finally {
        alterarLoad(false)
    }
}

const EstoqueService = {
    consultarDestaques,
    salvarEstoque,
    buscarCartaEstoque
}


export default EstoqueService
