
import { Box } from "@mui/material";
import Controls from "../../../components/controls/Controls";


export default function BuscarCartaDeck(props) {
    const { setPoolCards, poolCards, setCardsDeck, cardsDeck, userDetails } = props
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Controls.AutocompleteCarta
                setPoolCards={setPoolCards}
                poolCards={poolCards}
                cardsDeck={cardsDeck}
                setCardsDeck={setCardsDeck}
                userDetails={userDetails}
            />

        </Box>
    )
}