import { Box, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import * as Cores from "../../../util/Cores";
import * as Utils from "../../../util/Utils";
import * as Constantes from "../../../util/constantes";
import CardItemPedido from "./CardItemPedido";

export default function ConfirmarPedido(props) {
    const { notificar, carrinho, alterarCarrinho, removerCarrinho } = props
    const [valorTotal, setValorTotal] = useState(0)
    const { width } = Utils.useWindowDimensions();
    const [listaPedido, setListaPedido] = useState([])
    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE
    
    useEffect(() => {
        let novoTotal = 0
        carrinho.forEach((item) => novoTotal += item.valor * item.quantidade)
        setValorTotal(novoTotal.toFixed(2))
        let grupos = Utils.dividirArrayEmGrupos(carrinho, isMobile ? Constantes.CARD_PER_ROW_MOBILE_PEDIDO : Constantes.CARD_PER_ROW_PEDIDO)
        setListaPedido(grupos)
    }, [carrinho]);



    return (
        <Box sx={{ padding: '5px' }}>
            <Typography sx={{ textAlign: 'center', color: Cores.COR_PRINCIPAL }} fontWeight={700} fontSize={26}>Valor total: R${valorTotal}</Typography>
            {listaPedido.length > 0 && listaPedido.map((linha, indexLinha) => {
                return (
                    <Box sx={{ display: 'flex' }} key={indexLinha}>
                        {linha.map((item, index) => {
                            return (
                                <Box key={index} sx={{width: isMobile ? '100%' : '20%', paddingInline: '5px'}}>
                                    <CardItemPedido
                                        notificar={notificar}
                                        key={index}
                                        item={item}
                                        index={index}
                                        removerCarrinho={removerCarrinho}
                                        alterarCarrinho={alterarCarrinho}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                )
            })}
        </Box>
    )
}