
import { Box } from "@mui/material";
import * as Cores from "../../../util/Cores";

export default function PoolCartasDeck(props) {
    const { poolCards, adicionarCarta} = props

    const handleClickCarta = (carta) => {
        adicionarCarta(carta)
    }

    return (
        <Box sx={{
            display: 'flex', float: 'left', overflowX: 'scroll',
            width: '100%',
            marginInline: '1%',
            "::-webkit-scrollbar": {
                width: "3px",
                height: '8px'
            },
            "::-webkit-scrollbar-track": {
                backgroundColor: '#fff5f5'
            },
            "::-webkit-scrollbar-thumb": {
                background: Cores.COR_PRINCIPAL,
                borderRadius: "15px",
                height: "1px"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: Cores.COR_PRINCIPAL,
                maxHeight: "1px",
            },
        }}>
            {poolCards.map((carta, key) => {
                return (
                    <Box
                        sx={{ marginInline: '2%', cursor: 'pointer' }}
                        key={key}
                        onClick={() => handleClickCarta(carta)}
                    >
                        <img className="main-card" width='60' data-src={carta.cartaTO.imgUrl} src={carta.cartaTO.imgUrl} />
                    </Box>
                )
            })}
        </Box>
    )
}