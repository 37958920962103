import { Box, Typography } from "@mui/material"
import BarraProgresso from "./BarraProgresso"



export default function ListaCards(props) {
    const { dadosEdicao, comPromo } = props
    
    return (
        <Box>
            {dadosEdicao.edicaoTO.nome !== "" ?
                <Box>
                    <Typography variant='h5' textAlign='left' fontWeight={700} color='primary' sx={{ margin: '10px 0px' }}>Edição {dadosEdicao.edicaoTO.nome}</Typography>

                    <Box>
                        <BarraProgresso
                            total={dadosEdicao.edicaoTO.tamanho}
                            totalPromo={dadosEdicao.tamanhoPromo}
                            valorAtual={dadosEdicao.edicaoDadosColecaoTO.quantidade}
                            valorAtualPromo={dadosEdicao.edicaoDadosColecaoTO.quantidadePromo}
                            titulo={"Progresso de coleção"}
                            comPromo={comPromo}
                            tipo={1}
                        />
                        <BarraProgresso
                            total={dadosEdicao.valorTotal}
                            totalPromo={dadosEdicao.valorTotalPromo}
                            valorAtual={dadosEdicao.edicaoDadosColecaoTO.valor.toFixed(2)}
                            valorAtualPromo={dadosEdicao.edicaoDadosColecaoTO.valorPromo === null ? 0 : dadosEdicao.edicaoDadosColecaoTO.valorPromo.toFixed(2)}
                            titulo={"Valor de coleção (R$)"}
                            comPromo={comPromo}
                            tipo={2}
                        />

                    </Box>

                </Box>
                :
                <Box>
                    Você não possui cartas nesta edição.
                </Box>
            }
        </Box>
    )
}