import { Box } from "@mui/material"

import tree1 from '../../img/tree1.png';
import folha1img from '../../img/folha1.png';
import folha2img from '../../img/folha2.png';
import folha3img from '../../img/folha3.png';
import folha4img from '../../img/folha4.png';
import { motion } from "framer-motion"
import "./styles.css";
import { useState } from "react";
import useInterval from "../hooks/useInterval"
import { useEffect } from "react";
import * as Utils from "../../util/Utils";
import * as Constantes from "../../util/constantes";

const DURATION = 5

const folhasImg = [folha1img, folha1img, folha1img, folha1img, folha2img, folha2img, folha2img, folha3img, folha3img, folha4img]

const folha = (inicialX, finalX, inicialY, finalY, delay, img, cont) => {
    return <motion.div
        key={cont}
        className="box"
        initial={{ x: inicialX, y: inicialY, scale: 0.5, opacity: 0 }}
        animate={{
            x: finalX,
            y: finalY,
            opacity: [0, 1, 1, 1, 1, 0, 0],
            rotate: [10, -10, 10, -10, 10, -10, 10]
        }}
        transition={{ delay: delay, duration: DURATION, repeat: Infinity }}
    >
        <img src={img} alt="tree" width='20%' style={{ position: 'absolute', left: 0, zIndex: 99, top: -20 }} />
    </motion.div>
}

export default function Folhagem(props) {
    let cont = 0
    const { height, width } = Utils.useWindowDimensions();
    const [folhas, setFolhas] = useState([{ id: 0, jsx: <div></div> }])
    const numeroFolhas = width > 1000 ? 20 : 8
    useEffect(() => {
        let folhasAux = []
        for (let i = 0; i < numeroFolhas; i++) {
            folhasAux.push(criarNovaFolha())
        }
        setFolhas(folhasAux)
    }, [])

    useInterval(() => {
        let folhasAux = folhas

        for (let i = 0; i < folhasAux.length; i++) {
            folhasAux[i] = { ...folhasAux[i], duration: folhasAux[i].duration - 0.1 }
            if (folhasAux[i].duration < 0.1) {
                folhasAux = folhasAux.filter((item, index) => index !== i ? item : criarNovaFolha())
            }
        }
        setFolhas(folhasAux)
    }, 100)

    const criarNovaFolha = () => {
        let ix = Utils.randomNumber(-50, width - 200, 1)
        let fx = Utils.randomNumber(20, 80, 1)
        let iy = -25
        let fy = Utils.randomNumber( width > Constantes.MOBILE_SCREEN_SIZE ? 200 : 50, width > Constantes.MOBILE_SCREEN_SIZE ? 600 : 300, 1)
        let delay = Utils.randomNumber(0, 10, 1)
        let folhaImg = Utils.randomNumber(0, folhasImg.length, 1)
        
        fx = fx > width - 100 ? width - 150: fx
        return {
            id: cont++,
            duration: DURATION + delay,
            jsx: folha(ix, ix + fx, iy, fy, delay, folhasImg[folhaImg], cont)
        }
    }

    return (
        <Box sx={{ pointerEvents: 'none' }}>
            <img src={tree1} alt="tree1" width={width > 1000 ? '25%' : '55%'}
                style={{ position: 'absolute', left: width > 1000 ? 0 : 0, zIndex: 86, top: -10 }} />
            <img src={tree1} alt="tree2" width={width > 1000 ? '25%' : '45%'}
                style={{ position: 'absolute', left: width > 1000 ? 220 : 90, zIndex: 85, top: width > 1000 ? -50 : -20 }} />
            <img src={tree1} alt="tree3" width={width > 1000 ? '25%' : '65%'}
                style={{ position: 'absolute', right:0, zIndex: 85, top: -20, transform: 'scaleX(-1)' }} />
            <img src={tree1} alt="tree4" width={width > 1000 ? '25%' : '45%'}
                style={{ position: 'absolute', right: width > 1000 ? 250 : 50, zIndex: 85, top: width > 1000 ? -50 : -20, transform: 'scaleX(-1)' }} />
            {folhas.map(folha => {
                return folha.jsx
            })}

        </Box>
    )
}