import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { IconButton, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import CarrinhoDrawer from '../../components/CarrinhoDrawer';
import ModalConfirmacao from '../../components/ModalConfirmacao';
import Folhagem from '../../components/animation/Folhagem';
import { useCommon } from '../../components/hooks/useCommon';
import * as Utils from '../../util/Utils';
import * as Constantes from '../../util/constantes';
import DrawerMenu from './DrawerMenu';

export default function HeaderBar(props) {
    const navigate = useNavigate();
    const { setUserDetails, carrinho, setCarrinho, corpo, removerCarrinho, alterarCarrinho, isLoggedIn, setIsLoggedIn, userDetails } = props
    const [anchor, setAnchor] = useState(false)
    const [exibirModal, setExibirModal] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const { height, width } = Utils.useWindowDimensions();
    const { Common, notificar } = useCommon()

    const handleClick = (e) => {
        setShowMenu(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setShowMenu(null)
    }

    const abrirCarrinhoDrawer = (e, open) => {
        e.preventDefault()
        e.stopPropagation()
        setAnchor(open)
    }

    const logout = () => {
        setExibirModal(false)
        setIsLoggedIn(false)
        setUserDetails({
            roles: '',
            username: '',
            id: 0,
            email: '',
            senha: ''
        })
        navigate("/")
    }

    const handleRedirectPerfil = () => {
        navigate("/perfil")
    }

    return (
        <Box sx={{ flexGrow: 1, width: '100%', pointerEvents: 'auto', top: 0, position: 'absolute' }}>
            <Common />
            {corpo === 'home' ?
                <Folhagem />
                :
                null
            }
            <AppBar position="absolute" sx={{ zIndex: 90, background: 'none', backdropFilter: 'blur(10px)' }}>
                <Toolbar sx={{ width: '100%' }}>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        '& .MuiSvgIcon-root': {
                            '&:hover': {
                                cursor: 'pointer',
                                fill: 'pink',
                            }
                        }
                    }}>


                        <Tooltip title="Menu">
                            <IconButton color="primary" component="label" onClick={handleClick} >
                                <MenuIcon fontSize='large' style={{ fill: 'white' }} />
                            </IconButton>
                        </Tooltip>

                        <Box>
                            <Box style={{ display: isLoggedIn ? 'contents' : 'none' }} onClick={handleRedirectPerfil} >
                                <Tooltip title="" style={{ display: 'contents' }} >
                                    <Box sx={{ cursor: 'pointer', display: 'flex' }}>
                                        {userDetails !== null ? userDetails.username : ''}
                                        <IconButton color="primary" component="label" >
                                            <AccountCircleIcon fontSize='large' style={{ fill: 'white' }} />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                            </Box>

                            <Tooltip title="Carrinho" onClick={e => abrirCarrinhoDrawer(e, true)} style={{ marginLeft: '15px' }}>
                                <IconButton color="primary" component="label" >
                                    <ShoppingCartIcon fontSize='large' style={{ fill: 'white' }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            <DrawerMenu
                isLoggedIn={isLoggedIn}
                setExibirModal={setExibirModal}
                handleCloseMenu={handleCloseMenu}
                handleClick={handleClick}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                abrirCarrinhoDrawer={abrirCarrinhoDrawer}
                isMobile={width < Constantes.MOBILE_SCREEN_SIZE}
            />

            <CarrinhoDrawer carrinho={carrinho}
                setAnchor={setAnchor}
                setCarrinho={setCarrinho}
                alterarCarrinho={alterarCarrinho}
                removerCarrinho={removerCarrinho}
                isMobile={width < Constantes.MOBILE_SCREEN_SIZE}
                notificar={notificar}
                abrirCarrinhoDrawer={abrirCarrinhoDrawer}
                anchor={anchor}
                width={width}
            /> 
            <ModalConfirmacao
                exibirModal={exibirModal}
                setExibirModal={setExibirModal}
                handleConfirmar={logout}
                texto='Deseja fazer o logout?'
            />
        </Box >
    );
}