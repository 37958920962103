import * as Mascaras from "../../../util/Mascaras";
import Controls from "../Controls";


export default function Cep(props) {
    const {onChange, value, error} = props

    return (
        <Controls.Input
            name='cep'
            label='CEP'
            onChange={onChange}
            value={Mascaras.cep(value)}
            error={error}
        />
    )
}