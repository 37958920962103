import { Box, Fab, Grid, Typography } from "@mui/material";

export default function BotaoLabel(props) {
    const { ativo, consultar, tipo, icone, cor, isMobile } = props

    const aparecer = () => {
        return (isMobile && ativo) || !isMobile
    }

    return (
        <Box sx={{ color: 'white' }}>
            <Box
                onClick={(e) => consultar('', tipo, tipo)}
                sx={{
                    zIndex: '10',
                    display: 'flex',
                    background: aparecer() && ativo ? cor : !isMobile && 'gray',
                    alignItems: 'center',
                    paddingRight: '16px',
                    borderRadius: aparecer() && '30px',
                    '& .MuiFab-root': {
                        background: cor
                    },
                    cursor: 'pointer',
                    '& .MuiSvgIcon-root': {
                        transform: 'scale(1.2)',
                        fill: "white",
                    }
                }}>
                <Fab aria-label="consultar">
                    {icone}
                </Fab>
                {aparecer() && <Typography sx={{ marginLeft: '8px' }} fontSize={'large'}>{tipo === 3 ? 'Coleção' : (tipo === 2 ? 'Desejo' : 'Troca')}</Typography>}
            </Box>
        </Box>
    )
}