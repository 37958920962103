import { Box, Typography } from "@mui/material";



export default function LabelDescricao(props) {
    const {label, descricao} = props

    return (
        <Box >
            <Typography><b>{label}</b> {descricao}</Typography>
        </Box>
    )

}