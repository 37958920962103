import { Box } from "@mui/material";
import { StatusScreen } from '@mercadopago/sdk-react';




export default function StatusMP(props) {
    const { paymentId } = props

    const initialization = {
        paymentId: paymentId, // id do pagamento a ser mostrado
    };
    const onError = async (error) => {
        // callback chamado para todos os casos de erro do Brick
        console.log(error);
    };
    const onReady = async () => {
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
    };

    return (
        <Box sx={{marginTop: '64px'}}>
            {paymentId !== '' &&
                <StatusScreen
                    initialization={initialization}
                    onReady={onReady}
                    onError={onError}
                />
            }
        </Box>
    )

}