


export default function PoliticaPrivacidade() {

   return (
      <div>
         <h2><span style={{ color: "rgb(68, 68, 68)" }}>Política Privacidade</span></h2>
         <p><span style={{ color: "rgb(68, 68, 68)" }}>A sua privacidade é importante para nós. É política do Summoner Cat respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site <a href="https://www.summonercat.com/">Summoner Cat</a>, e outros sites que possuímos e operamos.</span></p>
         <p><span style={{ color: "rgb(68, 68, 68)" }}>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.</span></p>
         <p><span style={{ color: "rgb(68, 68, 68)" }}>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</span></p>
         <p><span style={{ color: "rgb(68, 68, 68)" }}>Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</span></p>
         <p><span style={{ color: "rgb(68, 68, 68)" }}>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</span></p>
         <p><span style={{ color: "rgb(68, 68, 68)" }}>O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.</span></p>
         <p><span style={{ color: "rgb(68, 68, 68)" }}>Quaisquer dados pessoais serão utilizados unicamente para provimento de serviços (como por exemplo entregas) ou com finalidades estatísticas.</span></p>
         
         <h3><span style={{ color: "rgb(68, 68, 68)" }}>Compromisso do Usuário</span></h3>
         <p><span style={{ color: "rgb(68, 68, 68)" }}>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Summoner Cat oferece no site e com caráter enunciativo, mas não limitativo:</span></p>




         <ul style={{listStyleType: 'none'}}>
            <li><span style={{ color: "rgb(68, 68, 68)" }}>A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</span></li>
            <li><span style={{ color: "rgb(68, 68, 68)" }}>B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, jogos de sorte ou azar, conteúdo adulto, de apologia ao terrorismo ou contra os direitos humanos;</span></li>
            <li><span style={{ color: "rgb(68, 68, 68)" }}>C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Summoner Cat, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</span></li>
         </ul>
         <h3><span style={{ color: "rgb(68, 68, 68)" }}>Mais informações</span></h3>
         <p><span style={{ color: "rgb(68, 68, 68)" }}>Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, entre em contato.</span></p>
         <p><span style={{ color: "rgb(68, 68, 68)" }}>Esta política é efetiva a partir de&nbsp;13 August 2023 16:00</span></p>

      </div>
   )
}

