import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText
} from '@mui/material';
export default function ModalConfirmacao(props) {
    const {exibirModal, setExibirModal, handleConfirmar, texto } = props
    function handleCloseModal() {
        setExibirModal(false)
    }
    if (exibirModal) {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'xs'}
                open={exibirModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {texto}
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button variant='text' color="inherit" onClick={handleCloseModal}>Não</Button>
                    <Button variant='contained' color='primary' onClick={handleConfirmar}>Sim</Button>
                </DialogActions>
            </Dialog>
        )
    }
    return (null)
}