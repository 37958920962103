import axios from "axios";
import * as Utils from "../util/Utils"

const CONSULTAR_IMAGEM = 'ia/chatgpt/imagem/nomes'

const client = axios.create({
    baseURL: process.env.REACT_APP_AMBIENTE
});

export async function postImagemGpt(img) {
    return client.post(CONSULTAR_IMAGEM, img, Utils.getAuth())
}