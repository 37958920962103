


export const COR_PRINCIPAL = "#9d53b5"
export const COR_PRINCIPAL_ESCURO = "#804095"
export const COR_SECUNDARIA_AZUL = "#2196f3"
export const COR_SECUNDARIA_ROSA = "#f06292"
export const COR_SECUNDARIA_ROSA_ESCURO = "#c04e74"
export const COR_SECUNDARIA_FUNDO = "#f7ebf9"
export const COR_FUNDO_ERRO = "#ffd8d8"
export const COR_ERRO = '#ff4f4f'
export const COR_SECUNDARIA_ROSA_FUNDO = "#fdebf5"
export const COR_SECUNDARIA_ROSA_FUNDO_ESCURO = "#f7b7db"

export const COR_SUCESSO_FUNDO = '#c8facd'

export const COR_CONQUISTA_OURO = '#FFD700'
export const COR_CONQUISTA_PRATA = '#A9A9A9'
export const COR_CONQUISTA_BRONZE = '#CD7F32'
export const COR_CONQUISTA_NENHUM = '#DDDDDD'
export const COR_SECUNDARIA_FUNDO_OPACITY = 'rgba(247, 235, 249, 0.8)'
export const COR_PRIMARIA_FUNDO_OPACITY = 'rgba(140, 57, 191, 0.8)'