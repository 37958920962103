import ShareIcon from '@mui/icons-material/Share';
import { Box, Fab, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CartasService from "../../../api/services/CartasService";
import { useForm } from "../../../components/UseForm";
import Controls from "../../../components/controls/Controls";

const initialValues = {
    edicao: 0,
    jogo: 1,
    carta: ''
}

export default function BarraPesquisaCarta(props) {

    const { isMobile, jogo, valuesPai, consultarCartaDeClienteInicio} = props

    const [edicaoOpcoes, setEdicaoOpcoes] = useState([{ id: 0, value: 'Selecione' }])
    const [copiado, setCopiado] = useState(false)
    useEffect(() => {
        CartasService.consultarEdicaoPorJogo(jogo).then((response) => {
            setEdicaoOpcoes(response)
        })
    }, [jogo])

    const validate = (fieldValues = values) => {
        let temp = { ...erros }

        //validar aqui

        setErros({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        handleInputChange,
        erros,
        setErros,
        resetForm
    } = useForm(initialValues, true, validate)

    const handleSubmit = async e => {
        e.preventDefault()
        if (validate()) {
            await consultarCartaDeClienteInicio(values, 1)
        }
    }

    const clicarCopiar = () => {
        navigator.clipboard.writeText('https://www.summonercat.com/cartas?tipo=Pesquisar&nome=' + valuesPai.hash + '&jogo=' + valuesPai.jogo)
        setCopiado(true)
    }

    return (
        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '6px', width: '100%', alignItems: 'center' }}>
            <Grid item md={4} xs={6}>
                <Controls.Input
                    name='carta'
                    label='Nome da carta'
                    onChange={handleInputChange}
                    value={values.carta}
                    error={erros.carta}
                />
            </Grid>
            <Grid item md={3} xs={6}>
                <Controls.Select
                    name='edicao'
                    label='Edição'
                    value={values.edicao}
                    onChange={handleInputChange}
                    options={edicaoOpcoes}
                    error={erros.edicao}
                />
            </Grid>
            <Grid item md={2} xs={4}>
                <Controls.Button
                    text='Pesquisar'
                    onClick={(e) => handleSubmit(e)}
                />
            </Grid>
            {!isMobile &&
                <Grid item md={2} xs={3}>
                    <Controls.Button
                        text={copiado ? 'Copiado!' : 'Compartilhar'}
                        onClick={() => { clicarCopiar() }}
                    />

                </Grid>
            }
            <Grid item md={1} xs={3.5}>
                <Controls.Button
                    text='Limpar'
                    color='primary'
                    variant='outlined'
                    onClick={() => resetForm()}
                />
            </Grid>

            {isMobile &&
                < Grid item md={2} xs={3.5}>
                    <Box>
                        {copiado ?
                            <Controls.Button
                                text='Copiado!'
                                onClick={() => { clicarCopiar() }}
                            />
                            :
                            <Fab color="primary" size='medium' onClick={(e) => { clicarCopiar(e) }}>
                                <ShareIcon sx={{ fill: 'white' }} />
                            </Fab>
                        }
                    </Box>
                </Grid>
            }
        </Grid >
    )
}