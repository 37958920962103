import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import * as Cores from "../../util/Cores";

export default function NewTabButton(props) {
    const { link, texto } = props
    return (
        <Box sx={{backgroundColor: Cores.COR_PRINCIPAL, padding: '1%', borderRadius: '15px'}}>
            <Link style={{color: 'white', textDecoration: 'none'}} to={link} target="_blank"> <Typography> {texto} </Typography></Link>

        </Box>
    )
}