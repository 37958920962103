import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Typography } from "@mui/material";
import Media from "react-media";
import NewTabButton from "../../components/controls/NewTabButton";
import roadmapImg from '../../img/roadmap2.png';
import * as Constantes from "../../util/constantes";

export default function SobreWrapper() {

    return (
        <Box sx={{ paddingBottom: '5%', backdropFilter: 'blur(10px)', background: Constantes.COR_SECUNDARIA_FUNDO_OPACITY }}>
            <Box sx={{ marginInline: '10%', textAlign: 'left' }}>
                <Typography sx={{ paddingTop: '4%' }} variant='h3' color='primary'> Sobre</Typography>
                <Typography sx={{ paddingTop: '1%' }} textAlign={'justify'}>
                    Pretendemos criar um serviço com qualidade e com o propósito de facilitar o gerenciamento de coleções de cartas de TCG, fornecendo uma espécie de fichário online
                    para ajudar a organizar todas as suas cartas e ajudar durante as trocas entre os jogadores. Esperamos um dia sermos o portal favorito para jogadores de TCG!
                    Valores: qualidade, segurança e confiança.
                </Typography>

                <Typography sx={{ paddingTop: '4%' }} variant='h3' color='primary'>Missão</Typography>
                <Typography sx={{ paddingTop: '1%' }} textAlign={'justify'}>
                    Ajudar o colecionador a completar e organizar sua coleção, fornecendo uma plataforma gamificada com serviços de qualidade e que evocam a fantasia e a experiência
                    do card game. Fomentar o hobby de card game para aumentar a base de jogadores.
                </Typography>
                <Typography sx={{ paddingTop: '4%' }} variant='h3' color='primary'>Visão</Typography>
                <Typography sx={{ paddingTop: '1%' }} textAlign={'justify'}>Ser a plataforma favorita de card games do Brasil.</Typography>
                <Typography sx={{ paddingTop: '4%' }} variant='h3' color='primary'>Negócio</Typography>
                <Typography sx={{ paddingTop: '1%' }} textAlign={'justify'}>Centralizar serviços de gerenciamento de coleções, agrupando jogadores em um lugar de forma a suprir todas as suas necessidades.</Typography>
            </Box>


            <Box sx={{ display: 'flex', paddingTop: '4%', marginInline: '10%' }}>
                <NewTabButton link="/termos" texto="Termos de Uso" />
                <Box sx={{ paddingInline: '2%' }} />
                <NewTabButton link="/politica" texto="Política de Privacidade" />
            </Box>

            <Box sx={{ marginTop: '3%', marginInline: '10%', textAlign: 'left' }}>
                <Typography variant='h5' color='primary'> Contato</Typography>
                <Box sx={{ paddingTop: '1%' }}>
                    <Typography textAlign={'justify'}> Nos envie sugestões, críticas, elogios ou solicitações de parceria através do nosso email, ou nos encontre no Instagram!</Typography>
                    <Box sx={{ display: 'flex', paddingTop: '2%' }}>
                        <InstagramIcon fontSize="large" />
                        <Typography variant="h6" sx={{ transform: 'translate(10px)' }}>@Summonercat</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <EmailIcon fontSize="large" />
                        <Typography variant="h6" sx={{ transform: 'translate(10px)' }}>summonercat.contato@gmail.com</Typography>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ marginTop: '3%', marginInline: '10%', textAlign: 'left' }}>
                <Typography variant='h5' color='primary'> Roadmap</Typography>

                <Media queries={{ small: { maxWidth: Constantes.MOBILE_SCREEN_SIZE } }}>
                    {matches =>
                        matches.small ? (
                            <Box sx={{ textAlign: "center" }}>
                                <img src={roadmapImg} alt="image" width='300px' />
                            </Box>
                        ) : (
                            <Box sx={{ textAlign: "center" }}>
                                <img src={roadmapImg} alt="image" />
                            </Box>
                        )
                    }
                </Media>

                <Typography variant='h6' color='primary'> 2024/T3 </Typography>
                <Typography sx={{ marginTop: '0.5%' }} textAlign={'justify'}> Lançamento do site para acesso geral, tendo limitação de 400 cartas por coleção para
                    mensurar as limitações dos servidores. Neste período, iremos focar em ouvir o público e focar nas melhorias de bugs, avaliar sugestões e
                    entender as reais necessidades, fazendo a validação do propósito do sistema. Iremos focar em melhorar a interface e concluir os desenvolvimentos
                    das telas. Este ano terá como prioridade a otimização e estabilização do sistema, garantindo que esteja seguro e confiável.</Typography>
                <Typography variant='h6' color='primary' sx={{ marginTop: '2%' }} > 2024/T4 </Typography>
                <Typography sx={{ marginTop: '0.5%' }} textAlign={'justify'}> Aqui, conforme evolução da utilização dos servidores, iremos aumentar o tamanho do limite
                    das coleções. Também focaremos nas melhorias de visualização das coleções, incluindo melhorias no Dashboard, com mais estatísticas e funcionalidades. 
                    Além disso, iremos iniciar os testes da loja integrada para assinantes. Aqui será concluído a fase de avaliação do usuário e entrega do projeto, onde
                    será decidido quanto à sua utilidade para o usuário.
                </Typography>

                <Typography variant='h6' color='primary' sx={{ marginTop: '2%' }}> 2025/T1 </Typography>
                <Typography sx={{ marginTop: '0.5%' }} textAlign={'justify'}> Para o ano que vem, o foco será na gamificação. A ideia do projeto é utilizar elementos gamificados,
                    como rankings e conquistas. Além disso, ter um ambiente que lembre um jogo, então teremos personagens interativos que possuem integração com o chatGPT. Esses
                    personagens serão auxiliares no sistema, como por exemplo um vendedor da loja ou um pet que te ajuda na elaboração de baralhos. O uso do sistema também evolui
                    o pet, permitindo mais interações com o mesmo.

                </Typography>

                <Typography variant='h6' color='primary' sx={{ marginTop: '2%' }}> 2025/T2 </Typography>
                <Typography sx={{ marginTop: '0.5%' }} textAlign={'justify'}> Neste trimestre, provavelmente teremos a continuidade das questões de gamificação e será
                    avaliado a expansão para outros jogos. Porém, como ainda existem muitas variáveis, ainda não é possível definir com exatidão este cronograma. TBD!
                </Typography>
            </Box>
        </Box>

    )
}