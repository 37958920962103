import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Snackbar, Typography } from "@mui/material";
import * as Cores from "../util/Cores";

export default function Notificacao(props) {

    const { notify, setNotify } = props;
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setNotify({
            ...notify,
            isOpen: false,
        });
    };

    return (
        <Snackbar
            open={notify.isOpen}
            autoHideDuration={2500}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleClose}
            style={{ border: notify.type === 'error' ? '2px solid red' : '2px solid ' + Cores.COR_PRINCIPAL, backgroundColor: 'white', padding: '10px', borderRadius: '50px' }}
        >
            <Box sx={{ display: 'flex' }}>
                {notify.type === 'error' ?
                    <ErrorOutlineIcon style={{ fill: 'red' }} />
                    :
                    <CheckCircleOutlineIcon style={{fill: Cores.COR_PRINCIPAL}}/>
                }
                <Typography style={{ marginLeft: '5px' }} color={notify.type === 'error' ? 'red' : Cores.COR_PRINCIPAL}>{notify.message}</Typography>
            </Box>
        </Snackbar>
    );
}
