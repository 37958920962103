import { Box, Grid, Typography } from "@mui/material";
import EstoqueService from "../../../../api/services/EstoqueService";
import { Form, useForm } from "../../../../components/UseForm";
import Controls from "../../../../components/controls/Controls";
import * as Cores from "../../../../util/Cores";
import * as Mensagens from "../../../../util/Mensagens";

const initialValues = {
    quantidade: 1,
    idioma: 1,
    desconto: 0,
    destaque: false,
    id: 0,
    cartaId: 0
}
export default function Estoque(props) {
    const validate = (fieldValues = values) => {
        let temp = { ...erros }
        if ('emailLogin' in fieldValues) {
            temp.emailLogin = fieldValues.emailLogin !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
        }

        if ('senha' in fieldValues) {
            temp.senha = fieldValues.senha !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
        }
        setErros({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")

    }
    const { listaIdioma, notificar, alterarLoad } = props


    const {
        values,
        setValues,
        handleInputChange,
        erros,
        resetForm,
        setErros
    } = useForm(initialValues, true, validate)

    const setCartaEscolhida = (carta) => {
        if (carta.length > 0) {
            let idSelecionado = carta[0].id
            EstoqueService.buscarCartaEstoque(idSelecionado, alterarLoad, notificar).then((response) => {
                if (response.id !== 0) {
                    setValues({
                        ...values, cartaId: idSelecionado, quantidade: response.quantidade,
                        idioma: response.idiomaId, desconto: response.desconto, destaque: response.destaque, id: response.id
                    })
                } else {
                    setValues({ ...initialValues, cartaId: idSelecionado })
                }

            })
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (validate()) {
            let estoque = {
                id: values.id, idiomaId: values.idioma, cartaId: values.cartaId, quantidade:
                    values.quantidade, destaque: values.destaque, desconto: values.desconto
            }
            EstoqueService.salvarEstoque(notificar, alterarLoad, estoque)
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Box sx={{ textAlign: 'left', marginTop: '16px' }}>
                <Typography variant='h5' color={Cores.COR_PRINCIPAL} fontWeight={700}>Estoque</Typography>
                <Box style={{ borderBottom: '2px solid ' + Cores.COR_PRINCIPAL, width: '100%', marginBottom: '20px' }} />
            </Box>
            <Grid container spacing={3}>
                <Grid item md={4} xs={6}>
                    <Controls.BuscarCartaNomeId
                        setCartaEscolhida={setCartaEscolhida}
                    />
                </Grid>
                <Grid item md={1} xs={2}>
                    <Controls.Input
                        name='quantidade'
                        label='Quantidade'
                        onChange={handleInputChange}
                        value={values.quantidade}
                        error={erros.quantidade}
                    />
                </Grid>
                <Grid item md={1} xs={2}>
                    <Controls.Input
                        name='desconto'
                        label='Desconto'
                        onChange={handleInputChange}
                        value={values.desconto}
                        error={erros.desconto}
                        inputPropText='%'
                    />
                </Grid>
                <Grid item md={2} xs={2}>
                    <Controls.Select
                        name='idioma'
                        label='Idioma'
                        value={values.idioma}
                        onChange={handleInputChange}
                        options={listaIdioma}
                        error={erros.idioma}
                    />
                </Grid>
                <Grid item md={1} xs={2}>
                    <Controls.Checkbox
                        name='destaque'
                        label='Destaque'
                        onChange={handleInputChange}
                        value={values.destaque}
                        error={erros.destaque}
                    />
                </Grid>
                <Grid item md={6} xs={6}
                    sx={{ display: 'flex' }}>
                    <Controls.Button
                        text='Inserir'
                        type='submit'
                    />
                    <Controls.Button
                        sx={{ marginLeft: '16px', background: 'white' }}
                        text='Limpar'
                        variant='outlined'
                        onClick={() => resetForm()}
                        color='secondary'
                    />
                </Grid>
            </Grid>
        </Form>
    )
}