import { Autocomplete, Box, CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import CartasService from "../../api/services/CartasService";

export default function BuscarCartaNomeId(props) {
    const [options, setOptions] = useState([])
    const [exibirLoad, setExibirLoad] = useState()
    const [listaCartas, setListaCartas] = useState()
    const { setCartaEscolhida } = props

    const handleChangeValue = (e, valor) => {
        if (valor.length > 2 && !exibirLoad) {
            consultar(valor)
        }
    }

    const getImagem = (id) => {
        return listaCartas.filter((x) => x.id === id)[0].imagem
    }

    const handleSelecionaItem = (e, carta) => {
        setCartaEscolhida(listaCartas.filter((x) => carta.id === x.id))
    }

    async function consultar(nome) {
        await CartasService.consultarCartaPorNomeSimples(nome, null, setExibirLoad).then(response => {
            setListaCartas(response)
            setOptions(
                response.map((item) => {
                    return { id: item.id, label: item.nome + " - " + item.edicaoDescricao + ' - ' + item.numero }
                })
            )
        })
    }

    return (
        <Autocomplete
            disablePortal
            id="buscarCarta"
            options={options}
            sx={{
                width: '85%',
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Carta"
                    sx={{background: 'white'}}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position='start'>
                                <CircularProgress
                                    size={25}
                                    disableShrink={true}
                                    variant={exibirLoad ? "indeterminate" : 'determinate'}
                                    value={0}
                                />
                            </InputAdornment>
                        )
                    }}
                />}

            renderOption={(props, option) => (
                <Box key={option.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="40"
                        src={getImagem(option.id)}
                        alt=""
                    />
                    {option.label}
                </Box>
            )}
            onChange={(e, valor) => handleSelecionaItem(e, valor)}
            onInputChange={(e, valor) => { handleChangeValue(e, valor) }}
            noOptionsText={'Nenhum resultado'}
        />
    )
}