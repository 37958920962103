import * as Utils from './Utils'

export function cartao(valor) {
    if (Utils.stringVazia(valor)) return ''
    let str = tam(digitos(valor), 16).replace(/(\d{4})(\d)/, "$1 $2").replace(/(\d{4})(\d)/, "$1 $2").replace(/(\d{4})(\d)/, "$1 $2")
    return str
}

export function formatarTimestamp(timestamp) {
    const options = { year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute:'2-digit' }
    return new Date(timestamp).toLocaleDateString(undefined, options)
}

export function dataExpiracao(valor) {
    if (Utils.stringVazia(valor)) return ''
    let str = tam(digitos(valor), 6).replace(/(\d{2})(\d)/, "$1/$2")
    return str
}

export function dinheiro(valor) {
    if(valor === null) return 0.00
    valor = (valor + '').replaceAll(',', '.').replaceAll("[^0-9.]", "")
    return Number(valor).toFixed(2)
}
export function tamTexto(texto, tamanhoMaximo = 15) {
    if (texto.length > tamanhoMaximo) {
        return texto.substring(0, tamanhoMaximo) + '...'
    }
    return texto
}

export function digitos(valor) {
    if (Utils.stringVazia(valor)) return ''
    return (valor + '').replace(/\D/g, '')
}
export function tam(texto, tamanhoMaximo = 15) {
    if (texto === undefined) {
        return ''
    }
    if (texto.length > tamanhoMaximo) {
        return texto.substring(0, tamanhoMaximo)
    }
    return texto
}

export function cep(cep) {
    cep = cep.replace(/\D/g, '')
    cep = tam(cep, 8)
    cep = cep.length > 2 ? cep.substring(0, 2) + '.' + cep.substring(2, cep.length) : cep
    cep = cep.length > 6 ? cep.substring(0, 6) + '-' + cep.substring(6, cep.length) : cep
    return cep
}

export function cpf(v) {
    if (v === undefined || v === '') return ''
    if (v.length >= 14) v = tam(v, 14)
    v = v.replace(/\D/g, "")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return v
}
