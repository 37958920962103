import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import InventoryIcon from '@mui/icons-material/Inventory';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import { Box, Typography } from "@mui/material";
import logoImg from '../../img/logoVerticalComPersonagemBeta.png';
import * as Cores from "../../util/Cores";
import * as Constantes from "../../util/constantes";


export default function Info(props) {
    const { isMobile } = props
    return (
        <Box>
            <Box sx={{
                width: '100%', marginTop: '3%'
            }}>
                <Typography variant='h4' fontWeight={700} color={Cores.COR_PRINCIPAL_ESCURO} sx={{ marginBottom: '2%' }}>Organize e compartilhe suas coleções de TCG!</Typography>
                <Box >
                    <img src={logoImg} alt="header img" height={isMobile ? '300px' : '400px'} overflow='hidden' />
                </Box>
            </Box>


            <Box sx={{ 
                marginTop: '16px', 
                padding: '16px', 
                display: isMobile ? 'contents' : 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                background: Constantes.COR_SECUNDARIA_FUNDO_OPACITY, 
                borderRadius: '45px'
                }}>

                <Box sx={{ display: 'flex' }}>
                    <Box sx={{
                        padding: '8px 4px', background: isMobile && Constantes.COR_SECUNDARIA_FUNDO_OPACITY, height: '150px', width: isMobile ? '100%' : '200px',
                        '& .MuiSvgIcon-root': {
                            fill: Cores.COR_PRINCIPAL_ESCURO
                        }
                    }}>
                        <InventoryIcon fontSize="large" />
                        <Typography fontWeight={700} color={Cores.COR_PRINCIPAL_ESCURO}>Organize sua coleção</Typography>
                        <Typography variant={ !isMobile ? 'body1' : 'caption'} color={Cores.COR_PRINCIPAL_ESCURO}>Veja quando vale e quanto precisa para completar cada edição</Typography>
                    </Box>


                    <Box sx={{
                        padding: '8px 4px', background: isMobile && Constantes.COR_SECUNDARIA_FUNDO_OPACITY,height: '150px', width: isMobile ? '100%' : '200px',
                        '& .MuiSvgIcon-root': {
                            fill: Cores.COR_PRINCIPAL_ESCURO
                        }
                    }}>
                        <ImportContactsIcon fontSize="large" style={{ fill: Cores.COR_PRINCIPAL_ESCURO }} />
                        <Typography fontWeight={700} color={Cores.COR_PRINCIPAL_ESCURO}>Suas cartas, um álbum</Typography>
                        <Typography variant={ !isMobile ? 'body1' : 'caption'} color={Cores.COR_PRINCIPAL_ESCURO}>Suas coleções são um álbum para: facilidade para trocar e completar</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex' }}>

                    <Box sx={{
                        padding: '8px 4px', background: isMobile && Constantes.COR_SECUNDARIA_FUNDO_OPACITY,height: '150px', width: isMobile ? '50%' : '200px',
                        '& .MuiSvgIcon-root': {
                            fill: Cores.COR_PRINCIPAL_ESCURO
                        }
                    }}>
                        <ChangeCircleIcon fontSize="large" />
                        <Typography fontWeight={700} color={Cores.COR_PRINCIPAL_ESCURO}>Compartilhe e troque</Typography>
                        <Typography variant={ !isMobile ? 'body1' : 'caption'} color={Cores.COR_PRINCIPAL_ESCURO}>Selecione suas cartas para troca e compartilhe com quem quiser</Typography>
                    </Box>


                    <Box sx={{
                        padding: '8px 4px', background: isMobile && Constantes.COR_SECUNDARIA_FUNDO_OPACITY,height: '150px', width: isMobile ? '50%' : '200px',
                        '& .MuiSvgIcon-root': {
                            fill: Cores.COR_PRINCIPAL_ESCURO
                        }
                    }}>
                        <SmartphoneOutlinedIcon fontSize="large" />
                        <Typography fontWeight={700} color={Cores.COR_PRINCIPAL_ESCURO}>Tudo em um só lugar</Typography>
                        <Typography variant={ !isMobile ? 'body1' : 'caption'} color={Cores.COR_PRINCIPAL_ESCURO}>Preços e loja integrados, coleções, baralhos e diversão!</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}