import EmailIcon from '@mui/icons-material/EmailOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import grass from '../../img/grass.png';
import logoImg from '../../img/logoTextoBranca.png';
import * as Cores from "../../util/Cores";

export default function FooterMobile(props) {
    const { userDetails } = props
    const navigate = useNavigate();
    const handleClickRedireciona = (e, param) => {
        e.stopPropagation()
        e.preventDefault()
        navigate(param)
    }

    return (
        <Paper sx={{ backgroundColor: Cores.COR_PRINCIPAL, marginTop: '20px', padding: '1%', paddingBottom: '100px' }}>
            <Box sx={{ paddingTop: '4%' }}>
                <img src={logoImg} style={{ marginRight: '10%' }} alt="Logo" height='50px' overflow='hidden' />
                <Box sx={{ textAlign: 'center', width: '100%' }}>
                    <Typography color='white' fontWeight={700}>
                        Páginas
                    </Typography>
                    {userDetails !== null && userDetails.id !== 0 &&
                        <Typography color='white'
                            onClick={(e) => handleClickRedireciona(e, '/dashboard')}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                        >
                            Dashboard
                        </Typography>
                    }
                    {userDetails !== null && userDetails.id !== 0 &&
                        <Typography color='white'
                            onClick={(e) => handleClickRedireciona(e, '/perfil')}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                        >
                            Perfil
                        </Typography>
                    }
                    <Typography color='white'
                        onClick={(e) => handleClickRedireciona(e, '/cartas')}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    >
                        Cartas
                    </Typography>
                    <Typography color='white'
                        onClick={(e) => handleClickRedireciona(e, '/sobre')}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    >
                        Sobre
                    </Typography>
                    <Typography color='white'
                        onClick={(e) => handleClickRedireciona(e, '/tutorial')}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    >
                        Tutorial
                    </Typography>
                </Box>


                    <Typography color='white' textAlign={'center'} fontWeight={700} sx={{paddingTop: '2%'}}>
                        Contato
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <InstagramIcon sx={{ fill: 'white' }} />
                        <Typography  textAlign={'center'} sx={{ color: 'white', transform: 'translate(4px, 6%)' }}>Summonercat</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <EmailIcon sx={{ fill: 'white' }} />
                        <Typography  textAlign={'center'} color='white' sx={{ transform: 'translate(4px, 6%)' }}>summonercat.contato@gmail.com</Typography>
                    </Box>
            

                <img src={grass} alt="grass" width={'45%'}
                    style={{ position: 'absolute', zIndex: 85, bottom: 0, right: 50, transform: 'scaleX(1.1)', pointerEvents: 'none' }} />
            </Box>
        </Paper>
    )
}