import { Box, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Controls from "../../components/controls/Controls";
import * as Cores from "../../util/Cores";
import * as Mensagens from "../../util/Mensagens";
import * as Constantes from "../../util/constantes";
import * as Icones from '../../util/icones';
import Info from './Info';
import Botoes from "./Botoes";

export default function HomeWeb(props) {
    const { userDetails, isMobile } = props

    const navigate = useNavigate();

    const handleRedirectSobreNos = () => {
        navigate("/sobre")
    }
    const handleRedirectCartas = () => {
        navigate("/cartas")
    }
    const handleRedirectTutorial = () => {
        navigate("/tutorial")
    }
    const handleRedirectLoja = () => {
        navigate("/loja")
    }
    const handleRedirectLogin = () => {
        navigate("/login")
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ backdropFilter: 'blur(10px)', background: Constantes.COR_SECUNDARIA_FUNDO_OPACITY, paddingBottom: '5%' }}>
                <Box sx={{
                    alignItems: 'center', width: '100%', paddingInline: !isMobile && '5%', textAlign: 'left',
                    display: isMobile ? 'contents' : 'flex', justifyContent: 'space-around'
                }}>
                    <Box sx={{ marginTop: '3%', paddingInline: '6px', marginLeft: !isMobile && '30px', textAlign: 'center', paddingTop: isMobile && '10%' }}>
                        <Typography variant={isMobile ? 'h3' : 'h2'} color={Cores.COR_PRINCIPAL_ESCURO} textAlign={'center'} fontWeight={700}>
                            {Mensagens.TEXTO_HOME_TITLE}
                        </Typography>
                        <Botoes
                            handleRedirectCartas={handleRedirectCartas}
                            handleRedirectLoja={handleRedirectLoja}
                            handleRedirectSobreNos={handleRedirectSobreNos}
                            handleRedirectTutorial={handleRedirectTutorial}
                            isMobile={isMobile}
                        />
                        {userDetails !== null && userDetails.id === 0 &&
                            <Controls.Button
                                startIcon={Icones.loginSecundario}
                                text={Mensagens.LOGIN}
                                onClick={() => handleRedirectLogin()}
                                variant='outlined'
                                color='primary'
                                sx={{ border: '3.5px solid', width: '50%', marginTop: isMobile? '3%' : '2%', fontSize: '25px', background: 'white',
                                    '&:hover': {
                                        border: '3.5px solid', background: Constantes.COR_SECUNDARIA_ROSA_FUNDO
                                    }
                                 }}
                            />
                        }
                        <Info
                            isMobile={isMobile}
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}