import { Box, Grid, Typography } from "@mui/material";
import { useState } from 'react';
import ClienteService from "../../api/services/ClienteService";
import ModalConfirmacao from "../../components/ModalConfirmacao";
import { Form, useForm } from "../../components/UseForm";
import Controls from "../../components/controls/Controls";
import * as Cores from "../../util/Cores";
import * as Mensagens from "../../util/Mensagens";

export default function Perfil(props) {
    const { userDetails, setUserDetails, notificar, alterarLoad } = props
    const [isAlterar, setIsAlterar] = useState(false)
    const [exibirModal, setExibirModal] = useState(false)

    const validate = (fieldValues = values) => {
        let temp = { ...erros }
        if ('email' in fieldValues) {
            temp.email = fieldValues.email !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
            if (temp.email !== Mensagens.CAMPO_OBRIGATORIO) {
                temp.email = (/^$|.+@.+..+/).test(fieldValues.email) ? '' : Mensagens.CAMPO_INVALIDO
            }
        }
        if ('username' in fieldValues) {
            temp.username = fieldValues.username.length >= 4 ? '' : Mensagens.CAMPO_OBRIGATORIO
        }

        setErros({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        handleInputChange,
        handleInputChangeSwitch,
        erros,
        resetForm, 
        setErros
    } = useForm(userDetails, true, validate)

    const handleAlterar = () => {
        setIsAlterar(!isAlterar)
    }

    const handleConfirmar = () => {
        setExibirModal(false)
        cadastrar()
    }

    async function cadastrar() {
        try {
            await ClienteService.cadastrarCliente({
                userTO: {
                    username: values.username,
                    password: "",
                },
                id: values.id,
                isAlterar: true,
                email: values.email
            }).then(response => {
                if (response.status !== 200) {
                    notificar(Mensagens.ERRO_SERVICO, "error")
                } else if (!response.data.erro) {
                    setUserDetails({ ...userDetails, username: values.username, email: values.email })
                    notificar(Mensagens.ALTERAR_CADASTRO_SUCESSO, "success")
                } else {
                    notificar(Mensagens.CADASTRO_ERRO + response.data.mensagem, "error")
                }
            })
        } catch (e) {
        }

    }

    const handleSubmeterAlteracao = (e) => {
        e.preventDefault()
        if (validate()) {
            setIsAlterar(!isAlterar)
            setExibirModal(true)
        }
    }
    return (
        <Form>
            <Box sx={{ textAlign: 'left' }}>
                <Typography variant='h5' color={Cores.COR_PRINCIPAL}>Dados de usuário</Typography>
                <Box style={{ borderBottom: '2px solid ' + Cores.COR_PRINCIPAL, width: '100%', marginBottom: '20px' }}></Box>
                <Grid container spacing={3}>
                    <Grid item md={2} sm={6}>
                        <Controls.Input
                            name='username'
                            label='Username'
                            value={values.username}
                            onChange={handleInputChange}
                            error={erros.username}
                            disabled={!isAlterar}
                        />
                    </Grid>
                    <Grid item md={2} sm={6}>
                        <Controls.Input
                            name='email'
                            label='Email'
                            value={values.email}
                            onChange={handleInputChange}
                            error={erros.email}
                            disabled={!isAlterar}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ textAlign: 'left', marginTop: '30px' }}>
                <Controls.Button
                    text='Alterar'
                    onClick={handleAlterar}
                    disabled={isAlterar}
                />
                <Controls.Button
                    style={{ marginLeft: '10px' }}
                    text='Salvar'
                    disabled={!isAlterar}
                    onClick={(e) => handleSubmeterAlteracao(e)}
                />
                <Controls.Button
                    style={{ marginLeft: '10px' }}
                    text='Cancelar'
                    variant='outlined'
                    onClick={handleAlterar}
                    disabled={!isAlterar}
                    sx={{ background: 'white' }}
                />
            </Box>

            <ModalConfirmacao
                exibirModal={exibirModal}
                setExibirModal={setExibirModal}
                handleConfirmar={handleConfirmar}
                texto='Confirmar alteração de informações?'
            />
        </Form>
    )
}