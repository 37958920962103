import { Box, Fab, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { Form, useForm } from "../../components/UseForm";
import Controls from "../../components/controls/Controls";
import CartasService from "../../api/services/CartasService";
import ShareIcon from '@mui/icons-material/Share';

const initialValues = {
    edicao: 0,
    jogo: 1,
    carta: ''
}

export default function Filtros(props) {
    const { setDadosEdicao, isMobile, alterarLoad, userDetails } = props
    const [edicaoOpcoes, setEdicaoOpcoes] = useState([{ id: 0, value: 'Selecione' }])
    const [copiado, setCopiado] = useState(false)

    const clicarCopiar = () => {
        navigator.clipboard.writeText('https://www.summonercat.com/cartas?tipo=Pesquisar&nome=' + userDetails.username + '&jogo=' + values.jogo)
        setCopiado(true)
    }

    useEffect(() => {
        CartasService.consultarEdicaoPorJogo(1).then((response) => {
            setEdicaoOpcoes(response)
        })
    }, [])

    async function selecionarJogo(e) {
        setValues({ ...values, jogo: e.target.value })
        CartasService.consultarEdicaoPorJogo(e.target.value).then((response) => {
            setEdicaoOpcoes(response)
        })
    }

    async function selecionarEdicao(e) {
        setValues({ ...values, edicao: e.target.value })
        CartasService.consultarDashboardEdicao(alterarLoad, e.target.value, userDetails.id).then((response) => {
            setDadosEdicao(response)
        })
    }

    const validate = (fieldValues = values) => {
        let temp = { ...erros }

        //validar aqui

        setErros({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {

        }
    }

    const {
        values,
        setValues,
        handleInputChange,
        erros,
        setErros
    } = useForm(initialValues, true, validate)

    return (
        <Form onSubmit={handleSubmit} >
            <Grid container spacing={3} sx={{ padding: '8px' }}>
                <Grid item md={4} xs={4}>
                    <Controls.Dados.Jogo
                        onChange={selecionarJogo}
                        value={values.jogo}
                        error={erros.jogo}
                    />
                </Grid>
                <Grid item md={5} xs={5}>
                    <Controls.Select
                        name='edicao'
                        label='Edição'
                        value={values.edicao}
                        onChange={(e) => selecionarEdicao(e)}
                        options={edicaoOpcoes}
                        error={erros.edicao}
                    />
                </Grid>
                <Grid item md={3} xs={2}>
                    {isMobile ?
                        <Box>
                            {copiado ?
                                <Controls.Button
                                    text='Copiado!'
                                    onClick={() => { clicarCopiar() }}
                                />
                                :
                                <Fab color="primary" size='medium' onClick={(e) => { clicarCopiar(e) }}>
                                    <ShareIcon sx={{ fill: 'white' }} />
                                </Fab>
                            }
                        </Box>
                        :
                        <Controls.Button
                            text={copiado ? 'Copiado!' : 'Compartilhar'}
                            onClick={() => { clicarCopiar() }}
                        />
                    }
                </Grid>
            </Grid>
        </Form>
    )
}