import { FormControl, FormControlLabel, FormHelperText, Checkbox as MuiCheckbox } from "@mui/material";

export default function Checkbox(props) {

    const { name, label, value, onChange, error, ...other } = props

    const convertToDefEventParameter = (name, value) => ({
        target: {
            name,
            value
        }
    })

    return (
        <FormControl error={error !== ""}>
            <FormControlLabel
                label={label}
                control={<MuiCheckbox
                    name={name}
                    checked={value}
                    onChange={e => { onChange(convertToDefEventParameter(name, e.target.checked))}}
                    color='primary'
                    {...other}
                />}
            >

            </FormControlLabel>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}