import { Box, Typography } from "@mui/material"

export const CAMPO_OBRIGATORIO = 'Campo obrigatório.'
export const SENHAS_DIFEREM = 'Senhas diferem.'
export const TERMOS_DEVEM_SER_ACEITOS = "Termos de uso e Política de Privacidade devem ser aceitos."
export const CAMPO_INVALIDO = 'Formato inválido.'
export const FORMATO_SENHA = 'Senha deve contar números e letras.'
export const FORMATO_EMAIL = 'Insira um email válido.'
export const ERRO_SERVICO = 'Falha de conexão com o serviço. Tente novamente mais tarde.'
export const OLA_ALTERAR_SENHA = (nome) => {
    return (
        <Typography component='span'>Olá, <strong>{nome}</strong> ! Você solicitou a alteração de sua senha. Preencha os campos abaixo para completar a alteração. Este link se expira em 30 minutos.</Typography>
    )
}

export const OLA_ATIVAR_CONTA = (nome) => {
    return (
        <Typography component='span'>Olá, <strong>{nome}</strong> ! Clique no botão abaixo para ativar sua conta.</Typography>
    )
}

export const OLA_FAZER_LOGIN = 'Pronto! Agora você pode fazer login na plataforma Summoner Cat.'

export const CADASTRO_SUCESSO = 'Cadastro realizado com sucesso.'
export const ALTERAR_CADASTRO_SUCESSO = 'Alteração cadastral realizada com sucesso.'
export const CADASTRO_ERRO = 'Erro ao criar cadastro. '
export const CONTA_ATIVADA_SUCESSO = "Conta ativada com sucesso."
export const CONTA_ATIVADA_ERRO = "Erro ao ativar conta. Tente novamente mais tarde."
export const CONSULTANDO_CARTAS = 'Consultando cartas'
export const CARREGANDO_PAGINA = 'Carregando página'
export const LINK_INDISPONIVEL = 'Link indisponível.'
export const FAZENDO_LOGIN = 'Fazendo Login'
export const ERRO_LOGIN_INVALIDO = 'Usuário e/ou senha inválido(s).'
export const LOGIN = "Login"
export const CAMPO_TAMANHO_INVALIDO = "Campo incompleto."
export const LIMITE_DECK = 'Limite de decks atingido.'

export const TEXTO_HOME_TITLE = 'Bem vindo(a)!'
export const TEXTO_HOME_SUBTITLE = 'Aqui você pode administrar sua coleção e compartilhar com seus amigos!'
export const TEXTO_HOME_COLECAO = 'Cadastre aqui sua coleção e facilite sua visualização! Para que toda vez se dar ao trabalho de procurar se você possui alguma carta em uma pilha de fichários e caixas quando você pode gerenciar tudo online?'
export const TEXTO_HOME_DESEJO = 'Mantenha no radar as cartas que deseja adicionando-as à sua Lista de Desejos! Assim fica fácil lembrar qual carta você precisa quando estiver trocando ou comprando uma nova carta.'
export const TEXTO_HOME_TROCA = 'Nunca foi fácil deixar separadinho todas as suas cartas de troca e ficar levando de um lado para o outro. Aqui, você pode verificar tudo o que você tiver disponível para trocas em qualquer lugar que estiver!'
export const TEXTO_HOME_PESQUISAR = 'Quando criar sua conta, cada uma de suas listas receberá um código, contendo o nome de três Pokémons. Esses códigos podem ser compartilhados, assim você poderá mostrar sua coleção, visualizar as cartas de trocas de seus amigos e mostrar a eles as cartas que você está procurando!'

export const TEXTO_HOME_CARD_CADASTRO = 'Cadastre-se aqui!'
export const TEXTO_HOME_CARD_LOGIN = 'Entre e acesse tudo!'
export const TEXTO_HOME_CARD_SOBRE = 'Sobre nós'
export const TEXTO_HOME_CARD_CARTAS = 'Cartas'
export const TEXTO_HOME_CARD_LOJA = 'Loja'
export const TEXTO_HOME_TUTORIAL = 'Serviços'
export const TEXTO_TUTORIAL_DECK_BUILDER = "Crie e planeje seus decks ou procure algum para se inspirar!"
export const PRIMEIRO_ACESSO_MENSAGEM = ' Olá, somos a Summoner Cat! Estamos criando este sistema para ajudar os jogadores a organizar suas coleções. Para report de bugs ou sugestões, enviar mensagem para summonercat.contato@gmail.com.'
export const TEXTO_COLECAO_ALBUM = "Visualize sua coleção como um álbum e deixe mais fácil e divertido para completá-la. Veja quanto sua coleção vale e quanto ainda precisa investir para finalizar."
export const TEXTO_TUTORIAL_LOJA = "Uma loja conectada com seus desejos para te trazer exatamente o que falta para terminar sua coleção."
export const TEXTO_TUTORIAL_FICHARIO = "Tire foto do seu fichário e busque as cartas em um clique! Veja facilmente os preços e não perca tempo digitando carta por carta."