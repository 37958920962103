import * as Mascaras from "../../../util/Mascaras";
import Controls from "../Controls";

export default function Cpf(props) {
    const {id='', onChange, value, error} = props

    return (
        <Controls.Input
            name='cpf'
            label='CPF'
            onChange={onChange}
            value={Mascaras.cpf(value)}
            error={error}
            id={id}
        />
        
    )
}