import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Backdrop, Box, CircularProgress, Snackbar, Typography } from "@mui/material";
import { useState } from 'react';
import * as Cores from "../../util/Cores";

export const useCommon = () => {
    const [exibirLoad, setExibirLoad] = useState(false)
    const [mensagemLoad, setMensagemLoad] = useState('Carregando...')
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    })

    const notificar = (texto, tipo) => {
        setNotify({
            isOpen: true,
            message: texto,
            type: tipo,
        })
    }

    const alterarLoad = (valor) => {
        setExibirLoad(valor)
    }

    const alterarMensagemLoad = (valor) => {
        setMensagemLoad(valor)
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setNotify({
            ...notify,
            isOpen: false,
        });
    };

    const Notificacao = () => {
        return (
            <Snackbar
                open={notify.isOpen}
                autoHideDuration={2200}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleClose}
                style={{
                    border: notify.type === 'error' ? '2px solid red' : '2px solid ' + Cores.COR_PRINCIPAL,
                    backgroundColor: 'white', padding: '10px', borderRadius: '50px',
                    position: 'fixed',
                    top: 20
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    {notify.type === 'error' ?
                        <ErrorOutlineIcon style={{ fill: 'red' }} />
                        :
                        <CheckCircleOutlineIcon style={{ fill: Cores.COR_PRINCIPAL }} />
                    }
                    <Typography style={{ marginLeft: '5px' }} color={notify.type === 'error' ? 'red' : Cores.COR_PRINCIPAL}>{notify.message}</Typography>
                </Box>
            </Snackbar>
        )
    }

    const Load = () => {
        return (
            <div>
                {exibirLoad ?
                    <Backdrop sx={{ color: "#fff", zIndex: 99 }} open={exibirLoad}>
                        <Box sx={{ display: "grid" }}>
                            <Typography variant='h5'>{mensagemLoad}</Typography>
                            <Box sx={{ textAlign: 'center' }}>
                                <CircularProgress color="inherit" />
                            </Box>
                        </Box>
                    </Backdrop>
                    : null}
            </div>
        )
    }
    const Common = () => {
        return (
            <div>
                <Load />
                <Notificacao />
            </div>
        )
    }

    return { Common, Notificacao, Load, notificar, alterarLoad, alterarMensagemLoad }
}