import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import VisualizarCarta from "../page/Cartas/visualizarCarta/VisualizarCarta";
import ChipEstoque from '../page/loja/ChipEstoque';
import * as Utils from '../util/Utils';
import * as Constantes from "../util/constantes";
import Popup from "./Popup";
import { useCommon } from './hooks/useCommon';

export default function Carta(props) {
    const [carta, setCarta] = useState({ ehColecao: false, ehDesejo: false })
    const [exibirPagina, setExibirPagina] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const { notificar, Common } = useCommon()
    const { pagina, index, cartaInfo, alterarCarrinho, atualizarCarta, isLoggedIn, userDetails, width } = props

    useEffect(() => {
        if (cartaInfo !== undefined) {
            setCarta(cartaInfo)
            setExibirPagina(true)
        }
    }, [cartaInfo]);

    const openInPopup = () => {
        setOpenPopup(true)
    }

    const adicionarCompra = (e) => {
        e.stopPropagation()
        let result = alterarCarrinho(0, 1, carta)
        if (result) {
            notificar('1 ' + carta.cartaTO.nome + ' adicionado(a) ao carrinho.', 'success')
        } else {
            notificar('Limite da carta em estoque atingido.', 'error')
        }
    }

    return (
        <Box style={{ marginInline: '4px', marginBottom: '8px', padding: '2px', width: width < Constantes.MOBILE_SCREEN_SIZE ? '50%' : '22%', background: 'white', borderRadius: '25px' }} key={index}>
            <Common />
            {exibirPagina &&
                (width < Constantes.MOBILE_SCREEN_SIZE ?

                    <Box onClick={() => { openInPopup() }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '3px',
                            marginBottom: '6px',
                            '& .MuiSvgIcon-root': {
                                marginRight: '6px',
                                '&:hover': {
                                    cursor: 'pointer',
                                    fill: '#F06292',
                                }
                            }
                        }}>
                            <Box sx={{ textAlign: 'left', display: isLoggedIn ? 'flex' : 'none' }}>
                                <Tooltip title="Minha coleção">
                                    {carta.ehColecao ?
                                        <ImportContactsIcon color='primary' onClick={(e) => Utils.adicionarColecao(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                        : <ImportContactsIcon onClick={(e) => Utils.adicionarColecao(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                    }
                                </Tooltip>
                                <Tooltip title="Lista de desejos">
                                    {carta.ehDesejo ?
                                        <FavoriteIcon color='secondary' onClick={(e) => Utils.adicionarDesejo(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                        : <FavoriteIcon onClick={(e) => Utils.adicionarDesejo(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                    }
                                </Tooltip>

                                <Tooltip title="Cartas de troca">
                                    {carta.ehTroca ?
                                        <ChangeCircleIcon sx={{ fill: '#2196f3' }} onClick={(e) => Utils.adicionarTroca(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                        : <ChangeCircleIcon onClick={(e) => Utils.adicionarTroca(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                    }
                                </Tooltip>


                                {carta.cartaTO.estoque > 0 &&
                                    <Tooltip title="Adicionar ao carrinho">
                                        <AddShoppingCartIcon onClick={(e) => adicionarCompra(e)} />
                                    </Tooltip>
                                }
                            </Box>
                        </Box>
                        <Box style={{ display: 'contents', width: 'fit-content', cursor: 'pointer' }}                        >
                            <img style={{ borderRadius: '5px' }} className="main-card" height='197px' width='140px' data-src={carta.cartaTO.imgUrl} src={carta.cartaTO.imgUrl} />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Box>
                                <Box>
                                    <Box sx={{ marginInline: '5px' }}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ '&:hover': { cursor: 'pointer', color: 'purple', textShadow: '0 0 1px purple' } }}>
                                                <Typography variant='subtitle2' style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                                    {carta.cartaTO.nome}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', zIndex: 0, marginLeft: '10px' }}>
                                                <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_COMUM ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: 'black', color: 'white' }}>C</Box>
                                                <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_INCOMUM ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: 'gray', color: 'white' }}>U</Box>
                                                <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_RARA ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: '#f2b91b', color: 'white' }}>R</Box>
                                                <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_ULTRA_RARA ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: 'blue', color: 'white' }}>UR</Box>
                                                <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_RARA_SECRETA ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: 'purple', color: 'white' }}>SR</Box>
                                                <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_PROMO ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: 'green', color: 'white' }}>P</Box>
                                            </Box>

                                        </Box>
                                        <Box sx={{ display: 'flex', textAlign: 'left', '&:hover': { cursor: 'pointer', color: 'purple', textShadow: '0 0 1px purple' } }}>
                                            <Typography variant='caption'>
                                                {carta.cartaTO.numero + '-' + carta.edicaoDescricao}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Typography variant='body1' sx={{ textAlign: 'left', marginLeft: '10px'  }} fontWeight={700} color='green'>{' R$' + (carta.cartaTO.valor+ '').replace('.', ',')}</Typography>
                            {pagina === 'loja' &&
                                <ChipEstoque
                                    adicionarCompra={adicionarCompra}
                                    estoque={carta.cartaTO.estoque}
                                />
                            }
                        </Box>
                    </Box >

                    :

                    <Box style={{ padding: '3px', borderRadius: '25px', background: 'white' }} onClick={() => { openInPopup() }}>
                        <Box style={{ display: 'flex' }}>
                            <Box style={{ display: 'contents', width: 'fit-content', cursor: 'pointer' }}                        >
                                <img style={{ borderRadius: '15px' }} className="main-card" height='197px' width='140px' data-src={carta.cartaTO.imgUrl} src={carta.cartaTO.imgUrl} />
                            </Box>
                            <Box sx={{ width: '100%', marginLeft: '10px', marginRight: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Box>
                                    <Box sx={{
                                        marginBottom: '8px',
                                        '& .MuiSvgIcon-root': {
                                            marginRight: '12px',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                fill: '#F06292',
                                            }
                                        }
                                    }}>
                                        <Box sx={{ textAlign: 'left', display: isLoggedIn ? 'flex' : 'none' }}>
                                            <Tooltip title="Minha coleção">
                                                {carta.ehColecao ?
                                                    <ImportContactsIcon color='primary' onClick={(e) => Utils.adicionarColecao(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                                    : <ImportContactsIcon onClick={(e) => Utils.adicionarColecao(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                                }
                                            </Tooltip>
                                            <Tooltip title="Lista de desejos">
                                                {carta.ehDesejo ?
                                                    <FavoriteIcon color='secondary' onClick={(e) => Utils.adicionarDesejo(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                                    : <FavoriteIcon onClick={(e) => Utils.adicionarDesejo(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                                }
                                            </Tooltip>

                                            <Tooltip title="Cartas de troca">
                                                {carta.ehTroca ?
                                                    <ChangeCircleIcon sx={{ fill: '#2196f3' }} onClick={(e) => Utils.adicionarTroca(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                                    : <ChangeCircleIcon onClick={(e) => Utils.adicionarTroca(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                                }
                                            </Tooltip>
                                            {carta.cartaTO.estoque > 0 &&
                                                <Tooltip title="Adicionar ao carrinho">
                                                    <AddShoppingCartIcon onClick={(e) => adicionarCompra(e)} />
                                                </Tooltip>
                                            }
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ '&:hover': { cursor: 'pointer', color: 'purple', textShadow: '0 0 1px purple' } }}>
                                                    <Typography variant='subtitle2' style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                                        {carta.cartaTO.nome}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', zIndex: 0, marginLeft: '10px' }}>
                                                    <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_COMUM ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: 'black', color: 'white' }}>C</Box>
                                                    <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_INCOMUM ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: 'gray', color: 'white' }}>U</Box>
                                                    <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_RARA ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: '#f2b91b', color: 'white' }}>R</Box>
                                                    <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_ULTRA_RARA ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: 'blue', color: 'white' }}>UR</Box>
                                                    <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_RARA_SECRETA ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: 'purple', color: 'white' }}>SR</Box>
                                                    <Box sx={{ transform: 'translate(0px, 2px)', display: carta.cartaTO.raridade === Constantes.RARIDADE_PROMO ? 'block' : 'none', width: '16px', height: '16px', fontSize: '12px', fontWeight: 'bold', borderRadius: '50px', backgroundColor: 'green', color: 'white' }}>P</Box>
                                                </Box>

                                            </Box>
                                            <Box sx={{ display: 'flex', textAlign: 'left', '&:hover': { cursor: 'pointer', color: 'purple', textShadow: '0 0 1px purple' } }}>
                                                <Typography variant='caption'>
                                                    {carta.cartaTO.numero + '-' + carta.edicaoDescricao}
                                                </Typography>
                                            </Box>
                                        </Box>


                                    </Box>
                                </Box>
                                <Typography variant='body1' sx={{ textAlign: 'left'}} fontWeight={700} color='green'>{' R$' + (carta.cartaTO.valor + '').replace('.', ',')}</Typography>
                                {pagina === 'loja' &&
                                    <ChipEstoque
                                        adicionarCompra={adicionarCompra}
                                        estoque={carta.cartaTO.estoque}
                                    />
                                }
                            </Box>
                        </Box>
                    </Box >
                )
            }
            {exibirPagina &&
                <Popup
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    title={carta.cartaTO.nome}
                    width={width < Constantes.MOBILE_SCREEN_SIZE ? '100%' : '22%'}
                >
                    <VisualizarCarta
                        carta={carta}
                        isLoggedIn={isLoggedIn}
                        setCarta={setCarta}
                        userDetails={userDetails}
                        atualizarCarta={atualizarCarta}
                        isMobile={width < Constantes.MOBILE_SCREEN_SIZE}
                    />
                </Popup>
            }
        </Box >
    )
}