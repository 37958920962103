import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useState } from 'react';
import Popup from '../../../components/Popup';
import * as Cores from "../../../util/Cores";
import * as Utils from '../../../util/Utils';
import * as Constantes from "../../../util/constantes";
import VisualizarCarta from "../visualizarCarta/VisualizarCarta";

export default function ListaCartaDeck(props) {
    const { cardsDeck, setCardsDeck, adicionarCarta, removerCarta, titulo, tipo, isLoggedIn, userDetails } = props
    const [openPopup, setOpenPopup] = useState(false)
    const [cartaInPopup, setCartaInPopup] = useState({cartaTO: {nome: ''}})
    const { height, width } = Utils.useWindowDimensions();

    const verificarTipoPokemon = (carta) => {
        if (tipo === Constantes.TIPO_TREINADOR) {
            return carta.naturezaDescricao.includes(Constantes.TIPO_TREINADOR)
        } else if (tipo === Constantes.TIPO_ENERGIA) {
            return carta.naturezaDescricao.includes(Constantes.TIPO_ENERGIA)
        } else {
            return !carta.naturezaDescricao.includes(Constantes.TIPO_TREINADOR) && !carta.naturezaDescricao.includes(Constantes.TIPO_ENERGIA)
        }
    }

    const contarCartas = () => {
        if (tipo === Constantes.TIPO_TREINADOR) {
            return cardsDeck.filter((x) => x.naturezaDescricao.includes(Constantes.TIPO_TREINADOR)).length
        } else if (tipo === Constantes.TIPO_ENERGIA) {
            return cardsDeck.filter((x) => x.naturezaDescricao.includes(Constantes.TIPO_ENERGIA)).length
        } else {
            return cardsDeck.filter((x) => !x.naturezaDescricao.includes(Constantes.TIPO_TREINADOR) && !x.naturezaDescricao.includes(Constantes.TIPO_ENERGIA)).length
        }
    }

    const openInPopup = (carta) => {
        setOpenPopup(true)
        setCartaInPopup(carta)
    }

    const atualizarCarta = (carta, is, tipo) => {
        setCardsDeck(cardsDeck.map((card) => {
            if(card.cartaTO.id === carta.cartaTO.id){
                if (tipo === Constantes.TIPO_LISTA_COLECAO) {
                    return {...carta, ehColecao: !carta.ehColecao}
                } if (tipo === Constantes.TIPO_LISTA_DESEJO) {
                    return {...carta, ehDesejo: !carta.ehDesejo}
                } if (tipo === Constantes.TIPO_LISTA_TROCA) {
                    return {...carta, ehTroca: !carta.ehTroca}
                }

            }else{
                return card
            }
        }))
    }

    const renderizarCartas = () => {
        let cardsEscolhidos = []
        return (
            cardsDeck.map((carta, key) => {
                let id = carta.cartaTO.id
                if (verificarTipoPokemon(carta) && !cardsEscolhidos.includes(id)) {
                    cardsEscolhidos = [id].concat(cardsEscolhidos)
                    return (
                        <Box key={key} sx={{ paddingInline: '0.5%' }}>
                            <Box onClick={() => openInPopup(carta)} sx={{cursor: 'pointer'}}>
                                <img className="main-card" width='130' data-src={carta.cartaTO.imgUrl} src={carta.cartaTO.imgUrl} />
                            </Box>
                            <Box sx={{
                                display: 'flex', justifyContent: 'center', '& .MuiSvgIcon-root': {
                                    fill: Cores.COR_PRINCIPAL,
                                    fontSize: '25px',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        fill: '#F06292',
                                    }
                                }
                            }}>
                                <IconButton onClick={() => adicionarCarta(carta)}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                                <Typography sx={{ paddingInline: '10%', transform: 'translate(0px, 5px)' }} variant="h6">
                                    {cardsDeck.filter((x) => x.cartaTO.id === carta.cartaTO.id).length}
                                </Typography>
                                <IconButton onClick={(e) => removerCarta(e, key)}>
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )
                }
            })
        )
    }


    return (
        <Box >
            <Divider sx={{ paddingTop: '1%', paddingBottom: '1%' }}> <Typography variant="h6" color={Cores.COR_PRINCIPAL}> {titulo + " (" + contarCartas() + ")"} </Typography></ Divider>

            <Box sx={{
                display: 'flex', overflowY: 'hidden', overflowX: 'scroll', height: '250px',
                "::-webkit-scrollbar": {
                    width: "3px",
                    height: '8px'
                },
                "::-webkit-scrollbar-track": {
                    backgroundColor: '#fff5f5'
                },
                "::-webkit-scrollbar-thumb": {
                    background: Cores.COR_PRINCIPAL,
                    borderRadius: "15px",
                    height: "1px"
                },
                "::-webkit-scrollbar-thumb:hover": {
                    background: Cores.COR_PRINCIPAL,
                    maxHeight: "1px",
                },
            }}>
                {renderizarCartas()}
                <Box sx={{
                    position: 'relative',
                    width: '130px',
                    backgroundColor: '#dbdbdb',
                    borderRadius: '5px',
                    height: '180px',
                    '& .MuiSvgIcon-root': {
                        fill: 'white',
                        fontSize: '70px',
                    }
                }}>
                    <AddCircleOutlineIcon
                        sx={{ transform: 'translateY(80%)', }}
                    />
                </Box>
            </Box>

            <Popup
                maxWidth='sm'
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title={cartaInPopup.cartaTO.nome}
            >
                <VisualizarCarta
                    carta={cartaInPopup}
                    isLoggedIn={isLoggedIn}
                    isMobile={width < Constantes.MOBILE_SCREEN_SIZE}
                    setCarta={setCartaInPopup}
                    userDetails={userDetails}
                    atualizarCarta={atualizarCarta}
                />
            </Popup>
        </Box>
    )
}