import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import ClienteService from "../../../api/services/ClienteService";
import ModalConfirmacao from '../../../components/ModalConfirmacao';
import { Form, useForm } from "../../../components/UseForm";
import Controls from "../../../components/controls/Controls";
import * as Cores from "../../../util/Cores";
import * as Mascaras from "../../../util/Mascaras";
import * as Mensagens from "../../../util/Mensagens";

const initialValues = {
    id: 0,
    estado: 0,
    cep: '',
    cidade: '',
    rua: '',
    complemento: '',
    numero: '',
    bairro: '',
    principal: false,
}

export default function EnderecoForm(props) {
    const { notificar, enderecoAlterar, setEnderecoAlterar, userDetails, excluirEndereco, consultarEnderecos } = props

    const [exibirModal, setExibirModal] = useState(false)

    const limparDados = () => {
        resetForm()
        setEnderecoAlterar(null)
    }

    const confirmarModal = () => {
        excluirEndereco(values.id)
        setExibirModal(false)
    }

    const validate = (fieldValues = values) => {
        let temp = { ...erros }
        if ('cidade' in fieldValues) {
            temp.cidade = fieldValues.cidade !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
        }
        if ('rua' in fieldValues) {
            temp.rua = fieldValues.rua !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
        }
        if ('bairro' in fieldValues) {
            temp.bairro = fieldValues.bairro !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
        }
        if ('numero' in fieldValues) {
            temp.numero = fieldValues.numero !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
        }

        if ('estado' in fieldValues) {
            temp.estado = fieldValues.estado !== 0 ? '' : Mensagens.CAMPO_OBRIGATORIO
        }

        if ('cep' in fieldValues) {
            temp.cep = fieldValues.cep !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
        }


        setErros({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const handleCheckbox = (e) => {
        console.log(e.target.value)
        setValues({...values, principal: e.target.value})
    }

    const {
        values,
        setValues,
        handleInputChange,
        erros,
        resetForm,
        setErros
    } = useForm(initialValues, true, validate)

    useEffect(() => {
        buscarEndereco()
    }, [])

    useEffect(() => {
        if (enderecoAlterar !== null) {
            setValues(enderecoAlterar)
        } else {
            setValues(initialValues)
        }
    }, [enderecoAlterar])

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (validate()) {
            let endereco = {
                id: values.id, cep: Mascaras.tam(Mascaras.digitos(values.cep), 8), cidade: values.cidade, estado: values.estado, rua: values.rua, complemento: values.complemento,
                complemento: values.complemento, numero: values.numero, bairro: values.bairro, principal: values.principal, apelido: values.apelido,
                clienteId: userDetails.id
            }
            salvarEndereco(endereco)
        }
    }
    async function buscarEndereco() {
        ClienteService.consultarEnderecosCliente(notificar, userDetails.id)
    }
    async function salvarEndereco(endereco) {
        ClienteService.salvarEnderecoCliente(notificar, endereco).then((response) => {
            if (!response.erro) {
                setValues({ ...values, id: response.codigo })
                consultarEnderecos()
            }
        })
    }

    function abrirExcluirEndereco() {
        setExibirModal(true)
    }

    return (
        <Box sx={{ paddingLeft: '0px' }}>
            <Form onSubmit={handleSubmit}>
                <Box sx={{ textAlign: 'left', marginTop: '16px', marginBottom: '16px' }}>
                    <Typography variant='h5' color={Cores.COR_PRINCIPAL} fontWeight={700}>Endereço {values.id !== 0 && values.apelido}</Typography>
                </Box>
                <Grid container spacing={3}  >
                    <Grid item md={2} xs={6}>
                        <Controls.Dados.Cep
                            onChange={handleInputChange}
                            value={values.cep}
                            error={erros.cep}
                        />
                    </Grid>
                    <Grid item md={1} xs={6}>
                        <Controls.SelectEstado
                            value={values.estado}
                            error={erros.estado}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <Controls.Input
                            name='cidade'
                            label='Cidade'
                            onChange={handleInputChange}
                            value={values.cidade}
                            error={erros.cidade}
                        />
                    </Grid>
                    <Grid item md={5} xs={6}>
                        <Controls.Input
                            name='rua'
                            label='Rua'
                            onChange={handleInputChange}
                            value={values.rua}
                            error={erros.rua}
                        />
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Controls.Input
                            name='numero'
                            label='Número'
                            onChange={handleInputChange}
                            value={values.numero}
                            error={erros.numero}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <Controls.Input
                            name='complemento'
                            label='Complemento'
                            onChange={handleInputChange}
                            value={values.complemento}
                            error={erros.complemento}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Controls.Input
                            name='bairro'
                            label='Bairro'
                            onChange={handleInputChange}
                            value={Mascaras.tam(values.bairro, 32)}
                            error={erros.bairro}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Controls.Input
                            name='apelido'
                            label='Apelido (ex: casa)'
                            onChange={handleInputChange}
                            value={Mascaras.tam(values.apelido, 32)}
                            error={erros.apelido}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Controls.Checkbox
                            name='endereco'
                            label='Endereço principal?'
                            onChange={(e) => {handleCheckbox(e)}}
                            value={values.endereco}
                            error={erros.endereco}
                        />
                    </Grid>

                    <Grid item md={12} xs={12}
                        sx={{ display: 'flex' }}>
                        <Controls.Button
                            text='Enviar'
                            type='submit'
                        />
                        <Controls.Button
                            sx={{ marginLeft: '16px', background: 'white' }}
                            text='Limpar'
                            variant='outlined'
                            onClick={() => limparDados() }
                            color='secondary'
                        />
                        <Controls.Button
                            sx={{ marginLeft: '16px' }}
                            text='Excluir'
                            variant='contained'
                            onClick={() => abrirExcluirEndereco()}
                            color='error'
                            disabled={values.id === 0}
                        />
                    </Grid>
                </Grid>
            </Form>
            <ModalConfirmacao
                exibirModal={exibirModal}
                setExibirModal={setExibirModal}
                handleConfirmar={confirmarModal}
                texto={'Deseja excluir o endereço ' + values.apelido + '?'}
            />
        </Box>
    )
}