import { Box, IconButton, Typography } from "@mui/material";
import { useState, useEffect } from 'react'
import ClienteService from "../../../api/services/ClienteService";
import CardEndereco from "./CardEndereco";
import EnderecoForm from "./EnderecoForm";
import * as icones from '../../../util/icones'
import * as Constantes from "../../../util/constantes";
import * as Utils from "../../../util/Utils";

export default function Endereco(props) {
    const { userDetails, notificar, alterarLoad } = props
    const [listaEnderecos, setListaEnderecos] = useState([])
    const [enderecoAlterar, setEnderecoAlterar] = useState(null)
    const [adicionar, setAdicionar] = useState(false)
    const { height, width } = Utils.useWindowDimensions();
    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE

    const selecionarEndereco = (endereco) => {
        setEnderecoAlterar(endereco)
    }

    const novoEndereco = () => {
        setAdicionar(true)
        setEnderecoAlterar(null)
    }

    async function excluirEndereco(id) {
        ClienteService.deletarEndereco(notificar, id).then((response) => {
            setListaEnderecos(listaEnderecos.filter((x) => x.id !== id))
            setEnderecoAlterar(null)
        })
    }

    useEffect(() => {
        consultarEnderecos()
    }, [])

    async function consultarEnderecos() {
        if (userDetails.id !== undefined) {
            ClienteService.consultarEnderecosCliente(alterarLoad, notificar, userDetails.id).then((response) => {
                setListaEnderecos(response)
            })
        }
    }

    return (
        <Box sx={{marginTop: '16px'}}>
            <Box sx={{ display: isMobile ? '-webkit-box' : '-webkit-box', overflowY: 'hidden', overflowX: 'scroll',
                ...Utils.barra() }}>
                {listaEnderecos !== undefined && listaEnderecos.map((item, index) => {
                    return (
                        <CardEndereco
                            selecionado={enderecoAlterar === item}
                            selecionarEndereco={selecionarEndereco}
                            key={index}
                            endereco={item}
                            isMobile={isMobile}
                        />
                    )
                })}
                <Box sx={{marginInline: '8px',display: 'flex', background: 'white', borderRadius: '100px', justifyContent: 'center', height: '90%'}}>
                    <IconButton onClick={() => { novoEndereco() }}>
                        {icones.adicionar}
                    </IconButton>
                </Box>
            </Box>
            <EnderecoForm
                enderecoAlterar={enderecoAlterar}
                alterarLoad={alterarLoad}
                notificar={notificar}
                userDetails={userDetails}
                excluirEndereco={excluirEndereco}
                consultarEnderecos={consultarEnderecos}
                isMobile={isMobile}
                setEnderecoAlterar={setEnderecoAlterar}
            />
        </Box>
    )

}