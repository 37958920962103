import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import { Form } from '../../components/UseForm';
import Controls from "../../components/controls/Controls";
import * as Cores from "../../util/Cores";
import * as style from './styles';

export default function Campos(props) {

    const { values, erros, handleInputChange, mostraSenha, setMostraSenha, isNoite, handleSubmit, setOpenPopupCadastro, setOpenPopupEsqueci } = props

    const handleClickShowPassword = (e) => {
        e.preventDefault()
        if (mostraSenha === 'password') {
            setMostraSenha('text')
        } else {
            setMostraSenha('password')
        }
    }

    return (
        <Form onSubmit={handleSubmit} >
            <Grid container spacing={3} sx={isNoite && style.estiloNoite}>
                <Grid item md={12} xs={12}>
                    <Controls.Input
                        name='username'
                        label='Username'
                        value={values.username}
                        error={erros.username}
                        onChange={handleInputChange}
                        sx={{ background: isNoite && 'none' }}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Controls.Input
                        name='senha'
                        label='Senha'
                        value={values.senha}
                        error={erros.senha}
                        onChange={handleInputChange}
                        type={mostraSenha}
                        sx={{ background: isNoite && 'none' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(e) => handleClickShowPassword(e)}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fill: isNoite ? 'white' : 'black',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                }
                                            }
                                        }}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Controls.Button
                        type="submit"
                        text='Log in'
                        sx={isNoite && { color: 'black', backgroundColor: 'white' }}
                    />
                    <Controls.Button
                        style={{ marginLeft: '10px' }}
                        text='Criar conta'
                        onClick={() => { setOpenPopupCadastro(true) }}
                        sx={isNoite && { color: 'black', backgroundColor: 'white' }}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography onClick={() => setOpenPopupEsqueci(true)} fontWeight={600} sx={{ cursor: 'pointer', color: isNoite ? 'white' : Cores.COR_PRINCIPAL }} component='span'>
                        Esqueci minha senha
                    </Typography>
                </Grid>
            </Grid >
        </Form>
    )
}