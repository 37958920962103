import axios from "axios";
import * as Utils from "../util/Utils"

const GERAR_QRCODE_PIX = 'pagamento/pix'
const GERAR_PREFERECIA = 'pagamento/preferencia'
const PROCESSAR_PAGAMENTO = "/pagamento/processar"
const ASSINATURA_CONSULTAR = '/pagamento/consultar/assinatura'
const ASSINATURA_CANCELAR = '/pagamento/cancelar/assinatura'
const ASSINATURA_GERAR = '/pagamento/gerar/assinatura'

const client = axios.create({
    baseURL: process.env.REACT_APP_AMBIENTE
});

export async function postGerarPreferencia(preferencia) {
    return client.post(GERAR_PREFERECIA, preferencia)
}

export async function postGerarQrCodePix(dados){
    return client.post(GERAR_QRCODE_PIX, dados)
}

export async function postProcessarPagamento(request){
    return client.post(PROCESSAR_PAGAMENTO, request)
}

export async function postConsultarAssinatura(request){
    return client.post(ASSINATURA_CONSULTAR, request, Utils.getAuth())
}

export async function postGerarAssinatura(request){
    return client.post(ASSINATURA_GERAR, request, Utils.getAuth())
}

export async function postCancelarAssinatura(request){
    return client.post(ASSINATURA_CANCELAR, request, Utils.getAuth())
}