import * as Mascaras from "../../../../util/Mascaras";
import Controls from "../../Controls";


export default function DataExpiracao(props) {
    const {id='', onChange, value, error} = props

    return (
        <Controls.Input
            name='dataExpiracao'
            label='Data de Expiração (mm/aaaa)'
            onChange={onChange}
            value={Mascaras.dataExpiracao(value)}
            error={error}
            id={id}
        />
    )
}