import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";


export default function Load(props) {

    const { exibirLoad, mensagemLoad } = props

    return (
        <div>
        {exibirLoad ? 
           <Backdrop sx={{ color: "#fff" }} open={exibirLoad}>
           <Box sx={{ display: "grid" }}>
               <Typography variant='h5'>{mensagemLoad}</Typography>
               <Box sx={{textAlign: 'center'}}>
                   <CircularProgress color="inherit" />
               </Box>
           </Box>
       </Backdrop>
         : null}
        </div>
    )
}
