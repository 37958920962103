import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Fab as MuiFab } from '@mui/material';
import * as Cores from "../../../util/Cores";

export default function FabExcluirDeck(props) {

    const { handleDeletarDeck, deck } = props

    return (
        <Box sx={{
            zIndex: '10',
            '& .MuiFab-root': {
                background: 'white'
            },
            '& .MuiSvgIcon-root': {
                fill: Cores.COR_PRINCIPAL,
            }
        }}>
            <MuiFab size={'medium'} aria-label="consultar" onClick={(e) => handleDeletarDeck(e, deck.deckId)}>
                <DeleteOutlineIcon />
            </MuiFab>
        </Box>
    )
}