


export const estiloNoite = {
    '& .MuiFormLabel-root': {
        color: 'white',
        '&.Mui-focused': {
            color: 'white',
        }
    },

    '& .MuiInputBase-root':{
        color: 'white',
        borderColor: 'white',
    },
    
    '& .MuiOutlinedInput-root': {
        color: 'white',
        'fieldset': {
            borderColor: '#aaa',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
          },
    },
    '& .MuiButton-root': {
        color: 'secondary'
    }

}