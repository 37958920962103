import { useForm } from "../../../components/UseForm";
import * as Mensagens from "../../../util/Mensagens";
import { useState } from 'react';

const initialValues = {
    roles: '',
    username: '',
    id: 0,
    email: '',
    token: ''
}


export const useDados = () => {
    const [mostraSenha, setMostraSenha] = useState('password')

    const validate = (fieldValues = values) => {
        let temp = { ...erros }

        if ('username' in fieldValues) {
            temp.username = fieldValues.username !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
        }

        if ('senha' in fieldValues) {
            temp.senha = fieldValues.senha !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
        }

        setErros({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const limparDados = () => {
        setValues(initialValues)
        setErros({})
    }

    const {
        values,
        setValues,
        handleInputChange,
        erros,
        setErros
    } = useForm(initialValues, true, validate)

    return {values, handleInputChange, erros, validate, limparDados, mostraSenha, setMostraSenha}
}