import { Box, Typography } from "@mui/material";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useCommon } from '../../components/hooks/useCommon';
import ClienteService from "../../api/services/ClienteService";
import * as mensagens from "../../util/Mensagens";
import * as Utils from '../../util/Utils';
import * as Cores from '../../util/Cores';
import * as Constantes from "../../util/constantes";
import CadastroForm from "./CadastroForm";
import Campos from './Campos';
import EsqueciSenhaForm from "./EsqueciSenhaForm";
import { useDados } from './hooks/useDados';
import Vagalumes from "../../components/animation/Vagalumes";

export default function Login(props) {
    const { setIsLoggedIn, setUserDetails } = props
    const navigate = useNavigate();
    const { values, handleInputChange, erros, validate, limparDados, mostraSenha, setMostraSenha } = useDados()
    const { alterarLoad, notificar, Common, alterarMensagemLoad } = useCommon()
    const isNoite = Utils.verificarNoite()
    const [openPopupCadastro, setOpenPopupCadastro] = useState(false)
    const [openPopupEsqueci, setOpenPopupEsqueci] = useState(false)
    const { height, width } = Utils.useWindowDimensions();
    const [mensagem, setMensagem] = useState("")
    const [tipoMensagem, setTipoMensagem] = useState('')
    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE

    const handleSubmit = async e => {
        e.preventDefault()
        if (validate()) {
            navigator.geolocation.getCurrentPosition((position) => {
                realizarLogin({
                    username: values.username,
                    senha: values.senha,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                })
            },
                function (error) {
                    if (error.code == error.PERMISSION_DENIED)
                        realizarLogin({
                            username: values.username,
                            senha: values.senha,
                            latitude: 0,
                            longitude: 0
                        })
                }
            )
        }
    }

    async function realizarLogin(credenciais) {
        alterarMensagemLoad(mensagens.FAZENDO_LOGIN)
        alterarLoad(true)
        try {
            await ClienteService.login(credenciais).then(response => {
                console.log(response.data)
                if(response.data === null || response.data === ''){
                    setMensagem("Conta deve ser ativada. Link de ativação enviado.")
                    setTipoMensagem("error")
                }else if (response.data.id > 0) {
                    setUserDetails({
                        roles: response.data.roles,
                        username: response.data.username,
                        id: response.data.id,
                        email: response.data.email,
                        token: response.data.token
                    })
                    setIsLoggedIn(true)
                    navigate("/")
                } else if (response.data.id === 0) {
                    notificar(mensagens.ERRO_LOGIN_INVALIDO, "error")
                    setIsLoggedIn(false)
                } else {
                    notificar(response.data.username, "error")
                    setIsLoggedIn(false)
                }
            })
        } catch (e) {
            notificar("Erro ao realizar login. Tente novamente mais tarde.", "error")
            console.log(e)
        } finally {
            alterarLoad(false)
        }
    }


    return (
        <Box>
            <Common />
            <Box sx={{
                borderRadius: '25px', backdropFilter: 'blur(15px)' + (isNoite ? ' brightness(130%)' : ' brightness(110%)'),
                marginTop: isMobile ? '30%' : '8%',
                marginInline: isMobile ? '3%' : '30%',
                padding: isMobile ? '8%' : '5%'
            }}>
                <Campos
                    handleSubmit={handleSubmit}
                    isNoite={isNoite}
                    mostraSenha={mostraSenha}
                    setMostraSenha={setMostraSenha}
                    values={values}
                    erros={erros}
                    handleInputChange={handleInputChange}
                    setOpenPopupCadastro={setOpenPopupCadastro}
                    setOpenPopupEsqueci={setOpenPopupEsqueci}
                />
                {mensagem !== "" &&
                    <Box sx={{ width: '100%', background: tipoMensagem === 'error' ? Cores.COR_ERRO : Cores.COR_SUCESSO_FUNDO, borderRadius: '25px', padding: '16px' }}>
                        <Typography color={tipoMensagem === 'error' && 'white'}>{mensagem}</Typography>
                    </Box>
                }
            </Box>
            <CadastroForm
                openPopupCadastro={openPopupCadastro}
                setOpenPopupCadastro={setOpenPopupCadastro}
                setMensagem={setMensagem}
                setTipoMensagem={setTipoMensagem}
            />

            <EsqueciSenhaForm
                setOpenPopupEsqueci={setOpenPopupEsqueci}
                openPopupEsqueci={openPopupEsqueci}
                notificar={notificar}
                alterarLoad={alterarLoad}
                setMensagem={setMensagem}
                setTipoMensagem={setTipoMensagem}
            />
            {isNoite && <Vagalumes isMobile={isMobile} />}


        </Box>
    )
}