import { Box, Grid, Typography } from "@mui/material";
import { useState } from 'react';
import CartasService from "../../../api/services/CartasService";
import Notificacao from "../../../components/Notificacao";
import { Form, useForm } from "../../../components/UseForm";
import Controls from "../../../components/controls/Controls";
import * as Cores from "../../../util/Cores";

const initialValues = {
    nome: '',
    ano: '',
    jogo: '1',
    tamanho: 0
}
export default function AdminEdicoes(props) {
    const { listaJogo } = props
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    })
    const validate = (fieldValues = values) => {

    }

    const {
        values,
        setValues,
        handleInputChange,
        handleInputChangeSwitch,
        erros,
        resetForm,
        setErros
    } = useForm(initialValues, true, validate)

    const salvarEdicao = () => {
        let response = CartasService.salvarEdicao({
            ano: values.ano,
            nome: values.nome,
            edicao_id: 0,
            tamanho: values.tamanho,
            jogo: Number(values.jogo)
        })
        if (!response.erro) {
            if (!response.erro) {
                setValues({
                    ...values,
                    nome: '',
                    ano: ''
                })
                setNotify({
                    isOpen: true,
                    message: "Edição inserida com sucesso!",
                    type: "success",
                })
            }

        } else {
            setNotify({
                isOpen: true,
                message: "Erro ao salvar edição.",
                type: "error",
            })
        }
    }

    return (
        <Form>
            <Box sx={{ textAlign: 'left', marginTop: '16px' }}>
                <Notificacao notify={notify} setNotify={setNotify} />
                <Typography variant='h5' color={Cores.COR_PRINCIPAL} fontWeight={700}>Edições</Typography>
                <Box style={{ borderBottom: '2px solid ' + Cores.COR_PRINCIPAL, width: '100%', marginBottom: '20px' }} />
                <Grid container spacing={3}>
                    <Grid item md={3} sm={6}>
                        <Controls.Input
                            name='nome'
                            label='Nome'
                            value={values.nome}
                            onChange={handleInputChange}
                            error={erros.nome}
                        />
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Controls.Input
                            name='ano'
                            label='Ano/mês'
                            value={values.ano}
                            onChange={handleInputChange}
                            error={erros.ano}
                        />
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Controls.Input
                            name='tamanho'
                            label='Tamanho'
                            value={values.tamanho}
                            onChange={handleInputChange}
                            error={erros.tamanho}
                        />
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Controls.Select
                            name='jogo'
                            label='Jogo'
                            value={values.jogo}
                            onChange={handleInputChange}
                            options={listaJogo}
                            error={erros.jogo}
                        />
                    </Grid>
                    <Grid item md={12} sm={6}>
                        <Controls.Button
                            onClick={() => salvarEdicao()}
                            text='Adicionar'
                        />
                        <Controls.Button 
                            sx={{ marginLeft: '16px', background: 'white' }}
                            text='Limpar'
                            variant='outlined'
                            onClick={() => resetForm()}
                            color='secondary'
                        />
                    </Grid>
                </Grid>
            </Box>
        </Form>
    )
}