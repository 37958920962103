import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import Controls from "./controls/Controls"
import CloseIcon from '@mui/icons-material/Close'
import * as Constantes from "../util/constantes"
import * as Cores from "../util/Cores";

export default function Popup(props) {
    const { title, children, openPopup, setOpenPopup, maxWidth = 'md', width ='40%', background = Constantes.COR_SECUNDARIA_FUNDO } = props

    return (
        <Dialog
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '25px',
                    width: width
                }
            }}
            open={openPopup}
            fullWidth={true}
            onClose={() => setOpenPopup(false)}
            maxWidth={maxWidth} >
            <DialogTitle>
                <div style={{ display: 'flex' }}>
                    <Typography variant='h6' component='span' style={{ flexGrow: '1', fontWeight: 700 }} color='primary'> {title} </Typography>
                    <Controls.ActionButton
                        color='secondary'
                        onClick={() => { setOpenPopup(false) }}>
                        <CloseIcon />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent
                sx={{
                    background: background, 
                    paddingTop: "20px",
                    "::-webkit-scrollbar": {
                        width: "6px",
                    },
                    "::-webkit-scrollbar-track": {
                        backgroundColor: '#fff5f5'
                    },
                    "::-webkit-scrollbar-thumb": {
                        background: Cores.COR_PRINCIPAL,
                        borderRadius: "15px",
                        height: "2px"
                    },
                    "::-webkit-scrollbar-thumb:hover": {
                        background: Cores.COR_PRINCIPAL,
                        maxHeight: "10px"
                    },
                }}
            >
                {children}
            </DialogContent>
        </Dialog>
    )
}


