import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'white'
    },

});


const ListaCartasTexto = (props) => {
    const { cartas } = props

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View>
                    {cartas.map(cartas => {
                        return (
                            cartas.map(carta => {
                                return (
                                    <View style={{ marginLeft: '10px', marginTop: '12px' }}>
                                        <Text style={{ fontSize: '16px' }}>{carta.cartaTO.numero + " - " + carta.cartaTO.nome}</Text>
                                        <Text style={{ fontSize: '12px' }}>{carta.edicaoDescricao}</Text>
                                        <Text style={{ fontSize: '12px' }}>{carta.naturezaDescricao + " - " + carta.raridadeDescricao}</Text>
                                    </View>
                                )
                            })
                        )


                    })}
                </View>
            </Page>
        </Document>
    );
}



export default ListaCartasTexto


