import { Box, Typography } from "@mui/material"
import * as Constantes from "../../util/constantes"
import * as Cores from "../../util/Cores";



export default function CartaMenuIcone(props) {
    const { nome, icone, selecionarCorpo, corpo, isMobile } = props
    const handleClick = () => {
        selecionarCorpo(nome)
    }

    return (
        <Box sx={{
            paddingTop: '5px',
            width: isMobile ? '100px' : '130px',
            background: nome === corpo ? Constantes.COR_SECUNDARIA_FUNDO : 'white',
            '& .MuiSvgIcon-root': {
                fill: nome === corpo && Cores.COR_PRINCIPAL,
            },
            '&: hover': {
                cursor: 'pointer',
                background: Constantes.COR_SECUNDARIA_FUNDO
            },
        }} onClick={() => handleClick()}>
            <Box>
                {icone}
                {<Typography variant={isMobile ? 'body2' : 'body1'} color={nome === corpo && Cores.COR_PRINCIPAL}>
                    {nome}
                </Typography>}
            </Box>
            {nome === corpo &&
                <Box sx={{display: 'flex', justifyContent: 'center' }}>
                    <Box
                        sx={{ height: '4px', background: Cores.COR_PRINCIPAL, width: '50%', borderTopRightRadius: '50px', borderTopLeftRadius: '50px'}}
                    />
                </Box>}
        </Box>
    )

}