
import Cep from './Cep'
import Jogo from './Jogo'
import Nome from './Nome'
import Cpf from './Cpf'
import NumeroCartao from './pagamento/NumeroCartao'
import DataExpiracao from './pagamento/DataExpiracao'
import CodigoSeguranca from './pagamento/CodigoSeguranca'
import Titular from './pagamento/Titular'
import Parcelas from './pagamento/Parcelas'
import Bandeira from './pagamento/Bandeira'

const Dados = {
    Cep,
    Jogo, 
    Nome,
    Cpf,
    NumeroCartao,
    DataExpiracao,
    CodigoSeguranca,
    Titular,
    Parcelas,
    Bandeira
}

export default Dados
