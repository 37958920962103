import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TabContext from '@mui/lab/TabContext';
import { Box } from "@mui/material";
import { useEffect, useState } from 'react';
import { useCommon } from '../../components/hooks/useCommon';
import ClienteService from '../../api/services/ClienteService';
import * as Utils from '../../util/Utils';
import * as Constantes from '../../util/constantes';
import CartaMenuIcone from '../Cartas/CartaMenuIcone';
import GerenciarConta from './GerenciarConta';
import Admin from './admin/Admin';
import Endereco from './endereco/Endereco';
import * as icones from '../../util/icones'
import Pedido from './admin/pedido/Pedido';
import MeusPedidos from './MeusPedidos';

export default function PerfilWrapper(props) {
    const { userDetails, setUserDetails } = props
    const { notificar, alterarLoad, alterarMensagemLoad, Common } = useCommon()
    const [corpo, setCorpo] = useState(Constantes.CONTA_MENU)
    const { width } = Utils.useWindowDimensions();
    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE

    useEffect(() => {
        consultarCliente(userDetails.id)
    }, []);

    function selecionarCorpo(valor) {
        setCorpo(valor)
    }

    async function consultarCliente(codigo) {
        await ClienteService.consultarCliente(codigo).then((response) => {
        })
    }

    return (
        <Box>
            <Common />
            <Box sx={{ width: '100%' }}>
                <Box >
                    <Box sx={{ display: '-webkit-box', width: '100%', overflow: 'scroll', ...Utils.barra() }}>
                        <CartaMenuIcone
                            nome={Constantes.CONTA_MENU}
                            icone={<AccountCircleIcon />}
                            selecionarCorpo={selecionarCorpo}
                            corpo={corpo}
                            isMobile={isMobile}
                        />
                        <CartaMenuIcone
                            nome={Constantes.ENDERECO}
                            icone={icones.endereco}
                            selecionarCorpo={selecionarCorpo}
                            corpo={corpo}
                            isMobile={isMobile}
                        />
                        <CartaMenuIcone
                            nome={Constantes.PEDIDOS}
                            icone={icones.pedido}
                            selecionarCorpo={selecionarCorpo}
                            corpo={corpo}
                            isMobile={isMobile}
                        />
                        {userDetails.roles === Constantes.ROLE_ADMIN &&
                            <CartaMenuIcone
                                nome={Constantes.ADMIN_MENU}
                                icone={<AdminPanelSettingsIcon />}
                                selecionarCorpo={selecionarCorpo}
                                corpo={corpo}
                                isMobile={isMobile}
                            />
                        }
                        {userDetails.roles === Constantes.ROLE_ADMIN &&
                            <CartaMenuIcone
                                nome={Constantes.ADMIN_PEDIDOS}
                                icone={<AdminPanelSettingsIcon />}
                                selecionarCorpo={selecionarCorpo}
                                corpo={corpo}
                                isMobile={isMobile}
                            />
                        }
                    </Box>
                </Box>
                <Box sx={{ padding: '16px', background: Constantes.COR_SECUNDARIA_FUNDO }}>
                    {corpo === Constantes.CONTA_MENU &&
                        <GerenciarConta userDetails={userDetails} setUserDetails={setUserDetails} />
                    }
                    {corpo === Constantes.ADMIN_MENU &&
                        < Admin
                            corpo={corpo}
                            userDetails={userDetails}
                            notificar={notificar}
                            alterarLoad={alterarLoad}
                            alterarMensagemLoad={alterarMensagemLoad}
                        />
                    }
                    {corpo === Constantes.ENDERECO &&
                        <Endereco
                            alterarLoad={alterarLoad}
                            notificar={notificar}
                            userDetails={userDetails}
                            isMobile={isMobile}
                        />
                    }
                    {corpo === Constantes.ADMIN_PEDIDOS &&
                        <Pedido
                            alterarLoad={alterarLoad}
                            notificar={notificar}
                        />
                    }
                    {corpo === Constantes.PEDIDOS &&
                        <MeusPedidos
                            alterarLoad={alterarLoad}
                            notificar={notificar}
                            userDetails={userDetails}
                            isMobile={isMobile}
                        />
                    }
                </Box>
            </Box>
        </Box>
    )
}