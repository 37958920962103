import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import vagalumeImg from '../../img/vagalume.png';
import * as Utils from "../../util/Utils";
import useInterval from "../hooks/useInterval";

const DURATION = 10
const posicoes = [-20, -15, -12, -10, -8, -5, 0, 0, 5, 8, 10, 12, 15, 20]
const frames = [1, 2, 3, 4, 5, 6, 7]

const vagalume = (inicialX, intervalX, inicialY, intervalY, delay, img) => {
    return <motion.div
        className="box"
        initial={{ x: inicialX, y: inicialY, scale: Utils.randomNumber(3, 15, 1) / 100, opacity: 0 }}
        animate={{
            x: intervalX,
            y: intervalY,
            opacity: [0, 1, 1, 1, 1, 0, 0]
        }}
        transition={{ delay: delay, duration: DURATION, repeat: 1 }}
    >
        <img src={img} alt="tree" width='20%' style={{ filter: 'blur(10px)', borderRadius: '25px', background: 'rgba(255, 255, 255, .4)', position: 'absolute', left: 0, zIndex: 99, top: -20 }} />
    </motion.div>
}

const randomInterval = (inicial) => {
    return frames.map(() => posicoes[Utils.randomNumber(0, 7, 1)] + inicial)
}

const criarNovoVagalume = (width, cont, isMobile) => {
    let ix = Utils.randomNumber(isMobile ? -150 : -250, isMobile ? width - 25 : width - 50, 1)
    ix = ix > width - 100 ? width - 100 : ix
    let iy = Utils.randomNumber(isMobile ? 40 : 60, isMobile ? 230 : 350, 1)
    let delay = Utils.randomNumber(0, 10, 1)
    let intervalX = randomInterval(ix)
    let intervalY = randomInterval(iy)
    return {
        id: cont,
        duration: (DURATION + delay).toFixed(2),
        jsx: vagalume(ix, intervalX, iy, intervalY, delay, vagalumeImg, cont)
    }
}

export default function Vagalumes(props) {
    const [cont, setCont] = useState(0)
    const { isMobile } = props
    const { height, width } = Utils.useWindowDimensions();
    const [vagalumes, setVagalumes] = useState([{ id: 0, jsx: <div></div> }])
    const numeroVagalumes = isMobile ? 6 : 15

    useEffect(() => {
        let vagalumesAux = []
        let temp = cont
        for (let i = 0; i < numeroVagalumes; i++) {
            temp++
            vagalumesAux.push(criarNovoVagalume(width, temp, isMobile))
        }
        setCont(temp)
        setVagalumes(vagalumesAux)
    }, [])

    useInterval(() => {

        let vagalumesAux = []
        let temp = cont
        for (let i = 0; i < vagalumes.length; i++) {
            let duracao = vagalumes[i].duration - 0.1
            if (duracao < 0.1) {
                temp++
                vagalumesAux.push(criarNovoVagalume(width, temp, isMobile))
            } else {
                vagalumesAux.push({ ...vagalumes[i], duration: duracao.toFixed(2) })
            }
        }
        setCont(temp)
        setVagalumes(vagalumesAux)
    }, 100)

    return (
        <Box sx={{ pointerEvents: 'none' }}>
            {vagalumes.map(vagalume => {
                return (
                    <Box key={vagalume.id}>
                        {vagalume.jsx}
                    </Box>
                )
            })}
        </Box>
    )
}