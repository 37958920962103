import * as Mascaras from "../../../util/Mascaras";
import Controls from "../Controls";


export default function Nome(props) {
    const {onChange, value, error, name='nome', label='Nome'} = props

    return (
        <Controls.Input
            name={name}
            label={label}
            onChange={onChange}
            value={Mascaras.tamTexto(value, 64)}
            error={error}
        />
        
    )
}