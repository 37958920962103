import { Box, Typography } from '@mui/material';
import * as Constantes from '../../util/constantes';
import ChipEstoque from './ChipEstoque';

export default function VisualizarCartaLoja(props) {
    const { carta, width, adicionarCompra,isMobile } = props
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <img className="main-card" height='250px' data-src={carta.cartaTO.imgUrl} src={carta.cartaTO.imgUrl} />
            </Box>
            <Box sx={{ display: width < Constantes.MOBILE_SCREEN_SIZE ? 'contents' : 'flex' }}>
                <Box sx={{ marginLeft: '2%', width: '100%', marginTop: '6%' }}>
                    <Typography sx={{ textAlign: 'center' }} fontWeight='550' color='green'>{' R$' + (carta.cartaTO.valor+ '').replace('.', ',')}</Typography>
                    <Typography> <b>Raridade</b>: {carta.raridadeDescricao} </Typography>
                    <Typography> <b>Edição:</b> {carta.edicaoDescricao}</Typography>
                    <Typography> <b>Número:</b> {carta.cartaTO.numero}</Typography>
                    <Typography> <b>Natureza:</b> {carta.naturezaDescricao}</Typography>
                    <Typography> <b>Ano:</b> {carta.ano}</Typography>
                    <Typography> <b>Nível:</b> {carta.nivelDescricao}</Typography>
                </Box>
            </Box>
            <Box sx={{ width: isMobile ? '45%' : '25%'}}>
                <ChipEstoque
                    adicionarCompra={adicionarCompra}
                    estoque={carta.cartaTO.estoque}
                />
            </Box>
        </Box>
    )
}