import GroupsIcon from '@mui/icons-material/Groups';
import SchoolIcon from '@mui/icons-material/School';
import StoreIcon from '@mui/icons-material/Store';
import StorefrontIcon from '@mui/icons-material/Storefront';
import * as Constantes from "./constantes";
import LoginIcon from '@mui/icons-material/Login';
import PlaceIcon from '@mui/icons-material/Place';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import * as Cores from "./Cores";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export const colecoesSecundario = <StoreIcon sx={{ fill: Cores.COR_PRINCIPAL_ESCURO }} />
export const tutorialSecundario = <SchoolIcon sx={{ fill: Cores.COR_PRINCIPAL_ESCURO }} />
export const sobreNosSecundario = <GroupsIcon sx={{ fill: Cores.COR_PRINCIPAL_ESCURO }} />
export const lojaSecundario = <StorefrontIcon sx={{ fill: Cores.COR_PRINCIPAL_ESCURO }} />
export const loginSecundario = <LoginIcon sx={{ fill: Cores.COR_PRINCIPAL_ESCURO, transform: 'scale(1.5)', marginRight: '30px' }} />

export const conquistaIcone = (id, cor) => {
    if (id === Constantes.CONQUISTA_VALOR_COLECAO) return <AttachMoneyIcon sx={{ fill: cor }} />
    if (id === Constantes.CONQUISTA_EDICOES_COMPLETAS) return <MenuBookIcon sx={{ fill: cor }} />
    if (id === Constantes.CONQUISTA_NUMERO_CARTAS) return <CatchingPokemonIcon sx={{ fill: cor }} />
}

export const pedido = <ListAltIcon />
export const endereco = <PlaceIcon />
export const enderecoCasaPredio = (selecionado) => {
    return (
        <HomeWorkIcon sx={{ fill: selecionado && Cores.COR_PRINCIPAL }} />
    )
}
export const adicionar = <AddIcon fontSize='large' sx={{ fill: Cores.COR_PRINCIPAL }} />