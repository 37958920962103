import * as Mascaras from "../../../../util/Mascaras";
import Controls from "../../Controls";


export default function Titular(props) {
    const {id='', onChange, value, error} = props

    return (
        <Controls.Input
            name='titular'
            label='Titular do Cartão'
            onChange={onChange}
            value={Mascaras.tam(value, 64)}
            error={error}
            id={id}
        />
    )
}