import axios from "axios";
import * as Utils from "../util/Utils"

const CONSULTAR_DESTAQUES = 'estoque/consultar/destaques'
const SALVAR_ESTOQUE = 'estoque/salvar'
const BUSCAR_CARTA_ESTOQUE = 'estoque/consultar/carta'

const client = axios.create({
    baseURL: process.env.REACT_APP_AMBIENTE
});

export async function getDestaques(){
    return client.get(CONSULTAR_DESTAQUES)
}

export async function postSalvarEstoque(estoque){
    return client.post(SALVAR_ESTOQUE, estoque, Utils.getAuth())
}

export async function getBuscarCartaEstoque(id){
    return client.get(BUSCAR_CARTA_ESTOQUE + '?id=' + id)
}


