import {
    getConsultarPedido,
    getConsultarPedidosCliente,
    postSalvarPedido,
    getCalcularFrete,
    postCancelarPedido,
    postAlterarPedido
} from '../PedidoApi'


async function cancelarPedido(id, notificar, alterarLoad) {
    alterarLoad(true)
    try {
        return await postCancelarPedido(id).then(response => {
            notificar(response.data.mensagem, response.data.erro ? "error" : "success")
            return response.data
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao cancelar pedido. Tente novamente mais tarde.", "error")
    } finally {
        alterarLoad(false)
    }
}

async function salvarPedido(notificar, alterarLoad, pedido) {
    alterarLoad(true)
    try {
        return await postSalvarPedido(pedido).then(response => {
            if (response.data.erro) {
                notificar(response.data.mensagem, "error")
            } else {
                notificar('Pedido cadastrado com sucesso!', "success")
            }
            return response.data
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao salvar pedido.", "error")
    } finally {
        alterarLoad(false)
    }
}

async function alterarPedido(notificar, alterarLoad, pedido) {
    alterarLoad(true)
    try {
        return await postAlterarPedido(pedido).then(response => {
            if(response.data.erro){
                notificar(response.data.mensagem, "error")
            }else{
                notificar('Pedido alterado com sucesso!', "success")
            }
            return response.data
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao salvar pedido.", "error")
    } finally {
        alterarLoad(false)
    }
}

async function consultarPedido(alterarLoad, notificar, id, numero) {
    alterarLoad(true)
    try {
        return await getConsultarPedido(id, numero).then(response => {
            if (response.data !== "") {
                return response.data
            } else {
                notificar("Pedido não encontrado", "error")
                return null
            }
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao consultar pedido.", "error")
    } finally {
        alterarLoad(false)
    }
}

async function consultarPedidosCliente(alterarLoad, notificar, id) {
    alterarLoad(true)
    try {
        return await getConsultarPedidosCliente(id).then(response => {
            if (response.data.erro) {
                notificar(response.data.mensagem, "error")
            }
            return response.data
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao consultar pedidos.", "error")
    } finally {
        alterarLoad(false)
    }
}

async function calcularFrete(alterarLoad, notificar, id) {
    alterarLoad(true)
    try {
        return await getCalcularFrete(id).then(response => {
            if (response.data.erro) {
                notificar(response.data.mensagem, "error")
            }
            return response.data
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao consultar pedidos.", "error")
    } finally {
        alterarLoad(false)
    }
}

const PedidoService = {
    salvarPedido,
    cancelarPedido,
    consultarPedido,
    calcularFrete,
    consultarPedidosCliente,
    alterarPedido
}


export default PedidoService
