import { Box, Grid, Typography } from "@mui/material";
import { Form, useForm } from "../../../../components/UseForm";
import Controls from "../../../../components/controls/Controls";
import * as Cores from "../../../../util/Cores";

const initialValues = {
    username: '',
}
export default function Pedido(props) {
    const { notificar, alterarLoad } = props

    const validate = (fieldValues = values) => {

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (validate()) {

        }
    }

    const {
        values,
        handleInputChange,
        erros,
        resetForm,
        setErros
    } = useForm(initialValues, true, validate)

    return (
        <Form onSubmit={handleSubmit}>
            <Box sx={{ textAlign: 'left', marginTop: '16px' }}>
                <Typography variant='h5' color={Cores.COR_PRINCIPAL} fontWeight={700}>Pedidos Admin</Typography>
                <Box style={{ borderBottom: '2px solid ' + Cores.COR_PRINCIPAL, width: '100%', marginBottom: '20px' }} />
            </Box>
            <Grid container spacing={3}>
                <Grid item md={2} xs={2}>
                    <Controls.Input
                        name='numero'
                        label='Número'
                        onChange={handleInputChange}
                        value={values.numero}
                        error={erros.numero}
                    />
                </Grid>
                <Grid item md={2} xs={2}>
                    <Controls.Input
                        name='cpf'
                        label='CPF'
                        onChange={handleInputChange}
                        value={values.cpf}
                        error={erros.cpf}
                    />
                </Grid>
                <Grid item md={6} xs={6}
                    sx={{ display: 'flex' }}>
                    <Controls.Button
                        text='Pesquisar'
                        type='submit'
                    />
                    <Controls.Button
                        sx={{ marginLeft: '16px', background: 'white' }}
                        text='Limpar'
                        variant='outlined'
                        onClick={() => resetForm()}
                        color='secondary'
                    />
                </Grid>
            </Grid>
        </Form>
    )
}