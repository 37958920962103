import { Box, Chip } from "@mui/material";

export default function ChipEstoque(props) {
    const { adicionarCompra, estoque } = props

    const adicionarCompraLocal = (e) => {
        if (estoque > 0){
            adicionarCompra(e)
        }
    }

    return (
        <Box onClick={(e) => adicionarCompraLocal(e)} sx={{display: 'flex', justifyContent: 'left', marginLeft: '10px'}}>
            <Chip color={estoque > 0 ? 'primary' : 'secondary'} label={estoque + ' unidades'} sx={{marginBottom: '3px',marginLeft: '0px', height: '20px', cursor: 'pointer'}} />
        </Box>
    )
}