import { Box, Typography } from "@mui/material";
import RevealAnimation from "../../../util/RevealAnimation";


export default function BlocoTutorialWeb(props) {
    const {
        titulo,
        mensagem,
        imagem,
        isMobile
    } = props

    return (
        <Box>
            <Box sx={{ textAlign: 'left', padding: '2%', paddingInline: '6%', marginTop: isMobile ? '100px' : '100px' }}>
                <Box>
                    <RevealAnimation delay={0.3} isMobile={isMobile}>
                        <Typography variant={isMobile ? 'h4' : 'h2'} color='primary' textAlign='left' fontWeight={700}>
                            {titulo}
                        </Typography>
                    </RevealAnimation>
                    <RevealAnimation delay={0.3} isMobile={isMobile}>
                        <Typography variant={isMobile ? 'h6' :'h5'} sx={{ paddingTop: '5%', paddingBottom: '5%', paddingInline: '5%' }}>
                            {mensagem}
                        </Typography>
                    </RevealAnimation>
                </Box>
            </Box>
            <RevealAnimation delay={0.4} isMobile={isMobile}>
                <img src={imagem} alt="image" height={isMobile ? 'auto' : '520px'} width={isMobile ? '350px' : 'auto'} />
            </RevealAnimation>
        </Box>
    )
}