import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { IconButton, InputAdornment, TextField } from "@mui/material";

export default function InputNomeCarta(props) {

    const { name, label, value, error = null, onChange, onClickFiltro, ...other } = props

    return (
        <TextField
            variant="standard"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            sx={{background: 'white'}}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton onClick={onClickFiltro}>
                            <FilterAltOutlinedIcon fontSize="large" />
                        </IconButton>
                    </InputAdornment>
                )
            }}
            {...other}
            {...(error && { error: true, helperText: error })}
        />
    )
}