import { Box, Fab, Grid, Typography } from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useForm } from "../../../components/UseForm";
import * as GptService from '../../../api/services/GptService'
import { useState } from 'react'
import Controls from "../../../components/controls/Controls";

const initialValues = {
    jogo: 1,
    imagem: ''
}


export default function Fichario(props) {
    const { consultarMultiplosNomes, listaJogo, alterarLoad, notificar, setPage, userDetails } = props
    const [respGpt, setRespGpt] = useState('')
    const [info, setInfo] = useState('Capture uma foto com suas cartas (recomendado até nove) e catalogue facilmente sua coleção!')
    const validate = () => {
    }
    const {
        values,
        setValues,
        handleInputChange,
        handleInputChangeSwitch,
        erros,
        resetForm,
        setErros
    } = useForm(initialValues, true, validate)


    const consultarImagemChat = async (imagem64) => {
        alterarLoad(true)
        try {
            await GptService.buscarCartaGpt({ jogo: listaJogo.filter(x => x.id === Number(values.jogo))[0].value, nome: imagem64, clienteId: userDetails.id }).then(async response => {
                if (response !== undefined && response !== '' && response !== null && !JSON.stringify(response).includes('Nenhuma carta encontrada.')) {
                    let r = response.choices[0].message.content.split('[')[1].replace(']', '').replaceAll('`', '').replaceAll("\"", '').replaceAll('  ', '').replaceAll('\n', '').trim().split(',')
                    setRespGpt(r)
                    setPage(1)
                    setInfo('')
                    await consultarMultiplosNomes(r, 0)
                } else {

                }
            })
        } catch (e) {
            console.log(e)
        } finally {
            alterarLoad(false)
        }
    }


    const handleInputImage = (e) => {
        var file = e.target.files[0];
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = async function (e) {
            setValues({
                ...values,
                imagem: [reader.result][0]
            });
            await consultarImagemChat(reader.result, alterarLoad, notificar)
        }.bind();
        setValues({
            ...values,
            imagem: e.target.files[0]
        });
    }

    return (
        <Box sx={{ background: 'white', width: '100%', padding: '16px', alignItems: 'center' }}>
            <Grid container spacing={2} sx={{alignItems: 'center'}}>
                <Grid item md={2} xs={6}>
                    <Controls.Select
                        name='jogo'
                        label='Jogo'
                        value={values.jogo}
                        onChange={handleInputChange}
                        options={listaJogo}
                        error={erros.jogo}
                    />
                </Grid>
                <Grid item md={2} sm={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <input
                        accept="image/*"
                        id="contained-file"
                        multiple
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => handleInputImage(e)} />
                    <label htmlFor="contained-file">
                        <Fab component="span" >
                            <AddPhotoAlternateIcon />
                        </Fab>
                    </label>
                    {values.imagem === '' ? null : <img src={values.imagem} alt="image" style={{ zIndex: 5, height: '50px' }} />}
                </Grid>
                <Grid item md={8} xs={12}>
                    <Typography>{info !== '' && info}</Typography>
                    <Typography>{respGpt !== '' && 'Cards encontrados: ' + respGpt.join(', ')}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}