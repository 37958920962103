import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";
import * as Cores from "../../util/Cores";

export default function Select(props) {

    const { name, label, value, error = null, onChange, options, sx, ...other } = props;

    return (
        <FormControl variant='standard'
            sx={{ width: '100%', background: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
            {...(error && { error: true })}>
            <InputLabel sx={{marginLeft: '6px'}}>{label}</InputLabel>
            <MuiSelect
                label={label}
                name={name}
                value={value}
                sx={{ background: 'white' }}
                onChange={onChange}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 400,
                            "::-webkit-scrollbar": {
                                width: "6px",
                            },
                            "::-webkit-scrollbar-track": {
                                backgroundColor: '#fff5f5'
                            },
                            "::-webkit-scrollbar-thumb": {
                                background: Cores.COR_PRINCIPAL,
                                borderRadius: "15px",
                                height: "2px"
                            },
                            "::-webkit-scrollbar-thumb:hover": {
                                background: Cores.COR_PRINCIPAL,
                                maxHeight: "10px"
                            },
                        }
                    }
                }}
                {...other}>
                {
                    options.map(
                        item => (
                            <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
                        )

                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}

