import { Grid } from "@mui/material";
import { useEffect } from 'react';
import { Form, useForm } from "../../../components/UseForm";
import Controls from "../../../components/controls/Controls";

const initialValues = {
    nome: '',
    estoque: 0,
    edicao: '',
    raridade: '',
    natureza: 0,
    idioma: '1',
    jogo: '1',
    valorUnitario: 0,
    imgUrl: '',
    nivel: 0,
    numero: 0
}

export default function CartaForm(props) {

    const { carta, listaEdicao, listaNatureza, listaJogo, listaIdioma, listaRaridade, salvarCarta, listaNivel } = props

    const validate = () => {

    }

    const {
        values,
        setValues,
        handleInputChange,
        resetForm,
        erros
    } = useForm(initialValues, true, validate)

    useEffect(() => {
        carta === null ? setValues(initialValues) : setValues(carta)
    }, []);

    return (
        <Form>
            <Grid container spacing={3} sx={{ marginTop: 1 }}>
                <Grid item md={3} sm={6}>
                    <Controls.Input
                        name='nome'
                        label='Nome'
                        value={values.nome}
                        onChange={handleInputChange}
                        error={erros.nome}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <Controls.Select
                        name='jogo'
                        label='Jogo'
                        value={values.jogo}
                        onChange={handleInputChange}
                        options={listaJogo}
                        error={erros.jogo}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <Controls.Select
                        name='natureza'
                        label='Natureza'
                        value={values.natureza}
                        onChange={handleInputChange}
                        options={listaNatureza}
                        error={erros.natureza}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <Controls.Select
                        name='nivel'
                        label='Nível'
                        value={values.nivel}
                        onChange={handleInputChange}
                        options={listaNivel}
                        error={erros.nivel}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <Controls.Select
                        name='idioma'
                        label='Idioma'
                        value={values.idioma}
                        onChange={handleInputChange}
                        options={listaIdioma}
                        error={erros.idioma}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <Controls.Select
                        name='raridade'
                        label='Raridade'
                        value={values.raridade}
                        onChange={handleInputChange}
                        options={listaRaridade}
                        error={erros.raridade}
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <Controls.Select
                        name='edicao'
                        label='Edição'
                        value={values.edicao}
                        onChange={handleInputChange}
                        options={listaEdicao}
                        error={erros.edicao}
                    />
                </Grid>
                <Grid item md={1} sm={2}>
                    <Controls.Input
                        name='numero'
                        label='Número'
                        value={values.numero}
                        onChange={handleInputChange}
                        error={erros.numero}
                    />
                </Grid>
                <Grid item md={2} sm={2}>
                    <Controls.Input
                        name='valorUnitario'
                        label='Valor Unitário'
                        value={values.valorUnitario}
                        onChange={handleInputChange}
                        error={erros.valorUnitario}
                    />
                </Grid>
                <Grid item md={3} sm={6}>
                    <Controls.Input
                        name='imgUrl'
                        label='Imagem URL'
                        value={values.imgUrl}
                        onChange={handleInputChange}
                        error={erros.imgUrl}
                    />
                </Grid>
                <Grid item md={8} sm={4}>
                    <Controls.Button
                        onClick={() => salvarCarta(values)}
                        text='Submeter'
                    />
                     <Controls.Button
                        onClick={() => resetForm()}
                        sx={{marginLeft: '16px', background: 'white'}}
                        text='Limpar'
                        variant='outlined'
                        color='secondary'
                    />
                </Grid>
            </Grid>
        </Form>
    )
}