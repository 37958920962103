import * as Mascaras from "../../../../util/Mascaras";
import Controls from "../../Controls";
import { useState, useEffect } from 'react'

export default function Bandeira(props) {
    const { onChange, value, error, other } = props
    const [bandeiras, setBandeiras] = useState([{ id: 0, value: 'Selecione' }])

    useEffect(() => {
        setBandeiras(
            [
                { id: 0, value: 'Selecione' },
                { id: 1, value: 'Visa' },
                { id: 2, value: 'Mastercard' },
                { id: 3, value: 'Elo' },
            ]
        )

    }, [])

    return (
        <Controls.Select
            name='bandeira'
            label='Bandeira'
            value={value}
            onChange={onChange}
            options={bandeiras}
            error={error}
            {...other}
        />
    )
}