import { Box } from "@mui/material";



export default function Promocional(props){


    return(
        <Box>
            
        </Box>
    )
}