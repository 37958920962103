import { Box, Typography } from "@mui/material"
import * as Constantes from "../../util/constantes"
import * as Cores from "../../util/Cores";


export default function BarraProgresso(props) {
    const { total, valorAtual, titulo, comPromo, totalPromo, valorAtualPromo, tipo } = props

    const calcularWidthPromo = () => {
        let resp = 0
        if (comPromo) {
            resp = (valorAtual / total) * 100
        } else {
            resp = ((valorAtual - valorAtualPromo) / (total - totalPromo)) * 100
        }
        return resp
    }
    return (
        <Box sx={{ marginTop: '2%' }}>
            <Typography >{titulo}</Typography>
            <Box sx={{ marginLeft: '10%', width: '80%', height: '25px', position: 'relative', backgroundColor: Constantes.COR_SECUNDARIA_ROSA_FUNDO_ESCURO }}>
                <Box sx={{ height: '25px', position: 'absolute', width: calcularWidthPromo() + '%', backgroundColor: Cores.COR_PRINCIPAL }} />
                <Typography sx={{ width: '100%', color: 'white', position: 'absolute' }}>
                    {(!comPromo ? (tipo === 1 ? (valorAtual - valorAtualPromo).toFixed(0) : (valorAtual - valorAtualPromo).toFixed(2)) : valorAtual) + '/' + (!comPromo ? total - totalPromo : total)}
                </Typography>
            </Box>
        </Box>
    )


}