import ModeIcon from '@mui/icons-material/Mode';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Fab as MuiFab } from '@mui/material';
import * as Cores from "../../../util/Cores";

export default function FabPesquisarDeck(props) {

    const { handleAbrirAlterarNomeDeck, handleAlterarNomeDeck, nomeAlterar, deck } = props

    return (
        <Box sx={{
            zIndex: '10',
            '& .MuiFab-root': {
                background: Cores.COR_PRINCIPAL,
                '& .MuiSvgIcon-root': {
                    fill: 'white'
                }
            },
        }}>
            <MuiFab size={'medium'} aria-label="consultar" onClick={(e) => nomeAlterar === deck.nome ? handleAlterarNomeDeck(e, deck) : handleAbrirAlterarNomeDeck(e, deck.nome)}>
                {nomeAlterar === deck.nome ? <SaveAltIcon /> : <ModeIcon />}
            </MuiFab>
        </Box>
    )
}
