import { useEffect, useState } from 'react';
import CartasService from "../../../api/services/CartasService";
import Controls from '../Controls';

export default function SelectCidade(props) {

    const { value, error = null, onChange, sx, uf, ...other } = props;
    const [listaJogos, setListaJogos] = useState([])

    useEffect(() => {
        CartasService.consultarJogos().then((response) => {
            setListaJogos(response)
        })
    }, [uf])

    return (
        <Controls.Select
            name='jogo'
            label='Jogo'
            value={value}
            onChange={(e) => onChange(e)}
            options={listaJogos}
            error={error}
            sx={sx}
            {...other}
        />
    )
}

