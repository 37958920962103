import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Grid, IconButton, InputAdornment } from "@mui/material";
import { useState } from 'react';
import Popup from '../../components/Popup';
import { Form, useForm } from "../../components/UseForm";
import Controls from "../../components/controls/Controls";
import NewTabButton from "../../components/controls/NewTabButton";
import ClienteService from "../../api/services/ClienteService";
import * as Mensagens from "../../util/Mensagens";
import { useCommon } from '../../components/hooks/useCommon';

const initialValues = {
    id: 0,
    username: '',
    email: '',
    senha: '',
    confirmarSenha: '',
    check: false
}

export default function CadastroForm(props) {
    const { setOpenPopupCadastro, openPopupCadastro, setMensagem, setTipoMensagem} = props

    const { alterarLoad, notificar, Common } = useCommon()
    const [mostraSenha, setMostraSenha] = useState('password')

    const validate = (fieldValues = values) => {
        let temp = { ...erros }
        if ('email' in fieldValues) {
            temp.email = fieldValues.email !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
            if (temp.email !== Mensagens.CAMPO_OBRIGATORIO) {
                temp.email = (/^$|.+@.+..+/).test(fieldValues.email) ? '' : Mensagens.CAMPO_INVALIDO
            }
        }
        if ('senha' in fieldValues) {
            temp.senha = fieldValues.senha.length >= 8 ? '' : Mensagens.CAMPO_OBRIGATORIO
        }
        if ('confirmarSenha' in fieldValues) {
            temp.confirmarSenha = fieldValues.confirmarSenha.length >= 8 ? '' : Mensagens.CAMPO_OBRIGATORIO
            if (temp.confirmarSenha !== Mensagens.CAMPO_OBRIGATORIO) {
                temp.confirmarSenha = fieldValues.confirmarSenha === values.senha ? '' : Mensagens.SENHAS_DIFEREM
            }
        }
        if ('username' in fieldValues) {
            temp.username = fieldValues.username.length >= 4 ? '' : Mensagens.CAMPO_OBRIGATORIO
        }
        if ('check' in fieldValues) {
            temp.check = fieldValues.check ? '' : Mensagens.TERMOS_DEVEM_SER_ACEITOS
        }
        setErros({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        handleInputChange,
        erros,
        setErros
    } = useForm(initialValues, true, validate)

    async function cadastrar() {
        alterarLoad(true)
        try {
            await ClienteService.cadastrarCliente({
                userTO: {
                    username: values.username,
                    password: values.senha,
                },
                isAlterar: false,
                id: 0,
                email: values.email
            }).then(response => {
                if (response.status !== 200) {
                    notificar(Mensagens.ERRO_SERVICO, "error")
                } else if (!response.data.erro) {
                    setOpenPopupCadastro(false)
                    notificar(Mensagens.CADASTRO_SUCESSO, "success")
                    setMensagem(response.data.mensagem)
                    setTipoMensagem('success')
                } else {
                    notificar(Mensagens.CADASTRO_ERRO + response.data.mensagem, "error")
                }
            })
        } catch (e) {
            console.log(e)
        } finally {
            alterarLoad(false)
        }

    }

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            cadastrar()
        }

    }

    const handleClickShowPassword = () => {
        if (mostraSenha === 'password') {
            setMostraSenha('text')
        } else {
            setMostraSenha('password')
        }
    }
    return (
        <Popup
            width='100%'
            openPopup={openPopupCadastro}
            setOpenPopup={setOpenPopupCadastro}
            title={'Cadastro'}
        >
            <Form onSubmit={handleSubmit}>
                <Common />
                <Grid container spacing={3} sx={{ marginTop: '0px' }}>
                    <Grid item md={12} xs={12}>
                        <Controls.Input
                            name='username'
                            label='Usuário'
                            onChange={handleInputChange}
                            value={values.username}
                            error={erros.username}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Input
                            name='email'
                            label='Email'
                            onChange={handleInputChange}
                            value={values.email}
                            error={erros.email}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Input
                            name='senha'
                            label='Senha'
                            onChange={handleInputChange}
                            value={values.senha}
                            error={erros.senha}
                            type={mostraSenha}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Input
                            name='confirmarSenha'
                            label='Confirmar Senha'
                            onChange={handleInputChange}
                            value={values.confirmarSenha}
                            error={erros.confirmarSenha}
                            type={mostraSenha}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Box sx={{ display: 'flex', paddingTop: '4%', marginLeft: '5%', width: '100%' }}>
                        <NewTabButton link="/termos" texto="Termos de Uso" />
                        <Box sx={{ paddingInline: '4%' }} />
                        <NewTabButton link="/politica" texto="Política de Privacidade" />
                    </Box>
                    <Grid item md={12} xs={12}>
                        <Controls.Checkbox
                            name="check"
                            label="Li e aceito os termos de uso e a política de privacidade da Summoner Cat."
                            value={values.check}
                            error={erros.check}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Button
                            text='Cadastrar'
                            type='submit'
                        />
                    </Grid>
                </Grid>
            </Form>
        </Popup>

    )
}