import { postImagemGpt } from '../GptApi'

export async function buscarCartaGpt(imagem){
    //alterarLoad(true)
    try {
        return await postImagemGpt(imagem).then(response => {
            return response.data
        })
    } catch (e) {
        //notificar('Erro ao consultar estoque.', "error" )
    } finally {
        //alterarLoad(false)
    }
}

