import { Box, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import PedidoService from "../../api/services/PedidoService";
import StepperCheckout from "../../components/StepperCheckout";
import { useCommon } from "../../components/hooks/useCommon";
import * as Constantes from "../../util/constantes";
import * as Utils from "../../util/Utils";
import ConfirmarPedido from "./confirmar-pedido/ConfirmarPedido";
import EnderecoPedido from "./endereco-pedido/EnderecoPedido";
import FormaPagamento from "./pagamento-pedido/FormaPagamento";
import PagamentoPedido from "./pagamento-pedido/PagamentoPedido";


export default function Checkout(props) {
    const { isLoggedIn, userDetails, carrinho, alterarCarrinho, removerCarrinho, isMobile } = props
    const [activeStep, setActiveStep] = useState(0);
    const { Common, alterarLoad, alterarMensagemLoad, notificar } = useCommon()
    const [pedido, setPedido] = useState(null)
    const [endereco, setEndereco] = useState(null)
    const [compra, setCompra] = useState(null)
    const [numero, setNumero] = useState(null)
    const [pedidoId, setPedidoId] = useState(null)
    const [isAlterado, setIsAlterado] = useState(false)

    useEffect(() => {
        setCompra(carrinho)
    }, [carrinho]);

    useEffect(() => {
        (async () => {
            const query = new URLSearchParams(window.location.search);
            const numero = query.get('pedido')
            if (numero !== null) {
                let c = await buscarPedidoNumero(0, numero)
                setCompra(converterPedido(c))
                setNumero(numero)
                setPedidoId(c.id)
            }else{
                setCompra(carrinho)
            }
        })()
    }, []);

    function alterarCompra(i, quant, carta, desconto) {
        setIsAlterado(true)
        if (compra !== null) {
            return Utils.alterarCarrinho(i, quant, carta, compra, setCompra, userDetails.id, desconto)
        }
    }

    function removerCompra(index) {
        setIsAlterado(true)
        if (compra !== null) {
            setCompra(compra.filter((item, i) => i !== index))
        }
    }

    function converterPedido(compra) {
        return compra.itensPedido.map((item) => {
            return {
                id: item.carta.id,
                valor: item.carta.valor,
                quantidade: item.quantidade,
                imagem: item.carta.imgUrl,
                nome: item.carta.nome,
                estoque: item.carta.estoque,
                desconto: item.carta.desconto
            }
        })
    }

    async function buscarPedidoNumero(id, numero = 0) {
        return await PedidoService.consultarPedido(alterarLoad, notificar, id, numero).then((response) => {
            return response
        })
    }

    async function buscarPedido(id, numero = 0) {
        return await PedidoService.consultarPedido(alterarLoad, notificar, id, numero).then((response) => {
            setPedido(response)
            return !response.erro
        })
    }

    async function salvarPedido() {
        if (numero === null) {
            let pedidoCompleto = {
                id: 0,
                statusId: 1,
                clienteId: userDetails.id,
                cupomId: 0,
                comentario: '',
                enderecoTO: {
                    id: endereco.id
                },
                listaItens: compra.map((item) => {
                    return (
                        {
                            cartaId: item.id,
                            quantidade: item.quantidade
                        })
                })
            }
            return await PedidoService.salvarPedido(notificar, alterarLoad, pedidoCompleto).then((response) => {
                setNumero(response.numero)
                setPedidoId(response.codigo)
                return response
            })
        } else if (isAlterado) {
            let pedidoCompleto = {
                id: pedidoId,
                cupomId: 0,
                comentario: '',
                enderecoTO: {
                    id: endereco.id
                },
                listaItens: compra.map((item) => {
                    return (
                        {
                            cartaId: item.id,
                            quantidade: item.quantidade
                        })
                })
            }
            return await PedidoService.alterarPedido(notificar, alterarLoad, pedidoCompleto).then((response) => {
                return response
            })
        }

    }

    const montarChildren = () => {
        return (
            <Box sx={{ height: '100%', marginTop: '1%' }}>
                {activeStep === 0 &&
                    <Box>
                        <ConfirmarPedido
                            alterarLoad={alterarLoad}
                            notificar={notificar}
                            userDetails={userDetails}
                            carrinho={compra}
                            removerCarrinho={compra === null ? removerCarrinho : removerCompra}
                            alterarCarrinho={compra === null ? alterarCarrinho : alterarCompra}
                        />
                    </Box>}
                {activeStep === 1 &&
                    <Box>
                        <EnderecoPedido
                            alterarLoad={alterarLoad}
                            notificar={notificar}
                            userDetails={userDetails}
                            enderecoSelecionado={endereco}
                            setEnderecoSelecionado={setEndereco}
                        />
                    </Box>}
                {activeStep === 2 &&
                    <Box >
                        <PagamentoPedido
                            pedido={pedido}
                        />
                    </Box>}
                {activeStep === 3 &&
                    <Box >
                        <FormaPagamento
                            notificar={notificar}
                            alterarLoad={alterarLoad}
                            userDetails={userDetails}
                            isMobile={isMobile}
                            pedido={pedido}
                            removerCarrinho={removerCarrinho}
                        />
                    </Box>}
            </Box>
        )
    }



    return (
        <Box sx={{ borderRadius: '25px', height: '100%', backdropFilter: 'blur(10px)', background: Constantes.COR_SECUNDARIA_FUNDO_OPACITY, paddingInline: '5px', paddingBottom: '60px' }}>
            <Common />
            {compra !== null ?
                <Box>
                    {isLoggedIn ? <StepperCheckout
                        buscarPedido={buscarPedido}
                        salvarPedido={salvarPedido}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        children={montarChildren()}
                    />
                        :
                        <Typography>É necessário fazer login.</Typography>}
                </Box>
                :
                <Typography></Typography>
            }
        </Box>
    )
}