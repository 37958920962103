import * as Mascaras from "../../../../util/Mascaras";
import Controls from "../../Controls";


export default function CodigoSeguranca(props) {
    const {id='', onChange, value, error} = props

    return (
        <Controls.Input
            name='codigoSeguranca'
            label='Código de Segurança'
            onChange={onChange}
            value={Mascaras.tam(Mascaras.digitos(value), 3)}
            error={error}
            id={id}
        />
    )
}