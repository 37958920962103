import { Button as MuiButton } from '@mui/material'
export default function Button(props) {

    const { text, size, color, variant, onClick, sx = null, ...other } = props

    return (
        <MuiButton
            variant={variant || "contained"}
            size={size || 'large'}
            onClick={onClick}
            color={color || 'primary'}
            {...other}
            sx={{ paddingInline: (text.length + 20) + 'px',...sx}}
        >
            {text}
        </MuiButton>
    )
}