import { Box, Tooltip, Typography } from "@mui/material";
import * as Utils from '../../util/Utils'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { useCommon } from "../../components/hooks/useCommon";


export default function VisualizarCartaDash(props) {
    const { carta, setCarta, userDetails, atualizarCarta } = props
    const { notificar, Common } = useCommon()

    return (
        <Box>
            <Common />
            <Box sx={{
                '& .MuiSvgIcon-root': {
                    marginInline: '6px',
                    '&:hover': {
                        cursor: 'pointer',
                        fill: '#F06292',
                    }
                }
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                        <Tooltip title="Minha coleção">
                            {carta.ehColecao ?
                                <ImportContactsIcon fontSize='large' color='primary' onClick={(e) => Utils.adicionarColecao(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                : <ImportContactsIcon fontSize='large' onClick={(e) => Utils.adicionarColecao(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                            }
                        </Tooltip>
                        <Tooltip title="Lista de desejos">
                            {carta.ehDesejo ?
                                <FavoriteIcon fontSize='large' color='secondary' onClick={(e) => Utils.adicionarDesejo(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                : <FavoriteIcon fontSize='large' onClick={(e) => Utils.adicionarDesejo(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                            }
                        </Tooltip>

                        <Tooltip title="Cartas de troca">
                            {carta.ehTroca ?
                                <ChangeCircleIcon fontSize='large' sx={{ fill: '#2196f3' }} onClick={(e) => Utils.adicionarTroca(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                                : <ChangeCircleIcon fontSize='large' onClick={(e) => Utils.adicionarTroca(carta, setCarta, userDetails, atualizarCarta, e, notificar)} />
                            }
                        </Tooltip>
                    </Box>
                    <Box sx={{ alignItems: 'center', marginTop: '2.5%' }}>
                        <Typography variant='h5' fontWeight={700} color='green'>{' R$' + (carta.valor + '').replace('.', ',')}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                    <img className="main-card" height='350px' data-src={carta.imgUrl} src={carta.imgUrl} />
                </Box>
                <Box >
                    <Box sx={{ marginLeft: '0', width: '100%', marginTop: '2%' }}>
                        <Typography variant='body2'> <b>Raridade</b>: {carta.raridadeDescricao} </Typography>
                        <Typography variant='body2'> <b>Edição:</b>{carta.ano + '-'} {carta.edicaoDescricao}</Typography>
                        <Typography variant='body2'> <b>Número:</b> {carta.numero}</Typography>
                        {carta.naturezaDescricao !== 'Nenhum' && <Typography variant='body2'> <b>Natureza:</b> {carta.naturezaDescricao}</Typography>}
                        {carta.nivelDescricao !== null && <Typography variant='body2'> <b>Nível:</b> {carta.nivelDescricao}</Typography>}
                    </Box>
                </Box>

            </Box>
            {/*carta.estoque !== '0' ? <LojaFragmento carta={carta} /> : null*/}
        </Box>
    )
}