
import { Box, IconButton, Typography } from "@mui/material";
import Media from "react-media";
import * as Constantes from "../../util/constantes";
import * as Cores from "../../util/Cores";

export default function HomeCard(props) {
    const { handleClick, icone, texto } = props


    return (
        <Media queries={{ small: { maxWidth: Constantes.MOBILE_SCREEN_SIZE } }}>
            {matches => matches.small ? (
                <Box sx={{
                    width: '25%',
                    '& .MuiSvgIcon-root': {
                        transform: "scale(2.8)",
                        padding: '3px',
                        border: '1px solid ' + Cores.COR_PRINCIPAL_ESCURO,
                        borderRadius: '25px',
                        background: 'white'
                    },
                }} onClick={() => handleClick()}>
                    <IconButton>
                        {icone}
                    </IconButton>
                    <Typography color={Cores.COR_PRINCIPAL_ESCURO} sx={{ marginTop: '20px' }}>
                        {texto}
                    </Typography>
                </Box>
            ) : (
                <Box sx={{
                    cursor: 'pointer', width: '130px', textAlign: 'center',
                    '& .MuiSvgIcon-root': {
                        transform: "scale(3.5)",
                        padding: '3px',
                        border: '1px solid ' + Cores.COR_PRINCIPAL_ESCURO,
                        borderRadius: '25px',
                        background: 'white',
                    },
                    '&:hover': {
                        '& .MuiSvgIcon-root': {
                            transform: "scale(3)",
                            border: '1px solid ' + Cores.COR_PRINCIPAL_ESCURO,
                            fill: Cores.COR_PRINCIPAL_ESCURO,
                            background: Constantes.COR_SECUNDARIA_ROSA_FUNDO
                        },
                    }
                }} onClick={() => handleClick()}>
                    <IconButton disabled>
                        {icone}
                    </IconButton>

                    <Typography sx={{ marginTop: '30px' }} fontWeight={700} variant="h6" color={Cores.COR_PRINCIPAL_ESCURO}>
                        {texto}
                    </Typography>
                </Box>
            )
            }
        </Media>


    )
}