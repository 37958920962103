import { Box } from "@mui/material";
import Bricks from "./Bricks";

export default function FormaPagamento(props) {
    const { notificar, alterarLoad, userDetails, isMobile, pedido, removerCarrinho } = props
    return (
        <Box sx={{
            marginLeft: isMobile ? '0%' : '15%', width: isMobile ? '100%' : '70%', background: 'white', borderRadius: '30px', marginTop: '16px'
        }}>
            <Bricks
                removerCarrinho={removerCarrinho}
                notificar={notificar}
                alterarLoad={alterarLoad}
                userDetails={userDetails}
                isMobile={isMobile}
                dadosPagamento={{ 
                    valor: (pedido.valor + pedido.frete), 
                    cdPedido: pedido.numero,
                    items: pedido.itensPedido.map(item => {
                        return {
                            id: item.carta.id,
                            titulo: item.carta.numero + '-' + item.carta.nome + ' - ' + item.carta.edicaoDescricao,
                            descricao: 'Produto adquirido na loja Summoner Cat.',
                            quantidade: item.quantidade,
                            precoUnitario: item.valorUnitario - ((item.valorUnitario*item.descontoUnitario)/100)
                        }
                    }),
                    payer: {
                        nome: '',
                        sobrenome: '',
                        email: '',
                        cpf: '',
                        rua: pedido.enderecoTO.rua,
                        numero: pedido.enderecoTO.numero,
                        cep: pedido.enderecoTO.cep
                    }
                
                }}
            />
            {/* <Assinatura
                notificar={notificar}
                alterarLoad={alterarLoad}
                userDetails={userDetails}
                isMobile={isMobile}
                dadosPagamento={{ valor: '20' }}
            /> */}
        </Box>
    )

}