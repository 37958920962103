import { Box, Divider, Typography } from "@mui/material"
import * as Constantes from "../../../util/constantes"


export default function OpcoesHash(props) {
    const { opcoes, consultar, width } = props
    
    return (
        <Box sx={{ marginTop: '5%', width: width < Constantes.MOBILE_SCREEN_SIZE ? '80%' : '45%', marginLeft: width < Constantes.MOBILE_SCREEN_SIZE ? '10%' :'27.5%' }}>
            <Typography>Mais de um resultado encontrado</Typography>
            <Divider sx={{marginTop: '3%'}}/>
            {opcoes.map((opcao, index) => {
                return (
                    <Box key={index} onClick={() => consultar(opcao)} sx={{
                        '&:hover': {
                            backgroundColor: '#f3dcf7',
                            cursor: 'pointer',
                        }
                    }}>
                        <Box sx={{
                            padding:  width < Constantes.MOBILE_SCREEN_SIZE ? '4%' : '2%', display: 'flex', justifyContent: 'space-between', textAlign: 'left'
                        }}>
                            <Typography>{opcao.nome}</Typography>
                            <Typography>{opcao.tipoNome}</Typography>
                        </Box>
                        <Divider />
                    </Box>
                )
            })}

        </Box>
    )
}