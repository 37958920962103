import { Button as MuiButton } from '@mui/material'


export default function ActionButton(props) {

    const { color, children, onClick } = props

    return (
        <MuiButton
            onClick={onClick}
            color={color || 'primary'}
        >
            {children}
        </MuiButton>
    )
}