import { Box, Typography } from "@mui/material";
import { useState } from 'react';
import Popup from "../../components/Popup";
import Controls from "../../components/controls/Controls";
import VisualizarCartaDash from "./VisualizarCartaDash";
import * as Constantes from '../../util/constantes'
import * as Utils from '../../util/Utils'

export default function VerColecao(props) {

    const { consultarEdicaoDashboard, colecao, isMobile, userDetails, atualizarCarta, comPromo } = props

    const [cartaPopup, setCartaPopup] = useState(false)
    const [cartaInPopup, setCartaInPopup] = useState({nome: ''})

    const filtrarPromo = (cartas) => {
        let cartasProcessada = cartas
        if(!comPromo){
            cartasProcessada = cartasProcessada.filter(x => x.raridade !== Constantes.RARIDADE_PROMO_DIGI)
        }
        return isMobile ? Utils.dividirArrayEmGrupos(cartasProcessada, Constantes.CARD_PER_ROW_MOBILE + 1)
                : Utils.dividirArrayEmGrupos(cartasProcessada, Constantes.CARD_PER_ROW + 4)
    }

    return (
        <Box>
            <Controls.Button
                text='Ver Álbum'
                sx={{ marginTop: '10px', marginBottom: '10px' }}
                onClick={(e) => consultarEdicaoDashboard()}
            />
            {colecao.cartasTO.length > 0 && <Typography variant='h6' color='primary' fontWeight={700}>{colecao.nomeEdicao}</Typography>}
            {colecao.cartasTO.length > 0 && filtrarPromo(colecao.cartasTO).map((cartasLinha, index) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }} key={index}>
                        {cartasLinha.map((carta, indexCarta) => {
                            return (
                                <Box key={indexCarta} sx={{ cursor: 'pointer' }} onClick={() => { setCartaPopup(true); setCartaInPopup(carta) }}>
                                    <img style={{ filter: !colecao.codigoCartasColecao.includes(carta.id) && 'brightness(50%)' }}
                                        className="main-card" width={isMobile ? '120' : '170'} data-src={carta.imgUrl} src={carta.imgUrl} />
                                </Box>
                            )
                        })}
                    </Box>
                )


            })}

            <Popup
                width={isMobile ? '100%' : '22%'}
                openPopup={cartaPopup}
                setOpenPopup={setCartaPopup}
                title={cartaInPopup.nome}
            >
                <VisualizarCartaDash
                    carta={cartaInPopup}
                    isLoggedIn={true}
                    isMobile={isMobile}
                    setCarta={setCartaInPopup}
                    userDetails={userDetails}
                    atualizarCarta={atualizarCarta}
                />
            </Popup>
        </Box>
    )
}