import axios from "axios";
import * as Utils from "../util/Utils"

const SALVAR_CARTA = 'carta/salvar'
const SALVAR_EDICAO = 'edicao/salvar'
const SALVAR_LISTA = 'listas/salvar'
const ADICIONAR_CARTA = 'listas/adicionar'
const REMOVER_CARTA = 'listas/remover'
const CONSULTAR_LISTA_CLIENTE = 'listas/consultar'
const CONSULTAR_CARTA_POR_ID = 'carta/consultar'
const CONSULTAR_CARTA_POR_NOME_EDICAO = 'carta/consultar/nomeEdicao'
const CONSULTAR_CARTA_FILTRO = "carta/consultar/filtro"
const CONSULTAR_CARTA_POR_NOME = "/carta/consultar/nome"
const CONSULTAR_CARTA_POR_MULTIPLOS_NOMES = "/carta/consultar/multiplos/nome"
const CONSULTAR_CARTA_POR_NOME_SIMPLES = "/carta/consultar/nomeSimples"
const LISTAR_COMBOS = "utils/lista/combos"
const DELETAR_CARTA = "carta/excluir/"
const PESQUISAR_HASH = "carta/consultar/hash"
const PESQUISAR_HASH_OPCOES = "/carta/consultar/hash/opcoes"
const PESQUISAR_DECKS_USUARIO = "/listas/deck/usuario"
const PESQUISAR_DECK_CODIGO = "/listas/deck/consultar"
const CRIAR_DECK = "/listas/deck/criar"
const EXCLUIR_DECK = "/listas/deck/excluir"
const SALVAR_DECK = "/listas/deck/salvar"
const ALTERAR_NOME_DECK = "/listas/deck/nome/alterar"
const LISTAR_JOGOS = "/carta/listar/jogos"
const CONSULTAR_EDICAO_JOGO = "/carta/listar/edicao/jogo"
const CONSULTAR_CARTA_LISTA_CLIENTE = '/carta/consultar/hash/carta'

const CONSULTAR_DASHBOARD_CLIENTE_SIMPLES = "/dashboard/consultar/simples"
const CONSULTAR_DASHBOARD_EDICAO = "/dashboard/consultar/edicao"
const CONSULTAR_EDICAO_DASHBOARD = '/dashboard/listar/edicao'
const CONSULTAR_DASHBOARD_CONQUISTAS = '/dashboard/listar/conquistas'

const client = axios.create({
    baseURL: process.env.REACT_APP_AMBIENTE
});

export async function postSalvarEdicao(edicao) {
    return client.post(SALVAR_EDICAO, edicao, Utils.getAuth())
}

export async function postRemoverCartaLista(cartaCliente) {
    return client.post(REMOVER_CARTA, cartaCliente, Utils.getAuth())
}

export async function postAdicionarCartaLista(cartaCliente) {
    return client.post(ADICIONAR_CARTA, cartaCliente, Utils.getAuth())
}

export async function postExcluirDeck(request) {
    return client.post(EXCLUIR_DECK, request, Utils.getAuth())
}

export async function postCriarDeck(request) {
    return client.post(CRIAR_DECK, request, Utils.getAuth())
}

export async function postSalvarLista(lista) {
    return client.post(SALVAR_LISTA, lista, Utils.getAuth())
}

export async function postSalvarCarta(carta) {
    return client.post(SALVAR_CARTA, carta, Utils.getAuth())
}

export async function deleteCarta(cartaId) {
    return client.delete(DELETAR_CARTA + cartaId, Utils.getAuth())
}

export async function getListaJogos() {
    return client.get(LISTAR_JOGOS)
}

export async function postSalvarDeck(deckTO) {
    return client.post(SALVAR_DECK, deckTO, Utils.getAuth())
}

export async function postAlterarNomeDeck(deck) {
    return client.post(ALTERAR_NOME_DECK, deck, Utils.getAuth())
}

export async function getConsultarLista(clienteId, tipoListaId) {
    return client.get(CONSULTAR_LISTA_CLIENTE + '?clienteId=' + clienteId + '&tipoListaId=' + tipoListaId)
}

export async function getConsultarDecksUsuario(clienteId) {
    return client.get(PESQUISAR_DECKS_USUARIO + '?clienteId=' + clienteId)
}

export async function getConsultarEdicaoPorJogo(jogoId) {
    return client.get(CONSULTAR_EDICAO_JOGO + '?jogoId=' + jogoId)
}

export async function postConsultarCartaDeCliente(request) {
    return client.post(CONSULTAR_CARTA_LISTA_CLIENTE, request)
}

export async function getConsultarDeckCodigo(deckId, clienteId) {
    return client.get(PESQUISAR_DECK_CODIGO + '?deckId=' + deckId + '&clienteId=' + clienteId)
}

export async function getConsultarCartaPorNome(nome, clienteId) {
    return client.get(CONSULTAR_CARTA_POR_NOME + '?nome=' + nome + '&clienteId=' + clienteId)
}

export async function postConsultarCartaPorMultiplosNome(request) {
    return client.post(CONSULTAR_CARTA_POR_MULTIPLOS_NOMES, request, Utils.getAuth())
}

export async function getConsultarCartaPorNomeSimples(nome) {
    return client.get(CONSULTAR_CARTA_POR_NOME_SIMPLES + '?nome=' + nome)
}

export async function getConsultarCartaPorId(id) {
    return client.get(CONSULTAR_CARTA_POR_ID + '?id=' + id)
}

export async function getConsultarCartasPorNomeEdicao(edicao, nome) {
    return client.get(CONSULTAR_CARTA_POR_NOME_EDICAO + '?edicao=' + edicao + '&cartaNome=' + nome)
}

export async function postConsultarCartasPorFiltro(filtro) {
    return client.post(CONSULTAR_CARTA_FILTRO, filtro)
}

export async function getListarCombos(jogo) {
    return client.get(LISTAR_COMBOS + '?jogo=' + jogo)
}

export async function postListaPorHash(request) {
    return client.post(PESQUISAR_HASH, request)
}

export async function getListaHashOpcoes(hash, tipo, tipoListaId, clienteConsultadoId) {
    return client.get(PESQUISAR_HASH_OPCOES + '?hash=' + hash + '&tipo=' + tipo + '&tipoListaId=' + tipoListaId + '&clienteConsultadoId=' + clienteConsultadoId)
}

export async function postConsultarDashboardSimplesCliente(request) {
    return client.post(CONSULTAR_DASHBOARD_CLIENTE_SIMPLES, request, Utils.getAuth())
}

export async function getConsultarEdicaoDashboard(request) {
    return client.get(CONSULTAR_EDICAO_DASHBOARD + '?clienteId=' + request.clienteId + '&edicaoId=' + request.edicaoId, Utils.getAuth())
}

export async function getConsultarDashboardEdicao(id, clienteId) {
    return client.get(CONSULTAR_DASHBOARD_EDICAO + '?id=' + id + '&clienteId=' + clienteId, Utils.getAuth())
}

export async function getConsultarDashboardConquistas(request) {
    return client.get(CONSULTAR_DASHBOARD_CONQUISTAS + '?clienteId=' + request.clienteId + '&jogoId=' + request.jogoId, Utils.getAuth())
}