
import SearchIcon from '@mui/icons-material/Search';
import { Box, Fab, Grid, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import CartasService from "../../../api/services/CartasService";
import { Form, useForm } from '../../../components/UseForm';
import Controls from '../../../components/controls/Controls';
import * as Mensagens from "../../../util/Mensagens";
import * as Utils from "../../../util/Utils";
import * as Constantes from "../../../util/constantes";
import CartasHash from "./CartasHash";
import OpcoesHash from "./OpcoesHash";
import { useSearchParams } from "react-router-dom";
import { useCommon } from '../../../components/hooks/useCommon';

const initialValues = {
    hash: '',
    jogo: 1
}

export default function PesquisarHash(props) {
    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1)
    const { width } = Utils.useWindowDimensions();
    const [isDeck, setIsDeck] = useState(false)
    const [opcao, setOpcao] = useState(null)
    const [params] = useSearchParams();
    const [ultimaConsulta, setUltimaConsulta] = useState(0)
    const [valuesBarraPesquisa, setValuesBarraPesquisa] = useState(null)
    const { listaVisualizacao, alterarCarrinho, setListaVisualizacao, opcoes, setOpcoes, tipoListaId, setTipoListaId, pesquisou, setPesquisou,
        nomeLista, setNomeLista, isLoggedIn, userDetails, isMobile, nome, setNome } = props

    const { Common, notificar, alterarLoad } = useCommon()

    useEffect(() => {
        let nomeUrl = params.get("nome")
        let jogoUrl = params.get("jogo")
        if (!pesquisou && nomeUrl !== null) {
            setValues({ hash: nomeUrl, jogo: jogoUrl === null ? 1 : jogoUrl })
            consultarOpcoes(nomeUrl, -1, undefined, jogoUrl === null ? 1 : jogoUrl)
        } else if(userDetails.id > 0){
            setValues({ hash: userDetails.username, jogo: 1  })
            consultarOpcoes(userDetails.username, -1, undefined, 1)
        }

    }, []);


    const validate = (fieldValues = values) => {
        let temp = { ...erros }

        if ('hash' in fieldValues) {
            temp.hash = fieldValues.hash !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
        }

        setErros({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        handleInputChange,
        erros,
        setErros
    } = useForm(initialValues, true, validate)

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            consultarOpcoes(values.hash, -1)
        }
    }

    async function consultarOpcoes(hash, tipo, tipoListaId, jogo = values.jogo) {
        let clienteConsultadoId = opcoes.length === 0 ? 0 : opcoes[0].clienteId
        try {
            alterarLoad(true)
            await CartasService.consultarHashOpcoes(hash.replace("#", ""), tipo, tipoListaId, clienteConsultadoId, notificar).then(async response => {
                setOpcoes(response)
                if (response.length === 1) {
                    setOpcao(response[0])
                    setPage(1)
                    await consultar(response[0], jogo)
                }
            })
        } catch (e) {
            console.log(e)
        } finally {
            alterarLoad(false)
        }
    }
    async function consultar(opcao, jogo, pagina = page) {
        setUltimaConsulta(0)
        try {
            setOpcoes([opcao])
            await CartasService.consultarHash({ ...opcao, usuarioLogadoId: userDetails.id, jogo: jogo, pagina: pagina - 1, tamanhoPagina: Constantes.CARD_PER_PAGE_MOBILE }).then(response => {
                if (response !== null) {
                    setPesquisou(true)
                    setNome(response.username)
                    setIsDeck(response.deck)
                    setNomeLista(response.listaNome)
                    setTipoListaId(response.tipoListaId)
                    setCount(response.numPaginas)
                    if (response.tipoListaNome === "") {
                        setNomeLista('Lista não encontrada.')
                        setNome('Lista não encontrada.')
                        setListaVisualizacao([])
                    } else {
                        atualizarLista(response)
                    }
                } else {
                    notificar('Erro ao consultar listas.', 'error')
                }
            })
        } catch (e) {
            notificar(Mensagens.ERRO_SERVICO, "error")
            setListaVisualizacao([])
        } finally {

        }
    }

    async function consultarCartaDeClienteInicio(valuesLocal, pagina = page) {
        try {
            setPage(1)
            alterarLoad(true)
            await consultarCartaDeCliente(valuesLocal, pagina)
        } catch (e) {
            console.log(e)
        } finally {
            alterarLoad(false)
        }
    }

    async function consultarCartaDeCliente(valuesLocal, pagina = page) {
        setUltimaConsulta(1)
        setValuesBarraPesquisa(valuesLocal)
        let request = {
            clienteId: opcoes.length > 0 ? opcoes[0].clienteId : 0,
            usuarioLogadoId: userDetails.id,
            edicaoId: valuesLocal.edicao,
            nomeCarta: valuesLocal.carta,
            jogo: values.jogo,
            pagina: pagina - 1,
            tamanhoPagina: Constantes.CARD_PER_PAGE_MOBILE,
            tipoLista: tipoListaId
        }
        await CartasService.consultarCartaDeCliente(request).then((response) => {
            atualizarLista(response)
            setCount(response.numPaginas)
        })
    }

    function atualizarLista(response) {
        let grupos = isMobile ?
            Utils.dividirArrayEmGrupos(response.cartas, Constantes.CARD_PER_ROW_MOBILE) :
            Utils.dividirArrayEmGrupos(response.cartas, Constantes.CARD_PER_ROW)
        setListaVisualizacao(grupos)
    }

    const handleChangePagination = async (event, value) => {
        setPage(value);
        try {
            if (ultimaConsulta === 1) {
                await consultarCartaDeCliente(valuesBarraPesquisa, value)
            } else {
                await consultar(opcao, values.jogo, value)
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Box>
            <Common />
            <Box sx={{ background: 'white', padding: '10px', paddingBottom: '18px', alignItems: 'center', width: '100%' }}>
                <Grid container spacing={0.5}>
                    <Grid item md={8} xs={6}>
                        <Controls.Input
                            name='hash'
                            label='Nome da Lista, Username ou Deck'
                            value={values.hash}
                            onChange={handleInputChange}
                            error={erros.hash}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">#</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item md={2} xs={3.5}>
                        <Controls.Dados.Jogo
                            onChange={handleInputChange}
                            value={values.jogo}
                            error={erros.jogo}
                        />
                    </Grid>
                    <Grid item md={2} xs={2} sx={{ textAlign: 'center', display: 'contents' }}>
                        <Box sx={{ marginLeft: isMobile ? '4%' : '2%' }}>
                            <Box>
                                <Fab color="primary" size='medium' onClick={(e) => { handleSubmit(e) }}>
                                    <SearchIcon sx={{ fill: 'white' }} />
                                </Fab>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            {opcoes.length > 1 ?
                <OpcoesHash
                    opcoes={opcoes}
                    consultar={consultar}
                    width={width}
                />
                :
                <CartasHash
                    consultarCartaDeCliente={consultarCartaDeCliente}
                    consultarCartaDeClienteInicio={consultarCartaDeClienteInicio}
                    pesquisou={pesquisou}
                    isMobile={isMobile}
                    alterarCarrinho={alterarCarrinho}
                    listaVisualizacao={listaVisualizacao}
                    isDeck={isDeck}
                    nome={nome}
                    nomeLista={nomeLista}
                    consultarOpcoes={consultarOpcoes}
                    tipoListaId={tipoListaId}
                    page={page}
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails}
                    width={width}
                    handleChangePagination={handleChangePagination}
                    count={count}
                    jogo={values.jogo}
                    values={values}
                />}
        </Box >
    )
}