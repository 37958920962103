import { Box, Typography } from "@mui/material";
import colecaoImg from '../../../img/colecao.png';
import deckBuilderImg from '../../../img/deckBuilder.png';
import desejoImg from '../../../img/desejo.png';
import logoImg from "../../../img/logoTexto.png";
import logoVerticalImg from "../../../img/logoVertical.png";
import albumImg from "../../../img/album.png";
import pesquisarListasImg from '../../../img/pesquisarListas.png';
import trocaImg from '../../../img/troca.png';
import catalogueImg from '../../../img/catalogue.png';
import * as Mensagens from "../../../util/Mensagens";
import RevealAnimation from "../../../util/RevealAnimation";
import BlocoTutorialWeb from "./BlocoTutorialWeb";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function TutorialWeb(props) {
    const { isMobile } = props

    return (
        <Box sx={{ backgroundColor: 'white', borderRadius: '10px' }}>
            <Box sx={{ paddingTop: isMobile ? '65px' : '100px', paddingBottom: isMobile ? '65px' : '100px' }}>
                <RevealAnimation delay={0.3} margin={"0px 0px"}>
                    <img src={isMobile ? logoVerticalImg : logoImg} alt="logo" height={isMobile ? '250px' : '180px'} overflow='hidden' />
                </RevealAnimation>
                <RevealAnimation delay={0.7} margin={"0px 0px"}>
                    <Typography variant='h4' color='primary'>Um assistente de colecionador gamificado para te ajuadar a organizar suas coleções!</Typography>
                    <Typography variant='h2' color='primary' sx={{ paddingTop: '40px', fontWeight: 700 }}>Conheça nossos serviços!</Typography>
                    <ArrowDownwardIcon color='primary'
                        sx={{
                            transform: 'scale(4)',
                            marginTop: '120px'
                        }}
                    />
                </RevealAnimation>
            </Box>


            <BlocoTutorialWeb
                titulo={'Catalogue sua coleção tirando fotos'}
                mensagem={Mensagens.TEXTO_TUTORIAL_FICHARIO}
                imagem={catalogueImg}
                isMobile={isMobile}
            />
            <BlocoTutorialWeb
                titulo={'Sua Coleção'}
                mensagem={Mensagens.TEXTO_HOME_COLECAO}
                imagem={colecaoImg}
                isMobile={isMobile}
            />

            <BlocoTutorialWeb
                titulo={'Lista de Desejos'}
                mensagem={Mensagens.TEXTO_HOME_DESEJO}
                imagem={desejoImg}
                isMobile={isMobile}
            />

            <BlocoTutorialWeb
                titulo={'Lista de Trocas'}
                mensagem={Mensagens.TEXTO_HOME_TROCA}
                imagem={trocaImg}
                isMobile={isMobile}
            />

            <BlocoTutorialWeb
                titulo={'Compartilhe suas Listas!'}
                mensagem={Mensagens.TEXTO_HOME_PESQUISAR}
                imagem={pesquisarListasImg}
                isMobile={isMobile}
            />
            <BlocoTutorialWeb
                titulo={'Sua coleção é como um álbum de figurinhas!'}
                mensagem={Mensagens.TEXTO_COLECAO_ALBUM}
                imagem={albumImg}
                isMobile={isMobile}
            />
            <Box sx={{ paddingBottom: '150px' }} />
            <BlocoTutorialWeb
                titulo={'Monte seus baralhos'}
                mensagem={Mensagens.TEXTO_TUTORIAL_DECK_BUILDER}
                imagem={deckBuilderImg}
                isMobile={isMobile}
            />
             <BlocoTutorialWeb
                titulo={'Uma loja com o que você precisa [EM CONSTRUÇÃO]'}
                mensagem={Mensagens.TEXTO_TUTORIAL_LOJA}
                imagem={deckBuilderImg}
                isMobile={isMobile}
            />
        </Box>
    )
}