import {
    getEnderecoCliente, getEstados, deleteEndereco, postSalvarEndereco, getCliente, postCadastrarCliente, postLogin,
    postAlterarSenha, postSolicitaAlterarSenha, getChecarLink, postAtivarConta, postDeletarCliente
} from '../ClienteApi'
import * as Mensagens from '../../util/Mensagens'
import * as Constantes from '../../util/constantes'



async function consultarCliente(codigo) {
    getCliente(codigo).then(response => {
        return response
    })
}
async function deletarCliente(id, notificar, alterarLoad, setUserDetails, setExibirModal) {
    let resp
    alterarLoad(true)
    try {
        await postDeletarCliente({
            userTO: {
                username: "",
                password: "",
            },
            isAlterar: false,
            id: id,
            email: ""
        }
        ).then(response => {
            resp = response.data
            notificar( resp.erro ? resp.mensagem : "Excluído com sucesso!", resp.erro ? "error" : "success")
            if (!resp.erro) {
                setUserDetails({
                    roles: Constantes.ROLE_ADMIN,
                    id: 0,
                    username: '',
                    email: '',
                    senha: '',
                    data: new Date().getTime()
                })
            }
        })
        return resp
    } catch (e) {
        notificar(Mensagens.ERRO_SERVICO, "error")
    } finally {
        setExibirModal(false)
        alterarLoad(false)
    }
}

async function checarLink(hash) {
    let resp
    await getChecarLink(hash).then(response => {
        resp = { ...response.data, status: response.status }
    })
    return resp
}

async function cadastrarCliente(cadastro) {
    return postCadastrarCliente(cadastro)
}

async function login(credenciais) {
    return postLogin(credenciais)
}

async function alterarSenha(request) {
    let resp
    await postAlterarSenha(request).then(response => {
        resp = { ...response.data, status: response.status }
    })
    return resp
}

async function solicitaAlterarSenha(request) {
    let resp
    await postSolicitaAlterarSenha(request).then(response => {
        resp = { ...response.data, status: response.status }
    })
    return resp
}

async function ativarConta(request) {
    let resp
    await postAtivarConta(request).then(response => {
        resp = { ...response.data, status: response.status }
    })
    return resp
}

async function consultarEstados() {
    return await getEstados().then(response => {
        return response.data.map((item) => { return ({ id: item.id, value: item.nome }) })
    })
}

async function consultarEnderecosCliente(alterarLoad, notificar, id) {
    alterarLoad(true)
    try {
        if(id !== undefined){
            return await getEnderecoCliente(id).then(response => {
                return response.data
            })
        }
    } catch (e) {
        console.log(e)
        notificar("Erro ao consultar endereço.", "error")
    } finally {
        alterarLoad(false)
    }

}

async function deletarEndereco(notificar, id) {
    try {
        return await deleteEndereco(id).then((response) => {
            notificar(response.erro ? response.mensagem : "Endereço excluído com sucesso.", response.erro ? "error" : "success")
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao excluir endereço.", "error")
    }

}

async function salvarEnderecoCliente(notificar, endereco) {
    try {
        return await postSalvarEndereco(endereco).then(response => {
            notificar(response.erro ? response.mensagem : "Endereço salvo com sucesso.", response.erro ? "error" : 'success')
            return response.data
        })
    } catch (e) {
        notificar("Erro ao salvar endereço.", "error")
        console.log(e)
    }


}

const ClienteService = {
    salvarEnderecoCliente,
    consultarEnderecosCliente,
    consultarEstados,
    deletarCliente,
    ativarConta,
    consultarCliente,
    cadastrarCliente,
    alterarSenha,
    solicitaAlterarSenha,
    login,
    checarLink,
    deletarEndereco
}


export default ClienteService
