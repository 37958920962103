import { Box, Typography } from "@mui/material";
import * as Utils from "../../util/Utils";
import * as Constantes from "../../util/constantes";
import Login from "./Login";

export default function LoginWrapper(props) {
    const { setIsLoggedIn, isLoggedIn, setUserDetails } = props
    const { height, width } = Utils.useWindowDimensions();
    return (
        <Box sx={{ height: height * 0.694 }}>
            {isLoggedIn ?
                <Typography sx={{background: 'white'}}>Usuário já logado!</Typography>
                :
                <Login
                    isMobile={width < Constantes.MOBILE_SCREEN_SIZE}
                    setIsLoggedIn={setIsLoggedIn}
                    setUserDetails={setUserDetails}
                />
            }
        </Box>
    )
}