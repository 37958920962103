import { Box, Tooltip, Typography } from "@mui/material";
import * as Constantes from "../../util/constantes";
import * as icones from '../../util/icones'
import Controls from "../../components/controls/Controls";

export default function BarraLateral(props) {
    const { dashboardLateral, conquistas } = props

    const pegarLista = (tipo, label) => {
        return dashboardLateral.map((item) => {
            if (tipo === item.tipo_lista) {
                return (
                    <Box>
                        <Typography><b>{label}:</b> {item.count} cartas (R${item.sum}) </Typography>
                    </Box>
                )
            }
        })
    }

    return (
        <Box sx={{ textAlign: 'left', padding: '8px' }}>
            <Box>
                <Typography variant='h6' color='primary' fontWeight={700}>Estatísticas</Typography>
                {pegarLista(Constantes.TIPO_LISTA_COLECAO, "Coleção")}
                {pegarLista(Constantes.TIPO_LISTA_TROCA, "Troca")}
                {pegarLista(Constantes.TIPO_LISTA_DESEJO, "Desejos")}
            </Box>
            <Box>
                <Typography variant='h6' color='primary' fontWeight={700}>Conquistas</Typography>
                {conquistas == undefined || conquistas.length == 0 ? <Typography>Nenhum</Typography>
                    :
                    <Box sx={{ display: 'flex' }}>
                        {conquistas.map((conquista, index) => {
                            let progresso = conquista.progresso < conquista.bronze ? (conquista.progresso/conquista.bronze) * 100 : 0
                            progresso = conquista.progresso >= conquista.bronze ? ((conquista.progresso - conquista.bronze)/(conquista.prata - conquista.bronze)) * 100 : progresso
                            progresso = conquista.progresso >= conquista.prata ? ((conquista.progresso - conquista.prata)/(conquista.ouro - conquista.prata) ) * 100 : progresso
                            progresso = conquista.progresso >= conquista.ouro ? 100 : progresso
                            let cor = Constantes.COR_CONQUISTA_NENHUM
                            cor = conquista.progresso >= conquista.bronze ? Constantes.COR_CONQUISTA_BRONZE : cor
                            cor = conquista.progresso >= conquista.prata ? Constantes.COR_CONQUISTA_PRATA : cor
                            cor = conquista.progresso >= conquista.ouro ? Constantes.COR_CONQUISTA_OURO : cor

                            return (
                                <Tooltip title={conquista.nome + ": " + conquista.progresso}>
                                    <Box key={index} sx={{ marginInline: '5px' }}>
                                        <Controls.CircularProgressBarComIcone
                                            cor={cor}
                                            progresso={progresso}
                                            icone={icones.conquistaIcone(conquista.id, cor)}
                                        />
                                    </Box>
                                </Tooltip>

                            )
                        })}
                    </Box>
                }
            </Box>
        </Box>
    )
}