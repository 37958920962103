import * as Mascaras from "../../../../util/Mascaras";
import Controls from "../../Controls";
import { useState, useEffect } from 'react'

export default function Parcelas(props) {
    const { onChange, value, error, listaParcelas, other } = props

    return (
        <Controls.Select
            name='parcela'
            label='Parcelamento'
            value={value}
            onChange={onChange}
            options={listaParcelas}
            error={error}
            {...other}
        />
    )
}