import { Box, Typography } from "@mui/material";
import LojaWrapper from "../loja/LojaWrapper";
import HomeWeb from "./HomeWeb";
import * as Cores from "../../util/Cores";

export default function HomeWrapper(props) {
    const { setUserDetails, userDetails, isLoggedIn, alterarCarrinho, removerCarrinho, isMobile } = props

    return (
        <Box>
            <HomeWeb
                userDetails={userDetails}
                isMobile={isMobile}
            />
            {/* <Box sx={{ paddingTop: '16px'  }}>
                <Typography sx={{ background: 'white', padding: '6px'}} variant='h5' textAlign={'left'} color={Cores.COR_PRINCIPAL_ESCURO}>Loja [Em contrução]</Typography>
                <LojaWrapper
                    alterarCarrinho={alterarCarrinho}
                    removerCarrinho={removerCarrinho}
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails}
                    isHome={true}
                />
            </Box> */}
        </Box>
    )
}