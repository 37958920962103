import { Grid, Typography } from "@mui/material"
import Popup from "../../components/Popup"
import { useForm } from "../../components/UseForm"
import Controls from "../../components/controls/Controls"
import ClienteService from "../../api/services/ClienteService"
import * as Mensagens from "../../util/Mensagens"

const initialValues = {
    email: ''
}

export default function EsqueciSenhaForm(props) {
    const { openPopupEsqueci, setOpenPopupEsqueci, notificar, alterarLoad, setMensagem, setTipoMensagem } = props

    const validate = (fieldValues = values) => {
        let temp = { ...erros }
        if ('email' in fieldValues) {
            temp.email = fieldValues.email !== '' ? '' : Mensagens.CAMPO_OBRIGATORIO
            if (temp.email !== Mensagens.CAMPO_OBRIGATORIO) {
                temp.email = (/^$|.+@.+..+/).test(fieldValues.email) ? '' : Mensagens.FORMATO_EMAIL
            }
        }
        setErros({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    const {
        values,
        setValues,
        handleInputChange,
        erros,
        setErros
    } = useForm(initialValues, true, validate)

    const handleAlterarSenha = () => {
        if (validate()) {
            enviarEmailAlterarSenha()
        }
    }

    async function enviarEmailAlterarSenha() {
        alterarLoad(true)
        try {
            await ClienteService.solicitaAlterarSenha({
                userTO: {
                    username: '',
                    password: ''
                },
                id: '',
                email: values.email
            }).then(response => {
                if (response.status !== 200) {
                    notificar(Mensagens.ERRO_SERVICO, "error")
                } else {
                    notificar(response.mensagem, response.erro ? "error" : "success")
                    if (!response.erro) {
                        setOpenPopupEsqueci(false)
                        setMensagem(response.mensagem)
                        setTipoMensagem('success')
                    }
                }
            })
        } catch (e) {
           console.log(e)
        } finally {
            alterarLoad(false)
        }
    }

    return (
        <Popup
            maxWidth='sm'
            openPopup={openPopupEsqueci}
            setOpenPopup={setOpenPopupEsqueci}
            title={'Esqueci minha senha'}
        >
            <Grid container spacing={3} sx={{ marginTop: '0px' }}>
                <Grid item md={12}>
                    <Typography>Informe seu email. Enviaremos um link para alteração da sua senha no email preenchido abaixo.</Typography>
                </Grid>
                <Grid item md={12}>
                    <Controls.Input
                        name='email'
                        label='Email'
                        value={values.email}
                        error={erros.email}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item md={12}>
                    <Controls.Button
                        text='Enviar Email'
                        onClick={() => handleAlterarSenha()}
                    />
                </Grid>
            </Grid>
        </Popup>
    )
}