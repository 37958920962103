import { Box } from "@mui/material";
import Media from "react-media";
import * as Constantes from "../../../util/constantes";
import TutorialWeb from "./TutorialWeb";

export default function TutorialWrapper(props) {

    return (
        <Box>
            <Media queries={{ small: { maxWidth: Constantes.MOBILE_SCREEN_SIZE } }}>
                {matches =>
                    matches.small ? (
                        <TutorialWeb isMobile={true}/>
                    ) : (
                        <TutorialWeb isMobile={false}/>
                    )
                }
            </Media>
        </Box>
    )
}