import { Box, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import ClienteService from "../../api/services/ClienteService";
import Load from "../../components/Load";
import Notificacao from "../../components/Notificacao";
import Controls from "../../components/controls/Controls";
import * as Mensagens from "../../util/Mensagens";

export default function AtivarConta(props) {
    const { userDetails } = props
    const [showPage, setShowPage] = useState(false)
    const [showLoad, setShowLoad] = useState(false)
    const [params] = useSearchParams();
    const [ativou, setAtivou] = useState(false)
    const navigate = useNavigate();
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    })

    useEffect(() => {
        checarLink(params.get("hash"))
    }, []);

    async function checarLink(hash) {
        setShowLoad(true)
        try {
            await ClienteService.checarLink(hash).then(response => {
                setShowLoad(false)
                if (response.status !== 200) {
                    setShowPage(false)
                    setNotify({
                        isOpen: true,
                        message: Mensagens.ERRO_SERVICO,
                        type: "error",
                    })
                } else if (response.id === -1) {
                    setShowPage(false)
                    setNotify({
                        isOpen: true,
                        message: response.mensagem,
                        type: "error",
                    })
                } else {
                    setShowPage(true)
                }
            })
        } catch (e) {
            setShowPage(false)
            setShowLoad(false)
        }
    }

    async function ativarConta() {
        setShowLoad(true)
        try {
            await ClienteService.ativarConta({ link: params.get("hash"), email: params.get("email"), username: params.get("username") }).then(response => {
                setNotify({
                    isOpen: true,
                    message: response.erro ? Mensagens.CONTA_ATIVADA_ERRO : Mensagens.CONTA_ATIVADA_SUCESSO,
                    type: response.erro ? 'erro' : "success",
                })
                if (!response.erro) {
                    setAtivou(true)
                }
            })
        } catch (e) {
            setNotify({
                isOpen: true,
                message: Mensagens.ERRO_SERVICO,
                type: 'erro',
            })
        } finally {
            setShowLoad(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        ativarConta()
    }

    const navegarLogin = () => {
        navigate("/login")
    }

    return (
        <Box sx={{ marginLeft: '5%', width: '90%', background: 'white', borderRadius: '25px' }}>
            <Notificacao notify={notify} setNotify={setNotify} />
            <Load exibirLoad={showLoad} mensagemLoad='Ativando conta...' />
            {showPage ?
                <Box sx={{  marginLeft: '5%',width: '90%', paddingTop: '4%', justifyContent: 'center', paddingBottom: '19%' }}>
                    <Typography item md={12} sx={{ paddingBottom: '4%', }}>
                        {ativou ? Mensagens.OLA_FAZER_LOGIN : Mensagens.OLA_ATIVAR_CONTA(params.get("username"))}
                    </Typography>
                    <Box>
                        {ativou ?
                            <Controls.Button
                                text='Login'
                                onClick={() => navegarLogin()}
                            />
                            :
                            <Controls.Button
                                text='Ativar Conta'
                                onClick={(e) => handleSubmit(e)}
                            />
                        }
                    </Box>
                </Box>
                :
                <Typography sx={{ padding: '22%' }}>{Mensagens.LINK_INDISPONIVEL}</Typography>
            }

        </Box>
    )
}