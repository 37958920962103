import { useEffect, useState } from 'react';
import { useCommon } from "../../components/hooks/useCommon";
import CartasService from '../../api/services/CartasService';
import EstoqueService from '../../api/services/EstoqueService';
import * as Mensagens from '../../util/Mensagens';
import * as Utils from "../../util/Utils";
import * as Constantes from "../../util/constantes";
import CartasCarrossel from './CartasCarrossel';
import FiltroLoja from './FiltroLoja';
import Loja from "./Loja";
import Promocional from './Promocional';
import { Box } from '@mui/material';

export default function LojaWrapper(props) {
    const { alterarCarrinho, removerCarrinho, isLoggedIn, userDetails, isHome = false } = props
    const [listaRaridade, setListaRaridade] = useState()
    const [listaEdicao, setListaEdicao] = useState()
    const [listaNatureza, setListaNatureza] = useState()
    const [listaCartasDesejo, setListaCartasDesejo] = useState([])
    const [listaDesejoCodigo, setListaDesejoCodigo] = useState([])
    const { Common, notificar, alterarLoad, alterarMensagemLoad } = useCommon()
    const [showPage, setShowPage] = useState(false)
    const { width } = Utils.useWindowDimensions();
    const [listaVisualizacao, setListaVisualizacao] = useState([])
    const [listaCartas, setListaCartas] = useState([])
    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1)
    const [destaques, setDestaques] = useState([])
    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE

    useEffect(() => {
        consultarCombos()
        consultarDestaques()
        if (userDetails !== null && userDetails.id !== 0) {
            consultarColecaoUsuario()
        }
    }, []);

    async function consultarDestaques() {
        await EstoqueService.consultarDestaques(notificar, alterarLoad).then(response => {
            if (response === undefined) {
                setDestaques([])
            } else {
                setDestaques(response)
            }
        })
    }

    async function consultarCombos() {
        await CartasService.consultarCombos(0, alterarMensagemLoad, alterarLoad, notificar).then((response) => {
            if (response !== undefined) {
                setListaRaridade(response.listaRaridade)
                setListaEdicao(response.listaEdicao)
                setListaNatureza(response.listaNatureza)
                setShowPage(true)
            }
        })
    }

    async function consultarColecaoUsuario() {
        let filtro = {
            nome: '', natureza: '', raridade: '', edicao: '',
            jogo: '', clienteId: userDetails.id.toString(), colecaoCompleta: true, somenteEstoque: false
        }
        consultarDesejo(filtro)
    }

    async function consultarDesejo(filtro) {
        alterarMensagemLoad(Mensagens.CONSULTANDO_CARTAS)
        await CartasService.consultarCartasPorFiltro(filtro, notificar, alterarLoad).then(response => {
            let desejo = response.cartasCompletasTO.filter(x => response.listaDesejo.includes(x.cartaTO.id))
            setListaCartasDesejo(desejo)
            setListaDesejoCodigo(response.listaDesejo)
        })
    }

    async function consultarCartasFiltro(filtro) {
        alterarMensagemLoad(Mensagens.CONSULTANDO_CARTAS)
        await CartasService.consultarCartasPorFiltro(filtro, notificar, alterarLoad).then(response => {
            setListaCartas(response.cartasCompletasTO)
            atualizarListaVisualizacao(response.cartasCompletasTO)
        })
    }

    const atualizarListaVisualizacao = (lista) => {
        let tamanhoLinha = width < Constantes.MOBILE_SCREEN_SIZE ? Constantes.CARD_PER_ROW_MOBILE_LOJA : Constantes.CARD_PER_ROW_LOJA
        let aux = lista.map(x => {
            return {
                ...x, ehDesejo: verificarDesejo(lista, listaDesejoCodigo, x)
            }
        })
        let grupos = Utils.dividirArrayEmGrupos(aux, tamanhoLinha)
        if (isMobile) {
            setCount(Math.ceil(grupos.length / Constantes.CARD_PER_PAGE_MOBILE))
        } else {
            setCount(Math.ceil(grupos.length / Constantes.CARD_PER_PAGE))
        }
        setListaVisualizacao(grupos)
    }

    const verificarDesejo = (listaCartas, listaDesejo, carta) => {
        if (listaCartas !== undefined) {
            let result = listaDesejo.filter(x => { return (Number(x) === carta.cartaTO.id) })
            if (result.length > 0) {
                return true
            }
        }
        return false
    }

    return (
        <Box sx={{backdropFilter: 'blur(10px)', background: Constantes.COR_SECUNDARIA_FUNDO_OPACITY}}>
            <Common />
            <Promocional />
            <FiltroLoja
                isHome={isHome}
                isMobile={isMobile}
                width={width}
                listaRaridade={listaRaridade}
                listaEdicao={listaEdicao}
                listaNatureza={listaNatureza}
                showPage={showPage}
                consultar={consultarCartasFiltro}
                userDetails={userDetails}
            />
            <Loja
                notificar={notificar}
                listaDesejoCodigo={listaDesejoCodigo}
                alterarCarrinho={alterarCarrinho}
                isMobile={isMobile}
                userDetails={userDetails}
                isLoggedIn={isLoggedIn}
                listaCartas={listaCartas}
                page={page}
                setPage={setPage}
                count={count}
                setCount={setCount}
                listaVisualizacao={listaVisualizacao}
            />
            {destaques.length > 0 && <CartasCarrossel
                listaCartas={destaques}
                alterarCarrinho={alterarCarrinho}
                isLoggedIn={isLoggedIn}
                userDetails={userDetails}
                width={width}
                titulo={"Destaques"}
                notificar={notificar}
                isMobile={isMobile}
            />}
            {userDetails !== null && userDetails.id !==0 && listaCartasDesejo.length > 0 && <CartasCarrossel
                listaCartas={listaCartasDesejo}
                alterarCarrinho={alterarCarrinho}
                isLoggedIn={isLoggedIn}
                userDetails={userDetails}
                width={width}
                titulo={"Sua Lista de Desejo"}
                notificar={notificar}
                isMobile={isMobile}
            />}

        </Box>
    )
}