import StoreIcon from '@mui/icons-material/Store';
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { Form, useForm } from '../../components/UseForm';
import Controls from '../../components/controls/Controls';
import * as Cores from "../../util/Cores";
import * as Constantes from "../../util/constantes";

const initialValues = {
    nome: '',
    natureza: "0",
    raridade: "0",
    edicao: "0",
    jogo: "0"
}

export default function FiltroLoja(props) {
    const [mostrarFiltros, setMostrarFiltros] = useState(false)
    const { consultar, isMobile, listaNatureza, listaEdicao, listaRaridade, showPage, userDetails, width, isHome } = props

    const onClickFiltro = () => {
        setMostrarFiltros(!mostrarFiltros)
    }
    const validate = (fieldValues = values) => {
        return true
    }

    const consultarLista = () => {
        let filtro
        if (values.raridade === "0" && values.nome === "" && values.natureza === "0" && values.edicao === "0") {
            filtro = {
                nome: '', natureza: 0, raridade: 0, edicao: 0,
                jogo: 0, clienteId: userDetails.id.toString(), colecaoCompleta: true, somenteEstoque: true
            }
        } else {
            filtro = { ...values, raridade: values.raridade.toString(), natureza: values.natureza.toString(), clienteId: userDetails.id, colecaoCompleta: false, somenteEstoque: true }
        }
        consultar(filtro)
    }

    const {
        values,
        handleInputChange,
        erros,
        resetForm
    } = useForm(initialValues, true, validate)


    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            consultarLista()
        } else {
        }
    }

    return (
        <Paper sx={{ height: 'fit-content', paddingTop:"10px", paddingBottom: isMobile ? '0px' : '32px' }}>
            {!isHome && <Box sx={{ margin: '1.5%' }}>
                <Box sx={{display: 'flex' }}>
                    <StoreIcon sx={{
                        marginRight: '1%',
                        transform: 'scale(1.5)',
                        fill: Cores.COR_PRINCIPAL
                    }} />
                    <Typography color={Cores.COR_PRINCIPAL} variant="h5">Loja [Em construção]</Typography>
                </Box>
                <Divider />

            </Box>}
            <Box sx={{ padding: isMobile ? '2%' : '0px', paddingInline: isMobile ? '' : '2%' }}>
                {showPage &&
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item md={8} xs={12}>
                                <Controls.InputNomeCarta
                                    name='nome'
                                    label='Nome da Carta'
                                    value={values.nome}
                                    onChange={handleInputChange}
                                    error={erros.nome}
                                    onClickFiltro={onClickFiltro}
                                />
                            </Grid>
                            <Grid item md={4} xs={4} sx={{ display: width < Constantes.MOBILE_SCREEN_SIZE ? 'none' : 'contents', }}>
                                <Box sx={{ display: 'flex', justifyContent: 'left', paddingTop: '16px', marginLeft: '2%' }}>
                                    <Controls.Button
                                        text='Pesquisar'
                                        onClick={() => consultarLista()}
                                    />
                                    <Controls.Button
                                        sx={{ marginInline: '5%' }}
                                        text='Limpar'
                                        color='primary'
                                        variant='outlined'
                                        onClick={() => resetForm()}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        {mostrarFiltros ?
                            <Grid sx={{ marginTop: '1%' }} container spacing={2}>
                                <Grid item md={3} xs={6}>
                                    <Controls.Select
                                        name='natureza'
                                        label='Natureza'
                                        value={values.natureza}
                                        onChange={handleInputChange}
                                        options={listaNatureza}
                                        error={erros.natureza}
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <Controls.Select
                                        name='raridade'
                                        label='Raridade'
                                        value={values.raridade}
                                        onChange={handleInputChange}
                                        options={listaRaridade}
                                        error={erros.raridade}
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <Controls.Select
                                        name='edicao'
                                        label='Edição'
                                        value={values.edicao}
                                        onChange={handleInputChange}
                                        options={listaEdicao}
                                        error={erros.edicao}
                                    />
                                </Grid>
                            </Grid>
                            : null
                        }
                       
                    </Form>
                }
            </Box >
        </Paper>
    )
}