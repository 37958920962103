import FabPesquisarDeck from './FabPesquisarDeck'
import FabExcluirDeck from './FabExcluirDeck'
import FabSelecionarLista from './FabSelecionarLista'

const Fab = {
    FabPesquisarDeck, 
    FabExcluirDeck, 
    FabSelecionarLista
}

export default Fab