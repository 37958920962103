import {useState} from 'react'

export function useForm(initialValues, validateOnChange=false, validate){
    
    const [values, setValues] = useState(initialValues)
    const [erros, setErros] = useState({})
    
    function resetForm(){
        setValues(initialValues)
        setErros({})
    }
    const handleInputChangeSwitch = (e, name) => {
        setValues({
            ...values,
            [name]: !values[name]
        })
    }

    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
        if(validateOnChange)
            validate({[name]: value})
    }

    return {
        values,
        erros,
        setErros,
        setValues,
        handleInputChange,
        handleInputChangeSwitch,
        resetForm
    }

}


export function Form(props){

    const {children, ...other} = props
    return(
        <form autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}