import { useEffect, useState } from 'react';
import ClienteService from "../../api/services/ClienteService";
import Controls from './Controls';

export default function SelectEstado(props) {

    const { value, error = null, onChange, sx, ...other } = props;
    const [listaEstados, setListaEstados] = useState([{id: 0, value: ''}])

    useEffect(() => {
        ClienteService.consultarEstados().then((response) => {
            setListaEstados([{id: 0, value: ''}].concat(response))
        })
    }, [])

    return (
        <Controls.Select
            name='estado'
            label='Estado'
            value={value}
            onChange={onChange}
            options={listaEstados}
            error={error}
            sx={sx}
            {...other}
        />
    )
}

