import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import Popup from "../../../components/Popup";
import * as Cores from "../../../util/Cores";
import PesquisaDeckForm from "./PesquisaDeckForm";

export default function BarraPesquisaDeck(props) {

    const { isMobile = false, deckList, setDeckSelecionado, cardsDeck, setCardsDeck, deckSelecionado, setDeckList, userDetails, notificar, consultarCartasDeck } = props
    const [openPopup, setOpenPopup] = useState(false)

    const handleDeckChange = () => {
        setOpenPopup(true)
    }

    return (
        <Box>

            <Box sx={{ display: isMobile ? "contents" : 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}  onClick={() => handleDeckChange()}>
                    <Typography color={Cores.COR_PRINCIPAL} variant={isMobile ? "h6" : "h5"}>{deckSelecionado.nome}</Typography>
                    <IconButton
                       
                        sx={{
                            transform: 'translate(0px, -5px)',
                            '& .MuiSvgIcon-root': {
                                transform: isMobile ? 'scale(1)' : 'scale(1.2)',
                                fill: Cores.COR_PRINCIPAL,
                            }
                        }}
                    >
                        <ChangeCircleOutlinedIcon />
                    </IconButton>
                </Box>
                <Typography color={Cores.COR_PRINCIPAL} variant={isMobile ? "h6" : "h5"} sx={{ marginRight: '10%', display: "flex", justifyContent: 'center' }}>{cardsDeck.length + '/60'}</Typography>

            </Box>


            <Popup
                maxWidth={isMobile ? '10%' : '25%'}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title={"Decks"}
            >
                <PesquisaDeckForm
                    isMobile={isMobile}
                    userDetails={userDetails}
                    setDeckList={setDeckList}
                    deckList={deckList}
                    setCardsDeck={setCardsDeck}
                    setDeckSelecionado={setDeckSelecionado}
                    notificar={notificar}
                    consultarCartasDeck={consultarCartasDeck}
                    setOpenPopup={setOpenPopup}
                />
            </Popup>
        </Box>
    )
}