import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import Controls from "../../../components/controls/Controls"
import * as Cores from '../../../util/Cores'
import * as Mascaras from '../../../util/Mascaras'
import { useEffect, useState } from 'react'
import LabelDescricao from "../../../components/LabelDescricao"
import * as PagamentoService from '../../../api/services/PagamentoService'
import ModalConfirmacao from "../../../components/ModalConfirmacao"

export default function Assinatura(props) {
    const { userDetails, notificar, alterarLoad } = props
    const [assinatura, setAssinatura] = useState(0)
    const [load, setLoad] = useState(false)
    const [exibirModal, setExibirModal] = useState(false)
    const [exibirModalAss, setExibirModalAss] = useState(false)

    useEffect(() => {
        consultarAssinatura()
    }, []);

    const consultarAssinatura = async () => {
        let request = {
            clienteId: userDetails.id
        }
        await PagamentoService.consultarAssinatura(setLoad, request).then(response => {
            setAssinatura(response === '' ? null : response)
        });
    }

    const gerarAssinatura = async () => {
        window.open("https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c93808490edce280190f66e1330022b", '_blank').focus()
        setExibirModalAss(false)
    }

    const cancelarAssinatura = async () => {
        let request = {
            mpId: "2c93808490edce280190f66e1330022b",//assinatura.tipoAssinatura.preapprovalPlanId,
            payerId: "1846810297",//assinatura.payerId,
            status: 'cancelled',
            subscriptionId: '7e69a825c71248b882dcf1fabe1b01e1' //assinatura.subscriptionId
        }
        await PagamentoService.cancelarAssinatura(setLoad, request).then(response => {
            if (response !== null) {
                setAssinatura(null)
            }
        });
        setExibirModal(false)
    }

    return (
        <Box sx={{ textAlign: 'left' }}>
            <Typography variant='h5' color={Cores.COR_PRINCIPAL}>Assinatura</Typography>
            <Box style={{ borderBottom: '2px solid ' + Cores.COR_PRINCIPAL, width: '100%', marginBottom: '20px' }}></Box>
            {load && <CircularProgress />}
            {!load && <Box>
                <Typography variant='h6'>Apoie este projeto independente! Assine e não tenha mais limites para tamanhos de coleções e decks!</Typography>
                {(assinatura === null || assinatura === 0 || assinatura === undefined) &&
                    <Box>
                        Você não possui assinatura ativa. Assine o clube dos sonhos!
                        <Box sx={{ textAlign: 'left', marginTop: '10px' }}>
                            <Controls.Button
                                text='Assinar!'
                                onClick={() => setExibirModalAss(true)}
                            />
                        </Box>
                    </Box>
                }
                {assinatura !== undefined && assinatura !== 0 && assinatura !== null && assinatura.tipoAssinatura !== null && assinatura.tipoAssinatura !== undefined &&
                    <Box>
                        <Box sx={{ textAlign: 'left', marginTop: '10px' }}>
                            <LabelDescricao
                                label={'Assinatura:'}
                                descricao={assinatura.tipoAssinatura.nome}
                            />
                            <LabelDescricao
                                label={'Valor:'}
                                descricao={assinatura.tipoAssinatura.valor}
                            />
                            <LabelDescricao
                                label={'Data cadastro:'}
                                descricao={Mascaras.formatarTimestamp(assinatura.dataAssinatura)}
                            />
                            <LabelDescricao
                                label={'Está ativa:'}
                                descricao={assinatura.ativa ? 'Sim' : 'Não'}
                            />
                            <Box sx={{ marginTop: '10px' }} />
                            <Controls.Button
                                text='Cancelar'
                                onClick={() => setExibirModal(true)}
                            />
                        </Box>
                    </Box>
                }
            </Box>}
            <ModalConfirmacao
                exibirModal={exibirModal}
                setExibirModal={setExibirModal}
                handleConfirmar={() => cancelarAssinatura()}
                texto='Deseja cancelar sua assinatura?'
            />
            <ModalConfirmacao
                exibirModal={exibirModalAss}
                setExibirModal={setExibirModalAss}
                handleConfirmar={() => gerarAssinatura()}
                texto='Você será redirecionado para a página de pagamento. Deseja continuar?'
            />
        </Box>
    )

}