import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { Box, Card, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import Popup from '../../components/Popup';
import * as Cores from "../../util/Cores";
import * as Mascaras from '../../util/Mascaras';
import * as Constantes from '../../util/constantes';
import ChipEstoque from './ChipEstoque';
import VisualizarCartaLoja from './VisualizarCartaLoja';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

export default function CardCartaLoja(props) {
    const { notificar, carta, width, alterarCarrinho, isMobile } = props

    const [openPopup, setOpenPopup] = useState(false)

    const openInPopup = () => {
        setOpenPopup(true)
    }

    const adicionarCompra = () => {
        let result = alterarCarrinho(0, 1, carta, carta.cartaTO.desconto)
        if (result) {
            notificar('1 ' + carta.cartaTO.nome + ' adicionado(a) ao carrinho.', 'success')
        } else {
            notificar('Limite da carta em estoque atingido.', 'error')
        }
    }


    return (
        <Box sx={{ justifyContent: 'center' }}>
            <Card sx={{ marginBottom: '10px', marginInline: '1px', paddingInline: '10px', paddingTop: '5px' }}>
                <Box>
                    <img alt="" onClick={() => { openInPopup() }} className="main-card"
                        width='120' data-src={carta.cartaTO.imgUrl} src={carta.cartaTO.imgUrl}
                        style={{ cursor: 'pointer' }}
                    />
                    <Typography variant='body2' fontWeight={700} color={Cores.COR_PRINCIPAL}>{Mascaras.tamTexto(carta.cartaTO.nome, 14)}</Typography>
                    <Typography variant='caption' color={Constantes.COR_SECUNDARIA_ROSA}>{Mascaras.tamTexto(carta.edicaoDescricao, 16)}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='body2' fontWeight={700} color={carta.cartaTO.desconto > 0 ? Cores.COR_PRINCIPAL : 'green'}>
                            R${Mascaras.dinheiro(carta.cartaTO.desconto > 0 ?
                                carta.cartaTO.valor - carta.cartaTO.valor * carta.cartaTO.desconto / 100 + ''
                                :
                                carta.cartaTO.valor + '')  }
                        </Typography>
                        {carta.cartaTO.desconto > 0 &&
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <NewReleasesIcon fontSize='small' sx={{ fill: Cores.COR_PRINCIPAL }} />
                                <Typography variant='body2' fontWeight={700} color='primary'>
                                    {carta.cartaTO.desconto}%
                                </Typography>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ChipEstoque
                        adicionarCompra={adicionarCompra}
                        estoque={carta.cartaTO.estoque}
                    />
                    {carta.cartaTO.estoque > 0 &&
                        <Tooltip title="Adicionar ao carrinho">
                            <Box sx={{
                                '& .MuiSvgIcon-root': {
                                    '&:hover': {
                                        cursor: 'pointer',
                                        fill: '#F06292',
                                    }
                                }
                            }}>
                                <AddShoppingCartIcon onClick={(e) => adicionarCompra(e)} />
                            </Box>
                        </Tooltip>
                    }


                </Box>
            </Card>

            <Popup
                width={isMobile ? '100%' : '30%'}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title={carta.cartaTO.nome}
                background={'white'}
            >
                <VisualizarCartaLoja
                    isMobile={isMobile}
                    width={width}
                    carta={carta}
                    adicionarCompra={adicionarCompra}
                />
            </Popup>
        </Box>
    )
}