
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Typography } from "@mui/material";
import * as Mascaras from "../../../util/Mascaras";
import * as Cores from "../../../util/Cores";

export default function CardItemPedido(props) {
    const { item, removerCarrinho, alterarCarrinho, index, notificar } = props

    const adicionarCompra = (i, quant, carta) => {
        let result = alterarCarrinho(i, quant, carta, item.desconto)
        if (!result) {
            notificar('Limite da carta em estoque atingido.', 'error')
        }
    }

    return (
        <Box key={index} style={{ marginBottom: '10px', width: '100%', display: 'flex', justifyContent: 'space-between', background: 'white', borderRadius: '25px', padding: '10px' }}>
            <Box>
                <Box>
                    <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                        {Mascaras.tamTexto(item.nome)}
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '12px' }}>
                        <RemoveCircleIcon sx={{ fill: Cores.COR_PRINCIPAL, cursor: 'pointer' }} onClick={() => adicionarCompra(index, -1, null)} />
                        <Typography variant="body1" sx={{ color: Cores.COR_PRINCIPAL, transform: 'translateY(1px)' }}>
                            <b>{item.quantidade}</b>
                        </Typography>
                        <AddCircleIcon
                            sx={{ fill: item.quantidade === item.estoque ? 'grey' : Cores.COR_PRINCIPAL, cursor: 'pointer' }}
                            onClick={() => adicionarCompra(index, 1, null)} />
                    </Box>

                </Box>
                <img src={item.imagem} alt="image" height='150px' />
                <Typography variant="body2">
                    Valor unitário: <b>R$ {item.valor.toFixed(2)}</b>
                </Typography>

            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Box sx={{ width: '100%', textAlign: 'end', display: 'flex', marginTop: '12px', justifyContent: 'space-between' }}>
                    <Typography fontSize={20} variant="body1" sx={{ transform: 'translateY(7px)', color: 'green' }}>
                        <b>R${(item.quantidade * item.valor).toFixed(2)}</b>
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }} onClick={() => removerCarrinho(index)}>
                    <Typography sx={{transform: 'translateY(7px)'}} color={Cores.COR_PRINCIPAL} fontWeight={700}>Remover</Typography>
                    <DeleteIcon fontSize='large' sx={{ cursor: 'pointer', fill: Cores.COR_PRINCIPAL }} />
                </Box>
            </Box>
        </Box>
    )
}