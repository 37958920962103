import axios from "axios";
import * as Utils from "../util/Utils"

const CONSULTAR_CLIENTE = 'usuario/consultar'
const CADASTRAR_CLIENTE = 'usuario/cadastrar'
const ALTERAR_SENHA = 'usuario/alterarSenha'
const SOLICITA_ALTERAR_SENHA = 'usuario/solicitaAlterarSenha'
const DELETAR_CLIENTE = 'usuario/deletar'
const CHECAR_LINK = 'usuario/checarLink'
const LOGIN = 'usuario/login'
const ATIVA_CONTA = 'usuario/ativarConta'
const ENDERECO_EXCLUIR = 'usuario/endereco/excluir/'
const ENDERECO_SALVAR = 'usuario/salvar/endereco'
const ENDERECO_CONSULTAR_TODOS_CLIENTE = "usuario/consultar/endereco/cliente"
const ESTADOS_CONSULTAR = "dominio/estado/consultar"

const client = axios.create({
    baseURL: process.env.REACT_APP_AMBIENTE
});

export async function deleteEndereco(id) {
    return client.delete(ENDERECO_EXCLUIR + id, Utils.getAuth())
}

export async function getCliente(codigo) {
    return client.get(CONSULTAR_CLIENTE + '?id=' + codigo, Utils.getAuth())
}

export async function postCadastrarCliente(cadastro) {
    return client.post(CADASTRAR_CLIENTE, cadastro)
}

export async function postDeletarCliente(id) {
    return client.post(DELETAR_CLIENTE, id, Utils.getAuth())
}

export async function postAlterarSenha(request){
    return client.post(ALTERAR_SENHA, request)
}

export async function postSolicitaAlterarSenha(request){
    return client.post(SOLICITA_ALTERAR_SENHA, request)
}

export async function postAtivarConta(request){
    return client.post(ATIVA_CONTA, request)
}

export async function postLogin(credentials) {
    return client.post(LOGIN, credentials)
}

export async function getChecarLink(request){
    return client.get(CHECAR_LINK + '?hash=' + request)
}

export async function postSalvarEndereco(request){
    return client.post(ENDERECO_SALVAR, request, Utils.getAuth())
}

export async function getEnderecoCliente(request){
    return client.get(ENDERECO_CONSULTAR_TODOS_CLIENTE + '?clienteId=' + request, Utils.getAuth())
}

export async function getEstados(){
    return client.get(ESTADOS_CONSULTAR)
}
