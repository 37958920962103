import EmailIcon from '@mui/icons-material/EmailOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Cores from "../../util/Cores";
import grass from '../../img/grass.png';
import logoImg from '../../img/logoVerticalBranca.png';

export default function Footer(props) {
    const {userDetails} = props
    const navigate = useNavigate();
    const handleClickRedireciona = (e, param) => {
        e.stopPropagation()
        e.preventDefault()
        navigate(param)
    }

    return (
        <Box sx={{ display: 'flex', background: Cores.COR_PRINCIPAL, marginTop: '20px', padding: '1%', overflowY: 'hidden' }}>
            <img src={logoImg} style={{ marginLeft: '2%' }} alt="Logo" height='110px' overflow='hidden' />
            <Box sx={{ paddingInline: '10%', textAlign: 'left' }}>
                <Typography color='white' fontWeight={700}>
                    Páginas
                </Typography>
                {userDetails !== null && userDetails.id !== 0 &&
                    <Typography color='white'
                        onClick={(e) => handleClickRedireciona(e, '/dashboard')}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    >
                        Dashboard
                    </Typography>
                }
                {userDetails !== null && userDetails.id !== 0 &&
                    <Typography color='white'
                        onClick={(e) => handleClickRedireciona(e, '/perfil')}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    >
                        Perfil
                    </Typography>
                }
                <Typography color='white'
                    onClick={(e) => handleClickRedireciona(e, '/cartas')}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                >
                    Cartas
                </Typography>
                <Typography color='white'
                    onClick={(e) => handleClickRedireciona(e, '/sobre')}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                >
                    Sobre
                </Typography>
                <Typography color='white'
                    onClick={(e) => handleClickRedireciona(e, '/tutorial')}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                >
                    Tutorial
                </Typography>
            </Box>
            <Box sx={{}}>
                <Typography color='white' textAlign={'left'} fontWeight={700}>
                    Contato
                </Typography>
                <Typography color='white' component='div'>
                    <Box sx={{ display: 'flex' }}>
                        <InstagramIcon />
                        <Typography sx={{ transform: 'translate(10px, 6%)' }}>@Summonercat</Typography>
                    </Box>
                </Typography>
                <Typography color='white' component='div'>
                    <Box sx={{ display: 'flex' }}>
                        <EmailIcon />
                        <Typography sx={{ transform: 'translate(10px, 6%)' }}>summonercat.contato@gmail.com</Typography>
                    </Box>
                </Typography>
            </Box>
            <Box textAlign='right' >
            </Box   >
            <img src={grass} alt="grass" width={'35%'}
                style={{ position: 'absolute', zIndex: 85, bottom: 0, right: 0, transform: 'scaleX(1)', pointerEvents: 'none' }} />
        </Box>
    )
}