
import { Box, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import PedidoService from "../../api/services/PedidoService";
import PagamentoPedido from '../checkout/pagamento-pedido/PagamentoPedido';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCommon } from '../../components/hooks/useCommon';
import * as Cores from '../../util/Cores'
import * as Constantes from '../../util/constantes'
import { useNavigate } from "react-router-dom";

export default function PedidoPopup(props) {
    const [pedido, setPedido] = useState(null)
    const { record, isMobile, handleCancelar } = props
    const { notificar, alterarLoad, Common } = useCommon()
    const navigate = useNavigate();

    useEffect(() => {
        consultarPedido()
    }, []);

    async function consultarPedido() {
        await PedidoService.consultarPedido(alterarLoad, notificar, record.id, 0).then((response) => {
            setPedido(response)
        })
    }

    const handleCheckout = () => {
        navigate("/" + Constantes.PAGE_CHECKOUT + "?pedido=" + record.numero)
    }

    return (
        <Box>
            {pedido !== null &&
                <Box>
                    <Common />
                    <PagamentoPedido
                        popup={true}
                        pedido={pedido}
                    />
                    {record.statusId === 1 &&
                        <IconButton onClick={() => handleCheckout()} >
                            <Box sx={{ background: Cores.COR_PRINCIPAL, display: 'flex', padding: '6px', borderRadius: '25px', alignItems: 'center' }}>
                                <DeleteIcon fontSize="large" sx={{ background: Cores.COR_PRINCIPAL, color: 'white', borderRadius: '25px', padding: '5px' }} />
                                <Typography sx={{ color: 'white' }}>Efetuar pagamento</Typography>
                            </Box>
                        </IconButton>
                    }

                    {record.statusId !== 6 && 
                        <IconButton onClick={() => handleCancelar(record)} >
                            <Box sx={{ background: 'red', display: 'flex', padding: '6px', borderRadius: '25px', alignItems: 'center' }}>
                                <DeleteIcon fontSize="large" sx={{ background: 'red', color: 'white', borderRadius: '25px', padding: '5px' }} />
                                <Typography sx={{ color: 'white' }}>Cancelar pedido</Typography>
                            </Box>
                        </IconButton>
                    }
                </Box>
            }
        </Box>
    )
}