import { Box, Typography } from "@mui/material";
import * as Cores from "../../../util/Cores";
import * as icones from '../../../util/icones';

export default function CardEndereco(props) {
    const { endereco, selecionarEndereco, selecionado, isMobile } = props
    return (
        <Box sx={{
            borderRadius: '10px',  width: isMobile ? 'inherit' : '150px', minHeight: '50px',
            background: 'white', marginInline: '8px', cursor: 'pointer', marginBottom: '5px', justifyContent: 'center', display: 'flex'
        }}
            onClick={() => selecionarEndereco(endereco)}>
            <Box sx={{  paddingInline: '10px',marginLeft: isMobile ? '0%' :'6%', display: 'flex', transform: endereco.principal? 'translateY(24%)' : isMobile? 'translateY(27%)' : 'translateY(27%)' }}>
                {icones.enderecoCasaPredio(selecionado)}
                <Box sx={{marginLeft: '5px'}}>
                    <Typography sx={{color: selecionado && Cores.COR_PRINCIPAL}}>{endereco.apelido}</Typography>
                    {endereco.principal &&
                        <Typography sx={{color: selecionado && Cores.COR_PRINCIPAL}} variant='caption'>Principal</Typography>
                    }
                </Box>
            </Box>
        </Box>
    )
}