import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { Box, CircularProgress, Grid, Pagination, Stack, Typography } from "@mui/material";
import { useState } from 'react';
import BotaoLabel from "../../../components/BotaoLabel";
import Carta from "../../../components/Carta";
import * as Constantes from "../../../util/constantes";
import * as Cores from "../../../util/Cores";
import BarraPesquisaCarta from './BarraPesquisaCarta';

export default function CartasHash(props) {
    const { listaVisualizacao,
        alterarCarrinho,
        isDeck,
        nome,
        nomeLista,
        tipoListaId,
        consultarOpcoes,
        page,
        userDetails,
        isLoggedIn,
        width,
        handleChangePagination,
        count,
        isMobile, pesquisou, values, consultarCartaDeClienteInicio,
        jogo } = props

    const [loadCartas, setLoadCartas] = useState(false)

    const paginationLocal = async (e, v) => {
        setLoadCartas(true)
        await handleChangePagination(e, v).finally(() => {
            setLoadCartas(false)
        })
    }

    return (
        <Box>
            <Box sx={{ display: pesquisou || nome !== "" && !isDeck ? 'block' : 'none', background: 'white' }}>
                <Box sx={{ justifyContent: 'center' }}>
                    <Typography sx={{ paddingTop: '1%', color: Cores.COR_PRINCIPAL }} variant="h6">Cartas de <b>{nome}</b></Typography>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-around"
                        sx={{ display: 'flex', minHeight: '10vh', width: isMobile ? '100%' : '50%', marginLeft: isMobile ? '0%' : '25%' }}
                    >
                        <BotaoLabel isMobile={isMobile} tipo={Constantes.TIPO_LISTA_COLECAO} consultar={consultarOpcoes} icone={<ImportContactsIcon />}
                            ativo={tipoListaId === Constantes.TIPO_LISTA_COLECAO} cor={Cores.COR_PRINCIPAL} />
                        <BotaoLabel isMobile={isMobile} tipo={Constantes.TIPO_LISTA_DESEJO} consultar={consultarOpcoes} icone={<FavoriteIcon />}
                            ativo={tipoListaId === Constantes.TIPO_LISTA_DESEJO} cor={Constantes.COR_SECUNDARIA_ROSA} />
                        <BotaoLabel isMobile={isMobile} tipo={Constantes.TIPO_LISTA_TROCA} consultar={consultarOpcoes} icone={<ChangeCircleIcon />}
                            ativo={tipoListaId === Constantes.TIPO_LISTA_TROCA} cor={Constantes.COR_SECUNDARIA_AZUL} />
                    </Grid>

                </Box>
            </Box>

            <Box>
                {(pesquisou) &&
                    <Box sx={{ background: 'white' }}>
                        <Typography sx={{ paddingTop: '0.5%', marginBottom: '0.5%', color: Cores.COR_PRINCIPAL }} variant="body1" >Pesquisar em <b>{nomeLista}</b></Typography>
                        <Box sx={{ marginLeft: isMobile ? '0%' : '15%', width: isMobile ? '100%' : '70%', marginBottom: '2%' }}>
                            <BarraPesquisaCarta
                                isMobile={isMobile}
                                jogo={jogo}
                                valuesPai={values}
                                consultarCartaDeClienteInicio={consultarCartaDeClienteInicio}
                            />
                        </Box>
                    </Box>}
                {listaVisualizacao.length > 0 &&
                    <Box sx={{
                        marginTop: '1%',
                        marginBottom: '1%',
                        '& .MuiPagination-ul': {
                            justifyContent: 'center'
                        }
                    }}>
                        <Pagination count={count} color="secondary" page={page} onChange={(e, v) => paginationLocal(e, v)} />
                    </Box>
                }

                {loadCartas && <CircularProgress />}
                {listaVisualizacao.length === 0 ?
                    <Typography>{'Você ainda não adicionou cartas aqui para ' + (jogo === 2 ? 'Digimon' : 'Pokémon') + '.'}</Typography>
                    :
                    <Box>
                        {listaVisualizacao.map((lista, i) => {
                            return (
                                <Stack direction='row' key={i} sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }} spacing={4}>
                                    {lista.map((item, index) => {
                                        return (<Carta
                                            alterarCarrinho={alterarCarrinho}
                                            cartaInfo={item}
                                            pagina='loja'
                                            key={'loja' + index}
                                            isLoggedIn={isLoggedIn}
                                            userDetails={userDetails}
                                            width={width}
                                            atualizarCarta={() => null}

                                        />)
                                    })}
                                </Stack>
                            )
                        })}
                    </Box>
                }



            </Box>
            {listaVisualizacao.length > 0 &&
                <Box sx={{
                    marginTop: '1%',
                    marginBottom: '7%',
                    '& .MuiPagination-ul': {
                        justifyContent: 'center'
                    }
                }}>
                    <Pagination count={count} color="secondary" page={page} onChange={(e, v) => paginationLocal(e, v)} />
                </Box>
            }
        </Box>
    )
}