import * as Mascaras from "../../../../util/Mascaras";
import Controls from "../../Controls";


export default function NumeroCartao(props) {
    const {onChange, value, error, id=''} = props

    return (
        <Controls.Input
            name='numeroCartao'
            label='Numero do Cartão'
            onChange={onChange}
            value={Mascaras.cartao(value)}
            error={error}
            id={id}
        />
    )
}