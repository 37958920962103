import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState } from 'react';
import ClienteService from "../../api/services/ClienteService";
import Load from "../../components/Load";
import ModalConfirmacao from "../../components/ModalConfirmacao";
import Notificacao from "../../components/Notificacao";
import { Form, useForm } from "../../components/UseForm";
import Controls from "../../components/controls/Controls";
import * as Cores from "../../util/Cores";
import * as Mensagens from "../../util/Mensagens";
import Perfil from "./Perfil";
import { useCommon } from "../../components/hooks/useCommon";
import Assinatura from "./assinatura/Assinatura";

const initialValues = {
    username: '',
    emailAlterarSenha: '',
    senha: ''

}

export default function GerenciarConta(props) {
    const { userDetails, setUserDetails } = props
    const [exibirModal, setExibirModal] = useState(false)
    const { notificar, Common, alterarLoad } = useCommon()

    const validate = (fieldValues = values) => {

    }

    const {
        values,
        setValues,
        handleInputChange,
        handleInputChangeSwitch,
        erros,
        resetForm,
        setErros
    } = useForm(initialValues, true, validate)

    async function handleExcluir() {
        await ClienteService.deletarCliente(userDetails.id, notificar, alterarLoad, setUserDetails, setExibirModal)
    }

    const handleClickExcluir = () => {
        setExibirModal(true)
    }

    async function handleAlterarSenha() {
        alterarLoad(true)
        try {
            await ClienteService.solicitaAlterarSenha({
                userTO: {
                    username: userDetails.username,
                    password: ''
                },
                id: userDetails.id,
                email: values.emailAlterarSenha
            }).then(response => {
                if (response.status !== 200) {
                    notificar(Mensagens.ERRO_SERVICO, "error")
                } else {
                    notificar(response.mensagem, response.erro ? "error" : "success")
                }
            })
        } catch (e) {

        } finally {
            alterarLoad(false)
        }
    }

    return (
        <Form>
            <Common />
            <Stack spacing={4}>
                <Assinatura
                    alterarLoad={alterarLoad}
                    notificar={notificar}
                    userDetails={userDetails}
                />
                <Perfil
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    alterarLoad={alterarLoad}
                    notificar={notificar}
                />
                <Box sx={{ textAlign: 'left' }}>
                    <Typography variant='h5' color={Cores.COR_PRINCIPAL}>Alterar Senha</Typography>
                    <Box style={{ borderBottom: '2px solid ' + Cores.COR_PRINCIPAL, width: '100%', marginBottom: '20px' }}></Box>
                    <Grid container spacing={3}>
                        <Grid item md={4} sm={6}>
                            <Controls.Input
                                name='emailAlterarSenha'
                                label='Email'
                                value={values.emailAlterarSenha}
                                onChange={handleInputChange}
                                error={erros.emailAlterarSenha}
                            />
                        </Grid>
                        <Grid item md={4} sm={6}>
                            <Controls.Button
                                text='Alterar'
                                onClick={handleAlterarSenha}
                            />
                        </Grid>
                    </Grid>


                </Box>
                <Box sx={{ textAlign: 'left', marginTop: '30px' }}>
                    <Typography variant='h5' color={Cores.COR_PRINCIPAL}>Excluir Conta</Typography>
                    <Box style={{ borderBottom: '2px solid ' + Cores.COR_PRINCIPAL, width: '100%', marginBottom: '20px' }}></Box>
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={4}>
                            <Controls.Input
                                name='senha'
                                label='Senha'
                                value={values.senha}
                                onChange={handleInputChange}
                                error={erros.senha}
                            />
                        </Grid>
                        <Grid item md={2} sm={4}>
                            <Controls.Button
                                text='Excluir'
                                onClick={handleClickExcluir}
                                color='error'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <ModalConfirmacao
                exibirModal={exibirModal}
                setExibirModal={setExibirModal}
                handleConfirmar={handleExcluir}
                texto='Deseja mesmo excluir sua conta?'
            />
        </Form>
    )
}