import { Dashboard } from "@mui/icons-material";
import AboutUsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import SchoolIcon from '@mui/icons-material/School';
import StoreIcon from '@mui/icons-material/Store';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Drawer, IconButton, MenuItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import * as Constantes from '../../util/constantes';
import * as Cores from "../../util/Cores";

export default function DrawerMenu(props) {

    const { isLoggedIn, setExibirModal, showMenu, handleCloseMenu, setShowMenu, isMobile } = props

    const toggleDrawer = (e, open) => {
        e.preventDefault()
        e.stopPropagation()
        setShowMenu(open)
    }

    return (
        <Drawer
            anchor={'left'}
            open={showMenu}
            PaperProps={{
                sx: { width: isMobile ? "45%" : '11%', borderTopRightRadius: '20px', borderBottomRightRadius: '20px', background: Constantes.COR_SECUNDARIA_FUNDO },
            }}
            onClose={e => { toggleDrawer(e, false) }}
        >
            <Link to='/' style={{ textDecoration: 'none', color: Cores.COR_PRINCIPAL }}>
                <MenuItem onClick={handleCloseMenu}>
                    <Box sx={{ display: 'flex' }}>
                        <IconButton color="primary" component="label">
                            <HomeIcon fontSize='large' />
                        </IconButton>
                        <Typography sx={{ fontWeight: 700, transform: 'translate(0px, 25%)' }} >Home</Typography>
                    </Box>
                </MenuItem>
            </Link>
            <Link to='/dashboard' style={{ textDecoration: 'none', color: Cores.COR_PRINCIPAL }}>
                <MenuItem onClick={handleCloseMenu} sx={{ display: isLoggedIn ? 'flex' : 'none' }}>
                    <Box sx={{ display: 'flex' }}>
                        <IconButton color="primary" component="label">
                            <Dashboard fontSize='large' />
                        </IconButton>
                        <Typography sx={{ fontWeight: 700, transform: 'translate(0px, 25%)' }} >Dashboard</Typography>
                    </Box>
                </MenuItem>
            </Link>
            {/* <Link to='/loja' style={{ textDecoration: 'none', color: Cores.COR_PRINCIPAL }}>
                <MenuItem onClick={handleCloseMenu}>
                    <Box sx={{ display: 'flex' }}>
                        <IconButton color="primary" component="label">
                            <StorefrontIcon fontSize='large' />
                        </IconButton>
                        <Typography sx={{ fontWeight: 700, transform: 'translate(0px, 25%)' }} >Loja</Typography>
                    </Box>
                </MenuItem>
            </Link> */}
            <Link to='/cartas' style={{ textDecoration: 'none', color: Cores.COR_PRINCIPAL }}>
                <MenuItem onClick={handleCloseMenu}>
                    <Box sx={{ display: 'flex' }}>
                        <IconButton color="primary" component="label" >
                            <StoreIcon fontSize='large' />
                        </IconButton>
                        <Typography sx={{ fontWeight: 700, transform: 'translate(0px, 33%)' }}>Cartas</Typography>
                    </Box>
                </MenuItem>
            </Link>

            <Link to='/sobre' style={{ textDecoration: 'none', color: Cores.COR_PRINCIPAL }}>
                <MenuItem onClick={handleCloseMenu}>
                    <Box sx={{ display: 'flex' }}>
                        <IconButton color="primary" component="label">
                            <AboutUsIcon fontSize='large' />
                        </IconButton>
                        <Typography sx={{ fontWeight: 700, transform: 'translate(0px, 33%)' }}>Sobre</Typography>
                    </Box>
                </MenuItem>
            </Link>

            <Link to='/tutorial' style={{ textDecoration: 'none', color: Cores.COR_PRINCIPAL }}>
                <MenuItem onClick={handleCloseMenu}>
                    <Box sx={{ display: 'flex' }}>
                        <IconButton color="primary" component="label">
                            <SchoolIcon fontSize='large' />
                        </IconButton>
                        <Typography sx={{ fontWeight: 700, transform: 'translate(0px, 25%)' }}>Serviços</Typography>
                    </Box>
                </MenuItem>
            </Link>

            <Link to='/login' style={{ textDecoration: 'none', color: Cores.COR_PRINCIPAL }}>
                <MenuItem onClick={handleCloseMenu} sx={{ display: isLoggedIn ? 'none' : 'flex' }}>
                    <Box sx={{ display: 'flex', color: Cores.COR_PRINCIPAL }}>
                        <IconButton color="primary" component="label">
                            <LoginIcon fontSize='large' />
                        </IconButton>
                        <Typography sx={{ fontWeight: 700, transform: 'translate(0px, 25%)' }} >Login</Typography>
                    </Box>
                </MenuItem>
            </Link>
            <MenuItem sx={{ display: isLoggedIn ? 'flex' : 'none' }} onClick={() => setExibirModal(true)}>
                <Box sx={{ display: 'flex', color: Cores.COR_PRINCIPAL }}>
                    <IconButton color="primary" component="label" >
                        <LogoutIcon fontSize='large' />
                    </IconButton>
                    <Typography sx={{ fontWeight: 700, transform: 'translate(0px, 25%)' }} >Logout</Typography>
                </Box>
            </MenuItem>
        </Drawer>
    )
}