import { Box } from "@mui/material";
import * as Constantes from "../../../util/constantes";
import ListaCartaDeck from "./ListaCartaDeck";

export default function AreaCartasDeck(props) {
    const { cardsDeck, setCardsDeck, adicionarCarta, isLoggedIn, userDetails } = props

    const removerCarta = (e, index) => {
        e.preventDefault()
        setCardsDeck(cardsDeck.filter((carta, i) => i !== index))
    }

    return (
        <Box>
            < ListaCartaDeck
                cardsDeck={cardsDeck}
                setCardsDeck={setCardsDeck}
                isLoggedIn={isLoggedIn}
                adicionarCarta={adicionarCarta}
                removerCarta={removerCarta}
                userDetails={userDetails}
                titulo={'Pokémon'}
                tipo={""}
            />
            < ListaCartaDeck
                cardsDeck={cardsDeck}
                setCardsDeck={setCardsDeck}
                isLoggedIn={isLoggedIn}
                adicionarCarta={adicionarCarta}
                removerCarta={removerCarta}
                userDetails={userDetails}
                titulo={'Treinador'}
                tipo={Constantes.TIPO_TREINADOR}
            />
            < ListaCartaDeck
                cardsDeck={cardsDeck}
                isLoggedIn={isLoggedIn}
                setCardsDeck={setCardsDeck}
                adicionarCarta={adicionarCarta}
                removerCarta={removerCarta}
                userDetails={userDetails}
                titulo={'Energia'}
                tipo={Constantes.TIPO_ENERGIA}
            />
        </Box >
    )
}