import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import CartasService from "../../api/services/CartasService";
import { useCommon } from "../../components/hooks/useCommon";
import * as Utils from "../../util/Utils";
import * as Constantes from "../../util/constantes";
import BarraLateral from "./BarraLateral";
import Filtros from "./Filtros";
import ListaCards from "./ListaCards";
import VerColecao from "./VerColecao";
import Controls from "../../components/controls/Controls";
import { useForm } from "../../components/UseForm";

const initialValues = {
    comPromo: false
}
export default function Dashboard(props) {
    const { alterarLoad, Common, notificar } = useCommon()
    const { userDetails } = props

    const [dadosEdicao, setDadosEdicao] = useState({ edicaoTO: { nome: '', tamanho: 0, edicao_id: 0 }, edicaoDadosColecaoTO: { quantidade: 0, valor: 0 } })
    const [dashboardLateral, setDashboardLateral] = useState([{ sum: 0.0, count: 0, tipo_lista: 0 }])
    const [colecao, setColecao] = useState({ cartasTO: [], codigoCartasColecao: [] })
    const [conquistas, setConquistas] = useState([])
    const { height, width } = Utils.useWindowDimensions();
    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE

    useEffect(() => {
        let requestDashboard = {
            clienteId: userDetails.id,
            jogo: 0,
            edicao: dadosEdicao.edicaoTO.edicao_id,
            carta: 0
        }
        let requestConquista = {
            clienteId: userDetails.id,
            jogoId: 1
        }
        CartasService.consultarDashboardConquistas(requestConquista).then((response) => {
            setConquistas(response)
        })

        CartasService.consultarDashboardSimplesCliente(alterarLoad, notificar, requestDashboard).then((response) => {
            if (response !== undefined && response.listaDados !== undefined) setDashboardLateral(response.listaDados)
        })
    }, [])

    async function consultarEdicaoDashboard() {
        let request = {
            edicaoId: dadosEdicao.edicaoTO.edicao_id,
            clienteId: userDetails.id
        }
        CartasService.consultarEdicaoDashboard(request, alterarLoad).then((response) => {
            setColecao({ cartasTO: response.cartasTO, codigoCartasColecao: response.codigoCartasColecao, nomeEdicao: dadosEdicao.edicaoTO.nome })
        })
    }


    const atualizarCarta = (carta, acao, tipoAlteracao) => {
        if (tipoAlteracao === Constantes.TIPO_LISTA_COLECAO) {
            setColecao({ ...colecao, codigoCartasColecao: colecao.codigoCartasColecao.concat([carta.id]) })
        }

    }

    const handleCheckbox = (e) => {
        setValues({ ...values, comPromo: e.target.value })
    }

    const {
        values,
        setValues,
        erros,
    } = useForm(initialValues, true, () => { return true })



    return (
        <Box sx={{ padding: isMobile ? '1px' : '25px', backdropFilter: 'blur(10px)', background: Constantes.COR_SECUNDARIA_FUNDO_OPACITY }}>
            <Common />
            {isMobile ?
                <Box>
                    <Box sx={{ height: '100%' }}>
                        <Box sx={{ padding: '10px', margin: '5px', borderRadius: '10px', background: 'white' }}>
                            <Filtros
                                isMobile={isMobile}
                                alterarLoad={alterarLoad}
                                notificar={notificar}
                                setDadosEdicao={setDadosEdicao}
                                userDetails={userDetails}
                            />
                            <Box>
                                <Controls.Checkbox
                                    name='compromo'
                                    label='Incluir cartas Promo'
                                    onChange={(e) => { handleCheckbox(e) }}
                                    value={values.comPromo}
                                    error={erros.comPromo}
                                />
                            </Box>
                            <ListaCards
                                dadosEdicao={dadosEdicao}
                                comPromo={values.comPromo}
                            />

                        </Box>
                        <Box sx={{ background: 'white', margin: '5px', borderRadius: '10px' }}>
                            <BarraLateral
                                dashboardLateral={dashboardLateral}
                                conquistas={conquistas}
                            />
                        </Box>
                    </Box>
                    {dadosEdicao.edicaoTO !== null && dadosEdicao.edicaoTO.edicao_id !== 0 && <Box sx={{ marginInline: '5px', backgroundColor: 'white', borderRadius: '10px' }}>
                        <VerColecao
                            atualizarCarta={atualizarCarta}
                            isMobile={isMobile}
                            consultarEdicaoDashboard={consultarEdicaoDashboard}
                            colecao={colecao}
                            userDetails={userDetails}
                            comPromo={values.comPromo}
                        />
                    </Box>}
                </Box>
                :
                <Box>
                    <Box sx={{ display: 'flex', height: '100%' }}>
                        <Box sx={{ padding: '10px', width: '75%', background: 'white', margin: '5px', borderRadius: '10px' }}>
                            <Filtros
                                alterarLoad={alterarLoad}
                                notificar={notificar}
                                setDadosEdicao={setDadosEdicao}
                                userDetails={userDetails}
                            />
                            <Box>
                                <Controls.Checkbox
                                    name='compromo'
                                    label='Mostrar cartas Promo'
                                    onChange={(e) => { handleCheckbox(e) }}
                                    value={values.comPromo}
                                    error={erros.comPromo}
                                />
                            </Box>
                            <ListaCards
                                dadosEdicao={dadosEdicao}
                                comPromo={values.comPromo}
                            />

                        </Box>
                        <Box sx={{ background: 'white', width: '25%', margin: '5px', borderRadius: '10px' }}>
                            <BarraLateral
                                dashboardLateral={dashboardLateral}
                                conquistas={conquistas}
                            />
                        </Box>
                    </Box>
                    {dadosEdicao.edicaoTO !== null && dadosEdicao.edicaoTO.edicao_id !== 0 && <Box sx={{ marginInline: '5px', backgroundColor: 'white', borderRadius: '10px' }}>
                        <VerColecao
                            atualizarCarta={atualizarCarta}
                            isMobile={isMobile}
                            consultarEdicaoDashboard={consultarEdicaoDashboard}
                            colecao={colecao}
                            userDetails={userDetails}
                            comPromo={values.comPromo}
                        />
                    </Box>}
                </Box>
            }
        </Box>)
}