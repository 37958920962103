import { Box, Divider, IconButton, Typography } from "@mui/material"
import CardCartaLoja from "./CardCartaLoja"
import * as Constantes from "../../util/constantes"
import { useState, useEffect } from 'react'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import * as Cores from "../../util/Cores";

export default function CartasCarrossel(props) {

    const { notificar, listaCartas, width, alterarCarrinho, isLoggedIn, userDetails, titulo, isMobile } = props

    const cartasLinha = isMobile ? Constantes.CARD_PER_ROW_MOBILE_LOJA : Constantes.CARD_PER_ROW_LOJA
    const [listaCartasArea, setListaCartasArea] = useState([])
    const [index, setIndex] = useState(0)

    useEffect(() => {
        setListaCartasArea(listaCartas === undefined ? [] : listaCartas.filter((x, i) => i < cartasLinha))
    }, [])

    const handleNext = () => {
        if (cartasLinha + index < listaCartas.length) {
            setIndex(index + 1)
            setListaCartasArea(listaCartas.filter((x, i) => i >= index + 1 && i < index + 1 + cartasLinha))
        }
    }

    const handleBefore = () => {
        if (index > 0) {
            setIndex(index - 1)
            setListaCartasArea(listaCartas.filter((x, i) => i >= index - 1  && i < index - 1+ cartasLinha))
        }
    }

    return (
        <Box sx={{ marginTop: '1%', textAlign: 'left' }}>
            <Divider>
                <Typography variant='body1' color={Cores.COR_PRINCIPAL}>{titulo}</Typography>
            </Divider>
            <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
                    <IconButton disabled={index === 0} onClick={() => handleBefore()}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    {
                        listaCartasArea.map((carta, index) => {
                            return (
                                <Box key={index} sx={{ marginInline: '5px' }}>
                                    <CardCartaLoja
                                        isMobile={isMobile}
                                        notificar={notificar}
                                        alterarCarrinho={alterarCarrinho}
                                        carta={carta}
                                        isLoggedIn={isLoggedIn}
                                        userDetails={userDetails}
                                        width={width}
                                    />
                                </Box>
                            )
                        })
                    }
                    <IconButton disabled={cartasLinha + index >= listaCartas.length} onClick={() => handleNext()}>
                        <NavigateNextIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    )
}