import { Box, Divider, Typography } from "@mui/material";
import * as Mascaras from "../../../util/Mascaras";
import * as Cores from "../../../util/Cores";
import * as Utils from "../../../util/Utils";
import * as Constantes from "../../../util/constantes";

export default function PagamentoPedido(props) {
    const { pedido, popup = false } = props
    const { width } = Utils.useWindowDimensions();
    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE

    return (
        <Box sx={{ width: isMobile ? '100%' : popup ? '100%' : '70%', marginLeft: isMobile ? '0%' : popup ? '0%' : '15%', marginTop: '16px' }}>
            <Box sx={{ background: 'white', padding: isMobile ? '15px' : '40px', borderRadius: '30px' }}>
                <Box sx={{ textAlign: 'left' }}>
                    <Typography variant='h5' color={Cores.COR_PRINCIPAL}><b>Detalhes do Pedido</b></Typography>
                    <Typography><b>Data do pedido:</b> {Mascaras.formatarTimestamp(pedido.data)}</Typography>
                    <Typography><b>Número do pedido:</b> {pedido.numero}</Typography>
                    <Typography><b>Status: </b>{pedido.status}</Typography>
                    {pedido.comentario !== '' && <Typography>{pedido.comentario}</Typography>}

                </Box>
                <Divider />
                <Box sx={{ background: 'white', textAlign: 'left', marginTop: '16px' }}>
                    <Typography variant='h5' color={Cores.COR_PRINCIPAL}><b>Endereço</b></Typography>
                    <Typography>Entregar em <b>{pedido.enderecoTO.apelido}</b></Typography>
                    <Typography><b>Bairro: </b>{pedido.enderecoTO.bairro}</Typography>
                    <Typography><b>CEP: </b>{pedido.enderecoTO.cep}</Typography>
                    <Typography><b>Endereço: </b>{pedido.enderecoTO.rua + pedido.enderecoTO.numero + pedido.enderecoTO.complemento}</Typography>
                    <Typography><b>Cidade: </b>{pedido.enderecoTO.estado + ' ' + pedido.enderecoTO.cidade}</Typography>
                </Box>
                <Divider />
                <Box sx={{ textAlign: 'left', marginTop: '16px' }}>
                    <Typography variant='h5' color={Cores.COR_PRINCIPAL}><b>Itens</b></Typography>
                    {Utils.dividirArrayEmGrupos(pedido.itensPedido, isMobile ? 1 : 4).map((linha, indexLinha) => {

                        return (
                            <Box sx={{ display: 'flex' }} key={indexLinha}>
                                {linha.map((item, index) => {
                                    return (
                                        <Box key={index} sx={{ width: isMobile ? '100%' : '25%' }}>
                                            <Typography>{item.carta.numero + ' - ' + item.carta.nome + ' ' + item.carta.edicaoDescricao}</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <img width={isMobile ? '30%' : '30%'} height={isMobile ? '30%' : '30%'} data-src={item.carta.imgUrl} src={item.carta.imgUrl} />
                                                <Box>
                                                    <Typography><b>Ano: </b>{+ item.carta.ano}</Typography>
                                                    <Typography><b>Quantidade: </b>{item.quantidade}</Typography>
                                                    <Typography><b>Valor: </b> R${Mascaras.dinheiro(item.valorUnitario + '')}</Typography>
                                                    <Typography><b>Desconto {item.descontoUnitario + '%'}: </b> R${Mascaras.dinheiro(item.valorUnitario * item.descontoUnitario / 100 + '')} </Typography>
                                                    <Typography><b>Total item: </b>R${Mascaras.dinheiro((item.quantidade * (item.valorUnitario * (100 - item.descontoUnitario )/100) + ''))}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                        )
                    })}
                </Box>

                <Box sx={{ textAlign: 'left', marginTop: '16px' }}>
                    <Typography variant='h5' color={Cores.COR_PRINCIPAL}><b>Pagamento</b></Typography>
                    {pedido.cupom !== null ?
                        <Typography> {pedido.cupom}</Typography>
                        :
                        <Typography><b>Cupom: </b>nenhum</Typography>
                    }
                    <Typography><b>Frete:</b> R${Mascaras.dinheiro(pedido.frete + '')}</Typography>
                    <Typography><b>Descontos:</b> R${Mascaras.dinheiro(pedido.descontos + '')}</Typography>
                    <Typography><b>Pedido:</b> R${Mascaras.dinheiro(pedido.valor + '')}</Typography>
                    <Typography><b>Total:</b> R${Mascaras.dinheiro((pedido.valor + pedido.frete) + '')}</Typography>
                    <Typography><b>Previsão de entrega:</b> até 15 dias</Typography>
                </Box>
            </Box>
        </Box >
    )
}