import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Card, CardContent, Typography } from "@mui/material";
import * as Cores from "../../../util/Cores";
import * as Mascaras from "../../../util/Mascaras";

export default function MiniaturaCarrinho(props) {
    const { item, removerCarrinho, alterarCarrinho, isMobile, index, notificar } = props

    const adicionarCompra = (i, quant, carta) => {
        let result = alterarCarrinho(i, quant, carta)
        if (!result) {
            notificar('Limite da carta em estoque atingido.', 'error')
        }
    }

    return (
        <Card key={index}>
            {isMobile ?
                <CardContent style={{ textAlign: 'center' }}>
                    <Box>
                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                            {Mascaras.tamTexto(item.nome)}
                        </Typography>
                        <img src={item.imagem} alt="image" height='80px' />

                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <RemoveCircleIcon sx={{ fill: Cores.COR_PRINCIPAL }} onClick={() => adicionarCompra(index, -1, null)} />
                            <Typography variant="body1" sx={{ color: Cores.COR_PRINCIPAL, transform: 'translateY(1px)' }}>
                                <b>{item.quantidade}</b>
                            </Typography>
                            <AddCircleIcon

                                sx={{ fill: Cores.COR_PRINCIPAL }}
                                onClick={() => adicionarCompra(index, 1, null)}
                            />
                        </Box>
                        <Typography variant="body2">
                            Valor: <b>R$ {item.valor.toFixed(2)}</b>
                        </Typography>

                        <Box sx={{ width: '100%', textAlign: 'end', display: 'flex', marginTop: '12px', justifyContent: 'space-between' }}>
                            <Typography variant="body1" sx={{ transform: 'translateY(7px)' }}>
                                <b>R${(item.quantidade * item.valor).toFixed(2)}</b>
                            </Typography>
                            <DeleteIcon fontSize='large' sx={{ cursor: 'pointer', fill: Cores.COR_PRINCIPAL }} onClick={() => removerCarrinho(index)} />
                        </Box>
                    </Box>
                </CardContent>
                :
                <CardContent style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                    <Box>
                        <img src={item.imagem} alt="image" height='130px' />
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                            {Mascaras.tamTexto(item.nome)}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <RemoveCircleIcon sx={{ fill: Cores.COR_PRINCIPAL, cursor: 'pointer' }} onClick={() => adicionarCompra(index, -1, null)} />
                            <Typography variant="body1" sx={{ color: Cores.COR_PRINCIPAL, transform: 'translateY(1px)' }}>
                                <b>{item.quantidade}</b>
                            </Typography>
                            <AddCircleIcon
                                sx={{ fill: item.quantidade === item.estoque? 'grey' : Cores.COR_PRINCIPAL, cursor: 'pointer' }}
                                onClick={() => adicionarCompra(index, 1, null)} />
                        </Box>
                        <Typography variant="body2">
                            Valor: <b>R$ {item.valor.toFixed(2)}</b>
                        </Typography>
                        <Box sx={{ width: '100%', textAlign: 'end', display: 'flex', marginTop: '12px', justifyContent: 'space-between' }}>
                            <Typography variant="body1" sx={{ transform: 'translateY(7px)' }}>
                                <b>R${(item.quantidade * item.valor).toFixed(2)}</b>
                            </Typography>
                            <DeleteIcon fontSize='large' sx={{ cursor: 'pointer', fill: Cores.COR_PRINCIPAL }} onClick={() => removerCarrinho(index)} />
                        </Box>
                    </Box>
                </CardContent>
            }
        </Card>
    )
}