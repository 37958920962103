import {
    postGerarQrCodePix, postGerarPreferencia, postProcessarPagamento, postConsultarAssinatura, postGerarAssinatura, postCancelarAssinatura
} from '../PagamentoApi'



export async function consultarAssinatura(setLoad, request){
    setLoad(true)
    return await postConsultarAssinatura(request).then(response => {
        setLoad(false)
        return response.data
    }).catch(e => {
        setLoad(false)
        return null
    })
}

export async function cancelarAssinatura(setLoad, request){
    setLoad(true)
    return await postCancelarAssinatura(request).then(response => {
        setLoad(false)
        return response.data
    }).catch(e => {
        setLoad(false)
        return null
    })
}

export async function gerarAssinatura(setLoad, request){
    setLoad(true)
    return await postGerarAssinatura(request).then(response => {
        setLoad(false)
        return response.data
    }).catch(e => {
        setLoad(false)
        return null
    })
}

export async function processarPagamento(request, alterarLoad, notificar) {
    alterarLoad(true)
    try {
        return await postProcessarPagamento(request).then(response => {
            if (response.data.erro) {
                notificar(response.data.mensagemErro, "error")
                return response.data
            } else {
                return response.data
            }
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao gerar pagamento. Teste novamente.", "error")
        return ''
    } finally {
        alterarLoad(false)
    }
}

export async function gerarPreferencia(preferencia, alterarLoad, notificar) {
    alterarLoad(true)
    try {
        return await postGerarPreferencia(preferencia).then(response => {
            if (response.data.erro) {
                notificar(response.data.mensagemErro, "error")
                return response.data
            } else {
                return response.data
            }
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao gerar pagamento. Teste novamente.", "error")
        return ''
    } finally {
        alterarLoad(false)
    }
}

export async function gerarQrCodePix(notificar, alterarLoad, dados) {
    alterarLoad(true)
    try {
        return await postGerarQrCodePix(dados).then(response => {
            if (response.data.erro) {
                notificar(response.data.mensagemErro, "error")
                return response.data
            } else {
                return response.data
            }
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao gerar pagamento Pix. Teste novamente.", "error")
        return {}
    } finally {
        alterarLoad(false)
    }
}
