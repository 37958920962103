import { TableCell, TableRow } from "@material-ui/core";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Chip, TableBody, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import PedidoService from "../../api/services/PedidoService";
import Popup from "../../components/Popup";
import UseTable from "../../components/UseTable";
import * as Mascaras from '../../util/Mascaras';
import * as Cores from '../../util/Cores';
import PedidoPopup from "./PedidoPopup";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { ChipStatusPedido } from "../../components/ChipStatusPedido";

const headCells = [
    { id: 'numero', label: 'Número' },
    { id: 'data', label: 'Data' },
    { id: 'valorTotal', label: 'Valor Total' },
    { id: 'status', label: 'Status' },
    { id: 'rastreio', label: 'Código de Rastreio', disableSorting: true },
    { id: 'visualizar', label: '', disableSorting: true },
]

export default function MeusPedidos(props) {
    const { userDetails, alterarLoad, notificar, isMobile } = props
    const [records, setRecords] = useState([])
    const [recordForView, setRecordForView] = useState(null)
    const [filterFn, setFilterFn] = useState({ fn: items => { return items } })
    const [openPopup, setOpenPopup] = useState(false)
    const [numPedido, setNumPedido] = useState('')

    useEffect(() => {
        consultarPedidos()
    }, []);

    async function consultarPedidos() {
        await PedidoService.consultarPedidosCliente(alterarLoad, notificar, userDetails.id).then((response) => {
            setRecords(response.map((item) => {
                return ({
                    id: item.id,
                    numero: item.numero,
                    data: item.data,
                    valorTotal: item.valor,
                    status: item.status.nome,
                    statusId: item.status.id,
                    rastreio: item.rastreio === null ? 'Pedido não enviado.' : item.rastreio,
                })
            }))
        })
    }

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = UseTable(records, headCells, filterFn);


    const openInPopup = (e, item) => {
        e.preventDefault()
        e.stopPropagation()
        setRecordForView(item)
        setNumPedido(item.numero)
        setOpenPopup(true)
    }

    async function handleCancelar(record) {
        await PedidoService.cancelarPedido(record.id, notificar, alterarLoad).then(response => {
            if(!response.erro){
                setRecords(
                    records.map(item => {
                        return item.id === record.id ? {
                            ...item,
                            statusId: 6,
                            status: 'Cancelado'
                        }
                        :
                        item
                    })
                )
                setOpenPopup(false)
            }
        })
    }


    return (
        <Box sx={{ marginLeft: isMobile ? '1%' : '10%', width: isMobile ? '98%' : '80%' }}>
            {isMobile ?
                <Box>
                    {records.map((item, index) => {
                        return (
                            <Box onClick={e => openInPopup(e, item)} sx={{ background: 'white', marginBottom: '8px', borderRadius: '25px', textAlign: 'left', padding: '12px' }}>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography><b>Pedido </b>{item.numero}</Typography>
                                    <OpenInNewIcon sx={{fill: Cores.COR_SECUNDARIA_ROSA}}/>
                                </Box>
                                <Typography><b>Data do pedido: </b>{Mascaras.formatarTimestamp(item.data)}</Typography>
                                <Typography><b>Valor: </b>{Mascaras.dinheiro(item.valorTotal + '')}</Typography>
                                <TableCell><ChipStatusPedido item={item}/></TableCell>
                                <Typography><b>Rastreio: </b>{item.rastreio}</Typography>
                            </Box>
                        )
                    })}
                </Box>
                :
                <Box>
                    {
                        records.length > 0 ?
                            <Box>
                                <TblContainer >
                                    <TblHead />
                                    <TableBody sx={{ background: 'white'}}>
                                        {recordsAfterPagingAndSorting().map((item, index) => (
                                            <TableRow key={index} >
                                                <TableCell>{'#' + item.numero}</TableCell>
                                                <TableCell>{Mascaras.formatarTimestamp(item.data)}</TableCell>
                                                <TableCell>{item.valorTotal}</TableCell>
                                                <TableCell><ChipStatusPedido item={item}/></TableCell>
                                                <TableCell>{item.rastreio}</TableCell>
                                                <TableCell><OpenInNewIcon style={{ fill: Cores.COR_SECUNDARIA_ROSA, cursor: 'pointer' }} onClick={e => openInPopup(e, item)} /></TableCell>
                                            </TableRow>
                                        )
                                        )}
                                    </TableBody>
                                </TblContainer>
                                <TblPagination />
                            </Box>
                            :

                            <Typography sx={{ margin: '30px' }} variant='body2'>Você não tem nenhum pedido ainda.</Typography>
                    }
                </Box>
            }

            <Popup
                width='100%'
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title={'Visualizar pedido #' + numPedido}
            >
                <PedidoPopup
                    record={recordForView}
                    alterarLoad={alterarLoad}
                    notificar={notificar}
                    isMobile={isMobile}
                    handleCancelar={handleCancelar}
                />

            </Popup>
        </Box>
    )
}