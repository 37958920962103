import { Box, CircularProgress, Typography } from "@mui/material";
import * as Constantes from "../../util/constantes";


export default function CircularProgressBarComIcone(props) {
    const { icone, cor, progresso } = props

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate"
                value={progresso} 
                sx={{color: cor}} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {icone}
            </Box>
        </Box>
    )
}