import { Box, Grid, IconButton, InputAdornment, Paper, Typography } from "@mui/material";
import Controls from "../../components/controls/Controls";
import Load from "../../components/Load";
import { Form, useForm } from "../../components/UseForm";
import { useState, useEffect } from 'react'
import * as Mensagens from "../../util/Mensagens";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClienteService from "../../api/services/ClienteService";
import Notificacao from "../../components/Notificacao";
import { useSearchParams } from "react-router-dom";

const initialValues = {
    id: 0,
    novaSenha: '',
    confirmarSenha: ''
}

export default function AlterarSenha(props) {
    const { userDetails } = props
    const [showPage, setShowPage] = useState(false)
    const [showLoad, setShowLoad] = useState(false)
    const [params] = useSearchParams();
    const [mostraSenha, setMostraSenha] = useState('password')
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    })

    useEffect(() => {
        checarLink(params.get("hash"))
    }, []);

    async function checarLink(hash) {
        setShowLoad(true)
        try {
            await ClienteService.checarLink(hash).then(response => {
                setShowLoad(false)
                if (response.status !== 200) {
                    setShowPage(false)
                    setNotify({
                        isOpen: true,
                        message: Mensagens.ERRO_SERVICO,
                        type: "error",
                    })
                } else if(response.erro){
                    setShowPage(false)
                    setNotify({
                        isOpen: true,
                        message: response.mensagem,
                        type: "error",
                    })
                } else{
                    setShowPage(true)
                }
            })
        } catch (e) {
            setShowPage(false)
            setShowLoad(false)
        }
    }
    const validate = (fieldValues = values) => {
        let temp = { ...erros }
        if ('senha' in fieldValues) {
            temp.senha = fieldValues.senha.length >= 8 ? '' : Mensagens.CAMPO_OBRIGATORIO
        }
        if ('novaSenha' in fieldValues) {
            temp.novaSenha = fieldValues.novaSenha.length >= 8 ? '' : Mensagens.CAMPO_OBRIGATORIO
        }
        if ('confirmarSenha' in fieldValues) {
            temp.confirmarSenha = fieldValues.confirmarSenha.length >= 8 ? '' : Mensagens.CAMPO_OBRIGATORIO
            if (temp.confirmarSenha !== Mensagens.CAMPO_OBRIGATORIO) {
                temp.confirmarSenha = fieldValues.confirmarSenha === values.novaSenha ? '' : Mensagens.SENHAS_DIFEREM
            }
        }

        setErros({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        handleInputChange,
        erros,
        setErros
    } = useForm(initialValues, true, validate)

    async function alterarSenha() {
        setShowLoad(true)
        try {
            await ClienteService.alterarSenha({
                link: params.get("hash"),
                novaSenha: values.novaSenha,
                username: params.get("username"),
                email: params.get("email"),
                id: userDetails.id
            }).then(response => {
                setShowLoad(false)
                if (response.status !== 200) {
                    setNotify({
                        isOpen: true,
                        message: Mensagens.ERRO_SERVICO,
                        type: "error",
                    })
                } else {
                    setNotify({
                        isOpen: true,
                        message: response.mensagem,
                        type: response.erro ? "error" : "success",
                    })
                }
            })
        } catch (e) {
            setShowLoad(false)
        }

    }

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            alterarSenha()
        }
    }

    const handleClickShowPassword = () => {
        if (mostraSenha === 'password') {
            setMostraSenha('text')
        } else {
            setMostraSenha('password')
        }
    }
    return (
        <Paper sx={{}}>
            {showPage ?
                <Box sx={{ paddingTop: '4%', display: 'flex', justifyContent: 'center', paddingBottom: '19%' }}>
                    <Form onSubmit={handleSubmit} >
                        <Notificacao notify={notify} setNotify={setNotify} />
                        <Load exibirLoad={showLoad} mensagemLoad='Alterando senha...' />
                        <Grid container spacing={3} sx={{ width: '500px' }} >
                            <Grid item md={12} sx={{paddingBottom: '4%',}}>
                                {Mensagens.OLA_ALTERAR_SENHA(params.get("username"))}
                            </Grid>
                            <Grid item md={12}>
                                <Controls.Input
                                    name='novaSenha'
                                    label='Nova Senha'
                                    onChange={handleInputChange}
                                    value={values.novaSenha}
                                    error={erros.novaSenha}
                                    type={mostraSenha}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Controls.Input
                                    name='confirmarSenha'
                                    label='Confirmar Senha'
                                    onChange={handleInputChange}
                                    value={values.confirmarSenha}
                                    error={erros.confirmarSenha}
                                    type={mostraSenha}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Controls.Button
                                    text='Alterar Senha'
                                    type='submit'
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </Box>
                :
                <Typography sx={{padding: '22%'}}>{Mensagens.LINK_INDISPONIVEL}</Typography>
            }

        </Paper>
    )
}