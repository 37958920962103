import { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Controls from './controls/Controls';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const steps = [
    'Pedido',
    'Endereço',
    'Confirmação do pedido',
    'Pagamento',
];

export default function StepperCheckout(props) {
    const { children, activeStep, setActiveStep, salvarPedido, buscarPedido } = props
    const [skipped, setSkipped] = useState(new Set());

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const avancarStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    const handleNext = async () => {
        if (activeStep === 1) {
            let resp = await salvarPedido()
            if (!resp.erro && resp.codigo !== '') {
                let pedido = await buscarPedido(resp.codigo)
                if (pedido) {
                    avancarStep()
                }
            }
        } else {
            avancarStep()
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    return (
        <Box sx={{ width: '100%', paddingTop: '20px', paddingBottom: '20px' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {children}
            {activeStep === steps.length ? (
                <Fragment>
                    <Box>
                        <TaskAltIcon color="primary" fontSize='large' sx={{transform: 'scale(2)'}}/>
                        <Typography variant="h5" color="primary" sx={{ mt: 2, mb: 1 }}>
                            Pedido finalizado!
                        </Typography>
                    </Box>
                </Fragment>
            ) : (
                <Fragment>
                    <Box sx={{ display: 'flex', marginTop: '25px', marginInline: '5px' }}>
                        <Controls.Button
                            text='Anterior'
                            size='large'
                            onClick={handleBack}
                            disabled={activeStep === 0}
                        />

                        <Box sx={{ flex: '1 1 auto' }} />
                        {activeStep === steps.length - 1 ?
                            <Box>
                                <Controls.Button
                                    text={'Finalizar'}
                                    size='large'
                                    onClick={handleNext}
                                />
                            </Box>
                            :
                            <Controls.Button
                                text={'Próximo'}
                                size='large'
                                onClick={handleNext}
                            />
                        }
                    </Box>
                </Fragment>
            )}


        </Box>
    );
}