import { InputAdornment, TextField } from "@mui/material";
export default function Input(props) {

    const { id='', name, label, value, error = null, onChange, inputPropText = '', background='white', ...other } = props

    return (
        <TextField
            id={id}
            variant="standard"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            sx={{ background: background, borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
            "& .MuiFormLabel-root":{
                marginLeft: '6px'
            } }}
            InputProps={ inputPropText !== '' && { 
                endAdornment: (
                    <InputAdornment position="end">
                       {inputPropText}
                    </InputAdornment>
                )
            }}
            {...other}
            {...(error && { error: true, helperText: error })}
        />
    )
}