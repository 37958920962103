import { Box } from "@mui/material";
import { useEffect, useState } from 'react';
import CartasService from "../../../api/services/CartasService";
import AdminCartas from "./AdminCartas";
import AdminEdicoes from "./AdminEdicoes";
import Estoque from "./estoque/Estoque";
import Pedido from "./pedido/Pedido";

export default function Admin(props) {
    const [listaNivel, setListaNivel] = useState([])
    const [listaRaridade, setListaRaridade] = useState([])
    const [listaEdicao, setListaEdicao] = useState([])
    const [listaNatureza, setListaNatureza] = useState([])
    const [listaIdioma, setListaIdioma] = useState([])
    const [listaJogo, setListaJogo] = useState([])
    const [showPage, setShowPage] = useState(false)
    const { userDetails, notificar, alterarLoad, alterarMensagemLoad } = props

    useEffect(() => {
        consultarCombos()
    }, []);

    async function consultarCombos() {
        await CartasService.consultarCombos(0, alterarMensagemLoad, alterarLoad, notificar).then((response) => {
            try {
                if (response !== undefined) {
                    setListaRaridade(response.listaRaridade)
                    setListaEdicao(response.listaEdicao)
                    setListaNatureza(response.listaNatureza)
                    setListaIdioma(response.listaIdioma)
                    setListaNivel(response.listaNivel)
                }
            } catch (e) {
                console.log(e)
                setShowPage(false)
            } finally {
                setShowPage(true)
            }
        })
    }
    return (
        <Box>
            {showPage &&
                <Box>
                    <AdminCartas
                        notificar={notificar}
                        listaNivel={listaNivel}
                        listaEdicao={listaEdicao}
                        listaNatureza={listaNatureza}
                        listaRaridade={listaRaridade}
                        listaJogo={listaJogo}
                        listaIdioma={listaIdioma}
                    />

                    <AdminEdicoes
                        listaJogo={listaJogo}
                    />
                    <Estoque
                        listaIdioma={listaIdioma}
                        alterarLoad={alterarLoad}
                        notificar={notificar}
                    />
                </Box>
            }
        </Box>
    )
}