import axios from "axios";
import * as Utils from "../util/Utils"


const CANCELAR_PEDIDO = 'pedido/cancelar'
const SALVAR_PEDIDO = 'pedido/salvar'
const ALTERAR_PEDIDO = 'pedido/alterar'
const CONSULTAR_PEDIDO = '/pedido/consultar'
const CONSULTAR_PEDIDOS_CLIENTE = '/pedido/cliente/consultar'
const CALCULAR_FRETE = '/frete/calcular'

const client = axios.create({
    baseURL: process.env.REACT_APP_AMBIENTE
});

export async function getCalcularFrete(request){
    return client.get(CALCULAR_FRETE)
}

export async function postSalvarPedido(request){
    return client.post(SALVAR_PEDIDO, request, Utils.getAuth())
}

export async function postCancelarPedido(request){
    return client.post(CANCELAR_PEDIDO, request, Utils.getAuth())
}

export async function postAlterarPedido(request){
    return client.post(ALTERAR_PEDIDO, request, Utils.getAuth())
}

export async function getConsultarPedidosCliente(id){
    return client.get(CONSULTAR_PEDIDOS_CLIENTE + '?id=' + id, Utils.getAuth())
}

export async function getConsultarPedido(id, numero){
    return client.get(CONSULTAR_PEDIDO + '?id=' + id + '&numero=' + numero, Utils.getAuth())
}

